import { FunctionComponent, memo } from 'react';
import ChatListItemOfferStepTimer from './components/Timer/ChatListItemOfferStepTimer';
import { TChatListTranslations } from '../../hooks/useChatListItemTranslations';
import { SandglassIcon } from 'assets/img/icons/icon';
import {
  ChatListIconBlock,
  ChatListIconColumn,
  ChatListIconColumnItems,
  ChatListIconColumnSubTitle,
  ChatListIconColumnTitle,
} from '../../ChatListItem.style';
import { DealsStatusIcon } from 'shared/ui/deals-status-icon';
import { getStatusLabel } from 'shared/hooks/use-deal-status-label';
import { TDealsStatus } from 'interfaces';
import Icon from '@ant-design/icons';

type TChatListItemOfferStep = {
  step?: TDealsStatus;
  id?: string;
  isGuarantee?: boolean;
  isSelling?: boolean;
  createdAt: null | string;
  closedAt: null | string;
  translations: TChatListTranslations;
  dealId: number | null;
};

const ChatListItemOfferStep: FunctionComponent<TChatListItemOfferStep> = (
  props,
) => {
  const {
    step,
    id,
    createdAt,
    closedAt,
    translations,
    dealId,
    isGuarantee,
    isSelling,
  } = props;
  const renderIcon = () => {
    if (!dealId) {
      return <Icon component={SandglassIcon} alt={'Temp'} />;
    }

    return <DealsStatusIcon status={step ?? ''} />;
  };

  const renderStepText = () => {
    if (!dealId) {
      return <ChatListItemOfferStepTimer createdAt={createdAt} isHideMinutes />;
    }

    if (step) {
      const label = getStatusLabel({
        status: step,
        isGuarantee,
        isSelling,
      });

      return (
        <ChatListIconColumnTitle>
          {label}
        </ChatListIconColumnTitle>
      );
    }

    return null;
  };

  const renderSubTitle = () => {
    let text: string | undefined;

    if (!dealId) {
      text = translations.temporaryChat;
    } else if (step && id) {
      text = `${translations.deal} ${id}`;
    }

    if (!text) return null;

    return <ChatListIconColumnSubTitle>{text}</ChatListIconColumnSubTitle>;
  };

  return (
    <ChatListIconBlock>
      <ChatListIconColumn>
        {renderIcon()}
      </ChatListIconColumn>
      <ChatListIconColumnItems>
        {renderStepText()}
        {renderSubTitle()}
      </ChatListIconColumnItems>
    </ChatListIconBlock>
  );
};

export default memo(ChatListItemOfferStep);
