import { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useShallow } from 'zustand/react/shallow';
import styled from 'styled-components';

import { ModalConfirmReceiptOfCash } from './ModalConfirmReceiptOfCash/ModalConfirmReceiptOfCash';
import { ModalCancelTransaction } from './ModalCancelTransaction/ModalCancelTransaction';
import { ModalShowPointOnMap } from './ModalShowPointOnMap/ModalShowPointOnMap';
import { ModalOpenDispute } from './ModalOpenDispute/ModalOpenDispute';
import { ModalConfirmPayment } from './ModalConfirmPayment/ModalConfirmPayment';
import { useModalState } from '../model/useModalState';
import { ModalKeys } from '../model/enums';
import { ModalChatInfrormation } from './ModalChatInformation/ModalChatInfrormation';
import { ModalUserMenu } from './ModalUserMenu';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { useWindowScrollLock } from 'shared/hooks/useWindowScrollLock';
import { ModalHeaderMenu } from './ModalHeaderMenu';
import { ModalNotifications } from './ModalNotifications/ModalNotifications';
import { ModalChatWindow } from './ModalChatWindow/ModalChatWindow';
import { ModalConfirmFundsSend } from './ModalConfirmFundsSend/ModalConfirmFundsSend';
import { ModalComplaint } from './ModalComplaint/ModalComplaint';
import { ModalConfirmFundsReceived } from './ModalConfirmFundsReceived/ModalConfirmFundsReceived';
import { ModalConfirmPaymentCrypto } from './ModalConfirmPaymentCrypto/ModalConfirmPaymentCrypto';

const ModalByKey = ({ modalKey }: { modalKey: ModalKeys }) => {
  switch (modalKey) {
    case ModalKeys.ModalCancelTransaction:
      return <ModalCancelTransaction />;
    case ModalKeys.ModalConfirmReceiptOfCash:
      return <ModalConfirmReceiptOfCash />;
    case ModalKeys.ModalOpenDispute:
      return <ModalOpenDispute />;
    case ModalKeys.ModalShowPointOnMap:
      return <ModalShowPointOnMap />;
    case ModalKeys.ModalConfirmPayment:
      return <ModalConfirmPayment />;
    case ModalKeys.ModalConfirmFundsSend:
      return <ModalConfirmFundsSend />;
    case ModalKeys.ModalConfirmPaymentCrypto:
      return <ModalConfirmPaymentCrypto />;
    case ModalKeys.ModalUserMenu:
      return <ModalUserMenu />;
    case ModalKeys.ModalNotifications:
      return <ModalNotifications />;
    case ModalKeys.ModalChatInformation:
      return <ModalChatInfrormation />;
    case ModalKeys.ModalChatWindow:
      return <ModalChatWindow />;
    case ModalKeys.ModalHeaderMenu:
      return <ModalHeaderMenu />;
    case ModalKeys.ModalComplaint:
      return <ModalComplaint />;
    case ModalKeys.ModalConfirmFundsReceived:
      return <ModalConfirmFundsReceived />;
  }

  return null;
};

interface IPortalWrapperProps {
  modalKey: ModalKeys;
  isShowHeader?: boolean;
  isModalOpen?: boolean;
}

/**
 * "Классический" рендер модалки
 */
const PortalWrapper = ({
  modalKey,
  isShowHeader,
  isModalOpen = false,
}: IPortalWrapperProps) => {
  const ref = useRef<HTMLElement | null>();
  const [mounted, setMounted] = useState(false);

  const { isScreen991, isScreen480, isScreen720 } = useWindowBreakpointState(
    useShallow((state) => ({
      isScreen480: state.computed.isScreen480,
      isScreen720: state.computed.isScreen720,
      isScreen991: state.computed.isScreen991,
    }))
  );

  useEffect(() => {
    ref.current = document.querySelector('body');
    setMounted(true);
  }, []);

  useWindowScrollLock(isModalOpen);

  const modals = {
    [ModalKeys.ModalCancelTransaction]: isModalOpen && (
      <ModalCancelTransaction />
    ),
    [ModalKeys.ModalConfirmReceiptOfCash]: isModalOpen && (
      <ModalConfirmReceiptOfCash />
    ),
    [ModalKeys.ModalOpenDispute]: isModalOpen && <ModalOpenDispute />,
    [ModalKeys.ModalShowPointOnMap]: isModalOpen && <ModalShowPointOnMap />,
    [ModalKeys.ModalConfirmPayment]: isModalOpen && <ModalConfirmPayment />,
    [ModalKeys.ModalUserMenu]: isModalOpen && <ModalUserMenu />,
    // temporary hack
    [ModalKeys.ModalDealsFilters]: isModalOpen && null,
    [ModalKeys.ModalHeaderMenu]: isModalOpen && <ModalHeaderMenu />,
    [ModalKeys.ModalComplaint]: isModalOpen && <ModalComplaint />,
  };

  return mounted && ref.current
    ? createPortal(
        <ModalWrapperStyled
          className="modal-container"
          data-key={modalKey}
          $isScreen980={isScreen991}
          $isScreen720={isScreen720}
          $isScreen425={isScreen480}
          $isShowHeader={isShowHeader}
        >
          {isModalOpen && <ModalByKey modalKey={modalKey} />}
        </ModalWrapperStyled>,
        ref.current,
        modalKey
      )
    : null;
};

interface IRenderModalProps {
  currentModalKey: ModalKeys;
  isShowHeader?: boolean;
  useGlobalWrapper?: boolean;
}

export const RenderModal: FC<IRenderModalProps> = ({
  currentModalKey,
  isShowHeader,
  useGlobalWrapper,
}) => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(currentModalKey)
  );

  if (useGlobalWrapper) {
    return (
      <PortalWrapper
        modalKey={currentModalKey}
        isShowHeader={isShowHeader}
        isModalOpen={isModalOpen}
      />
    );
  }

  return isModalOpen ? <ModalByKey modalKey={currentModalKey} /> : null;
};

interface IModalWrapperProps {
  $isScreen980?: boolean;
  $isScreen720?: boolean;
  $isScreen425?: boolean;
  $isShowHeader?: boolean;
}

const ModalWrapperStyled = styled.div<IModalWrapperProps>`
  overflow-y: hidden;
  width: calc(100% - 15px);

  ${({ $isShowHeader }) =>
    $isShowHeader &&
    `
    .ant-modal {
      padding-bottom: 0;
      top: 72px;
    }
    .ant-modal-mask {
      top: 72px;
    }
  `}

  .ant-modal-title {
    margin-bottom: 24px !important;
    font-weight: 700 !important;
    color: #fbfbff !important;
    background: transparent;
  }

  .ant-modal {
    min-width: ${({
      $isScreen980,
      $isScreen425,
      $isScreen720,
      $isShowHeader,
    }) => {
      if ($isShowHeader && !$isScreen720) {
        return '100%';
      }
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100% - 32px);';
      }
      if (!$isScreen425) {
        return '100%';
      }
    }};
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-modal-content {
    min-height: ${({
      $isScreen980,
      $isScreen425,
      $isScreen720,
      $isShowHeader,
    }) => {
      if ($isShowHeader && !$isScreen720) {
        return 'calc(100vh - 72px)';
      }
      if (!$isScreen980 && $isScreen425) {
        return 'calc(100vh - 32px)';
      }
      if (!$isScreen425) {
        return '100vh';
      }
    }};
  }

  .ant-modal-content {
    border-radius: ${({ $isScreen720 }) => {
      if (!$isScreen720) {
        return '0';
      }
    }};
  }
`;
