import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import { ListCard } from 'shared/ui/list-card';
import styled from 'styled-components';

export const ChatListItemCard = styled(ListCard)<{ $isActive?: boolean }>`
  position: relative;
  background: ${({ $isActive }) => ($isActive ? '#2B2B36' : '#1c1c27')};
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2b2b36;
  }

  &:active {
    background-color: #181827;
  }

  --chat-list-font-inter: Inter, sans-serif;
`;

export const ChatListIconBlock = styled.div`
  display: flex;
  min-width: 0;
  gap: 8px;
  align-items: flex-start;

  font-family: var(--chat-list-font-inter);

  @media ${MEDIA_BREAKPOINT.XL} {
    align-items: center;
  }
`;

export const ChatListIconColumn = styled.div`
  width: 14px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const ChatListIconDot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #F16063;
  vertical-align: 1px;

  &.is-active {
    background-color: #0C8878;
  }
`;

export const ChatListIconColumnItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px 10px;
  min-width: 0;
  flex-wrap: wrap;
  align-items: baseline;

  @media ${MEDIA_BREAKPOINT.XL} {
    flex-direction: column;
  }
`;

export const ChatListIconColumnTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #ffffff;
`;

export const ChatListIconColumnSubTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #767687;
`;
