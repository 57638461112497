import { ModalStyled } from './ModalChatInformation.styles';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { ChatAside } from 'widgets/chatAside/ui/ChatAside/ChatAside';
import { useHeaderState } from 'state/header/useHeaderState';

export const ModalChatInfrormation = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalChatInformation)
  );
  const closeModal = useModalState((state) => state.closeModal);
  const setActiveDropdown = useHeaderState((state) => state.setActiveDropdown);

  const closeAddressModal = () => {
    closeModal(ModalKeys.ModalChatInformation);
    setActiveDropdown(null);
  };

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeAddressModal}
      footer={null}
      maskClosable={false}
      centered
      width={'100%'}
    >
      <ChatAside />
    </ModalStyled>
  );
};
