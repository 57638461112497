import React from 'react';

export const SecurityIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.44405 1.99141C4.40092 2.2674 3.34453 2.59367 2.7193 2.79279C2.54728 2.84758 2.42649 2.99297 2.40381 3.15911C1.8718 7.05584 3.10103 9.89999 4.56776 11.7728C5.30416 12.713 6.10093 13.4085 6.76517 13.8657C7.09756 14.0944 7.3914 14.2599 7.6223 14.366C7.7379 14.4191 7.83244 14.4549 7.90424 14.4766C7.96338 14.4945 7.9938 14.4987 8.00103 14.4997C8.00826 14.4987 8.03867 14.4945 8.09781 14.4766C8.16962 14.4549 8.26416 14.4191 8.37976 14.366C8.61065 14.2599 8.9045 14.0944 9.23688 13.8657C9.90112 13.4085 10.6979 12.713 11.4343 11.7728C12.901 9.89999 14.1303 7.05584 13.5982 3.15911C13.5756 2.99297 13.4548 2.84758 13.2828 2.79279C12.6575 2.59367 11.6011 2.26739 10.558 1.99141C9.49147 1.70924 8.51092 1.5 8.00103 1.5C7.49113 1.5 6.51058 1.70924 5.44405 1.99141ZM5.18828 1.02467C6.23115 0.748759 7.33875 0.5 8.00103 0.5C8.6633 0.5 9.77091 0.748759 10.8138 1.02467C11.8801 1.30678 12.9543 1.6387 13.5862 1.83995C14.1136 2.00791 14.5125 2.46274 14.5891 3.02384C15.1621 7.22133 13.8333 10.3314 12.2216 12.3894C11.4187 13.4145 10.5462 14.1785 9.80385 14.6894C9.43294 14.9447 9.08918 15.1405 8.79726 15.2746C8.52753 15.3986 8.23896 15.5 8.00103 15.5C7.7631 15.5 7.47453 15.3986 7.20479 15.2746C6.91287 15.1405 6.56911 14.9447 6.19821 14.6894C5.45586 14.1785 4.58331 13.4145 3.78048 12.3894C2.16875 10.3314 0.839928 7.22133 1.413 3.02384C1.48961 2.46274 1.88848 2.00791 2.41583 1.83995C3.04775 1.6387 4.122 1.30678 5.18828 1.02467Z"
      fill={'currentColor'}
    />
    <path
      d="M9.5 6.5C9.5 7.15311 9.0826 7.70873 8.5 7.91465L8.88494 9.90506C8.94461 10.2136 8.70826 10.5 8.39404 10.5H7.60596C7.29174 10.5 7.05539 10.2136 7.11506 9.90506L7.5 7.91465C6.9174 7.70873 6.5 7.15311 6.5 6.5C6.5 5.67157 7.17157 5 8 5C8.82843 5 9.5 5.67157 9.5 6.5Z"
      fill={'currentColor'}
    />
  </svg>
);
export const PayIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 0.75H1.25C0.973437 0.75 0.75 0.973437 0.75 1.25V12.75C0.75 13.0266 0.973437 13.25 1.25 13.25H12.75C13.0266 13.25 13.25 13.0266 13.25 12.75V1.25C13.25 0.973437 13.0266 0.75 12.75 0.75ZM12.125 8H7.25V6H12.125V8ZM12.125 12.125H1.875V1.875H12.125V5H6.75C6.47344 5 6.25 5.22344 6.25 5.5V8.5C6.25 8.77656 6.47344 9 6.75 9H12.125V12.125ZM8.0625 7C8.0625 7.16576 8.12835 7.32473 8.24556 7.44194C8.36277 7.55915 8.52174 7.625 8.6875 7.625C8.85326 7.625 9.01223 7.55915 9.12944 7.44194C9.24665 7.32473 9.3125 7.16576 9.3125 7C9.3125 6.83424 9.24665 6.67527 9.12944 6.55806C9.01223 6.44085 8.85326 6.375 8.6875 6.375C8.52174 6.375 8.36277 6.44085 8.24556 6.55806C8.12835 6.67527 8.0625 6.83424 8.0625 7Z"
      fill="currentColor"
    />
  </svg>
);

export const NotificationInfoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#5297FF"
    />
    <path
      d="M14.1461 9.13184L10.7095 9.5625L10.5865 10.1338L11.2632 10.2568C11.7027 10.3623 11.7906 10.5205 11.6939 10.96L10.5865 16.1631C10.2964 17.5078 10.7447 18.1406 11.7994 18.1406C12.6168 18.1406 13.566 17.7627 13.9966 17.2441L14.1285 16.6201C13.8296 16.8838 13.3902 16.9893 13.1002 16.9893C12.6871 16.9893 12.5377 16.6992 12.6431 16.1895L14.1461 9.13184Z"
      fill="#5297FF"
    />
    <circle cx="12.75" cy="6" r="1.5" fill="#5297FF" />
  </svg>
);

export const DropdownIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24677 11.1399L2.45054 5.6585C1.88478 5.01192 2.34396 4 3.20312 4H12.7956C13.6547 4 14.1139 5.01192 13.5482 5.6585L8.75193 11.1399C8.35352 11.5952 7.64518 11.5952 7.24677 11.1399Z"
      fill="url(#paint0_linear_896_39020)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_896_39020"
        x1="2.20117"
        y1="11.4814"
        x2="14.8735"
        y2="8.33862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#088473" />
        <stop offset="1" stopColor="#12BFA8" />
      </linearGradient>
    </defs>
  </svg>
);

export const CommonProfileControlIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5ZM8 8C9.65685 8 11 6.65685 11 5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5C5 6.65685 6.34315 8 8 8ZM14 13C14 14 13 14 13 14H3C3 14 2 14 2 13C2 12 3 9 8 9C13 9 14 12 14 13ZM13 12.9965C12.9986 12.7497 12.8462 12.0104 12.1679 11.3321C11.5156 10.6798 10.2891 10 7.99999 10C5.71088 10 4.48435 10.6798 3.8321 11.3321C3.15375 12.0104 3.00142 12.7497 3 12.9965H13Z"
      fill={'currentColor'}
    />
  </svg>
)

export const ArrowRightOutlined = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM9 16.2656C4.98828 16.2656 1.73438 13.0117 1.73438 9C1.73438 4.98828 4.98828 1.73438 9 1.73438C13.0117 1.73438 16.2656 4.98828 16.2656 9C16.2656 13.0117 13.0117 16.2656 9 16.2656Z" />
    <path d="M10.1236 5.69769L14.1883 8.57093C14.2589 8.62378 14.3155 8.68906 14.3542 8.76235C14.393 8.83564 14.4131 8.91525 14.4131 8.99579C14.4131 9.07633 14.393 9.15594 14.3542 9.22923C14.3155 9.30252 14.2589 9.3678 14.1883 9.42065L10.0767 12.2477C10.0482 12.2705 10.0095 12.2828 9.97073 12.2828H8.10599C7.95518 12.2828 7.88589 12.123 8.00002 12.037L11.5003 9.66293H3.78805C3.69838 9.66293 3.62502 9.59972 3.62502 9.52248V8.46911C3.62502 8.39186 3.69838 8.32866 3.78805 8.32866H11.5023L8.00002 5.87501C7.88589 5.79074 7.95518 5.62922 8.10599 5.62922H9.9096C9.98908 5.62922 10.0645 5.6538 10.1236 5.69769Z" />
  </svg>
);

export const TransactionOutlined = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.913 5.22727H4.33487L5.9033 3.14773C5.94787 3.08864 5.90765 3 5.83483 3H5.0468C4.99354 3 4.94355 3.025 4.90985 3.06932L3.07512 5.50227C2.89577 5.74091 3.05773 6.09091 3.34794 6.09091H10.913C10.9608 6.09091 10.9999 6.05 10.9999 6V5.31818C10.9999 5.26818 10.9608 5.22727 10.913 5.22727Z" />
    <path d="M10.651 7.90909H3.08707C3.03925 7.90909 3.00012 7.95 3.00012 8V8.68182C3.00012 8.73182 3.03925 8.77273 3.08707 8.77273H9.66516L8.09672 10.8523C8.05216 10.9114 8.09238 11 8.1652 11H8.95322C9.00648 11 9.05648 10.975 9.09018 10.9307L10.9249 8.49773C11.1043 8.25909 10.9423 7.90909 10.651 7.90909Z" />
    <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" />
  </svg>
)

export const TetherOutlined = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99976 0C3.13413 0 -0.000244141 3.13438 -0.000244141 7C-0.000244141 10.8656 3.13413 14 6.99976 14C10.8654 14 13.9998 10.8656 13.9998 7C13.9998 3.13438 10.8654 0 6.99976 0ZM6.99976 12.8125C3.79038 12.8125 1.18726 10.2094 1.18726 7C1.18726 3.79063 3.79038 1.1875 6.99976 1.1875C10.2091 1.1875 12.8123 3.79063 12.8123 7C12.8123 10.2094 10.2091 12.8125 6.99976 12.8125Z" />
    <path d="M7.68729 6.11121V5.32933H9.46604V4.09558H4.52854V5.32933H6.31229V6.11121C4.81792 6.18308 3.69604 6.48308 3.69604 6.84246C3.69604 7.20183 4.81667 7.49996 6.31229 7.57371V10.1543H7.68729V7.57371C9.18167 7.50246 10.3035 7.20246 10.3035 6.84246C10.3035 6.48246 9.18292 6.18308 7.68729 6.11121ZM6.99979 7.33996C5.38167 7.33996 4.0698 7.08996 4.0698 6.78871C4.0698 6.52933 5.02542 6.31183 6.31104 6.25371V7.13683C6.53229 7.14683 6.76229 7.15246 6.99854 7.15246C7.23479 7.15246 7.46604 7.14683 7.68604 7.13683V6.25371C8.97167 6.31183 9.92729 6.52933 9.92729 6.78871C9.92979 7.09308 8.61792 7.33996 6.99979 7.33996Z" />
  </svg>
)

export const LockOutlined = () => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46094 5.92992H10.125C10.2979 5.92992 10.4375 6.06957 10.4375 6.24242V9.99242C10.4375 10.1653 10.2979 10.3049 10.125 10.3049H3.875C3.70215 10.3049 3.5625 10.1653 3.5625 9.99242V6.24242C3.5625 6.06957 3.70215 5.92992 3.875 5.92992H4.53906V4.00758C4.53906 3.31715 5.09863 2.75758 5.78906 2.75758H8.21094C8.90137 2.75758 9.46094 3.31715 9.46094 4.00758V5.92992ZM5.55879 4.39773C5.55879 4.09597 5.8039 3.85085 6.10566 3.85085H7.90029C8.20205 3.85085 8.44717 4.09597 8.44717 4.39773V5.92992H5.55879V4.39773ZM4.60566 6.99242V9.25H9.39434V6.99242H4.60566ZM6.72656 8.24438V8.76196C6.72656 8.80492 6.76172 8.84008 6.80469 8.84008H7.19531C7.23828 8.84008 7.27344 8.80492 7.27344 8.76196V8.24438C7.35405 8.1865 7.41422 8.10454 7.44528 8.01029C7.47635 7.91604 7.47671 7.81437 7.44631 7.7199C7.41591 7.62543 7.35632 7.54305 7.27612 7.48461C7.19591 7.42616 7.09924 7.39468 7 7.39468C6.90076 7.39468 6.80409 7.42616 6.72388 7.48461C6.64368 7.54305 6.58409 7.62543 6.55369 7.7199C6.52329 7.81437 6.52365 7.91604 6.55472 8.01029C6.58578 8.10454 6.64595 8.1865 6.72656 8.24438Z" />
  </svg>
);

export const MoreOutlined = (color?: string) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6875 5.41406C10.6875 5.76216 10.8258 6.096 11.0719 6.34214C11.3181 6.58828 11.6519 6.72656 12 6.72656C12.3481 6.72656 12.6819 6.58828 12.9281 6.34214C13.1742 6.096 13.3125 5.76216 13.3125 5.41406C13.3125 5.06597 13.1742 4.73213 12.9281 4.48598C12.6819 4.23984 12.3481 4.10156 12 4.10156C11.6519 4.10156 11.3181 4.23984 11.0719 4.48598C10.8258 4.73213 10.6875 5.06597 10.6875 5.41406ZM10.6875 11.9766C10.6875 12.3247 10.8258 12.6585 11.0719 12.9046C11.3181 13.1508 11.6519 13.2891 12 13.2891C12.3481 13.2891 12.6819 13.1508 12.9281 12.9046C13.1742 12.6585 13.3125 12.3247 13.3125 11.9766C13.3125 11.6285 13.1742 11.2946 12.9281 11.0485C12.6819 10.8023 12.3481 10.6641 12 10.6641C11.6519 10.6641 11.3181 10.8023 11.0719 11.0485C10.8258 11.2946 10.6875 11.6285 10.6875 11.9766ZM10.6875 18.5391C10.6875 18.8872 10.8258 19.221 11.0719 19.4671C11.3181 19.7133 11.6519 19.8516 12 19.8516C12.3481 19.8516 12.6819 19.7133 12.9281 19.4671C13.1742 19.221 13.3125 18.8872 13.3125 18.5391C13.3125 18.191 13.1742 17.8571 12.9281 17.611C12.6819 17.3648 12.3481 17.2266 12 17.2266C11.6519 17.2266 11.3181 17.3648 11.0719 17.611C10.8258 17.8571 10.6875 18.191 10.6875 18.5391Z"
      fill={color ?? 'black'}
      // fillOpacity="0.45"
    />
  </svg>
);

export const DealsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0H9.5V1H4C3.44772 1 3 1.44772 3 2V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4.5H14V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2C2 0.89543 2.89543 0 4 0Z"
      fill={'currentColor'}
    />
    <path
      d="M9.5 3V0L14 4.5H11C10.1716 4.5 9.5 3.82843 9.5 3Z"
      fill={'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.5C5 11.2239 5.22386 11 5.5 11H7.5C7.77614 11 8 11.2239 8 11.5C8 11.7761 7.77614 12 7.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
      fill={'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.5C5 9.22386 5.22386 9 5.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H5.5C5.22386 10 5 9.77614 5 9.5Z"
      fill={'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7.5C5 7.22386 5.22386 7 5.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z"
      fill={'currentColor'}
    />
  </svg>
);

export const DealsComponentIcon = ({ color }: { color?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0H9.5V1H4C3.44772 1 3 1.44772 3 2V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4.5H14V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2C2 0.89543 2.89543 0 4 0Z"
      fill={color ? color : 'currentColor'}
    />
    <path
      d="M9.5 3V0L14 4.5H11C10.1716 4.5 9.5 3.82843 9.5 3Z"
      fill={color ? color : 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.5C5 11.2239 5.22386 11 5.5 11H7.5C7.77614 11 8 11.2239 8 11.5C8 11.7761 7.77614 12 7.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
      fill={color ? color : 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.5C5 9.22386 5.22386 9 5.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H5.5C5.22386 10 5 9.77614 5 9.5Z"
      fill={color ? color : 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7.5C5 7.22386 5.22386 7 5.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z"
      fill={color ? color : 'currentColor'}
    />
  </svg>
);

export const CorporateNoticesIcon = ({ color }: { color?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.09999 1.66654C5.04476 1.66654 4.99999 1.71131 4.99999 1.76654V3.19987H9.19999V1.76654C9.19999 1.71131 9.15522 1.66654 9.09999 1.66654H5.09999ZM10.3333 3.19987V1.76654C10.3333 1.08539 9.78114 0.533203 9.09999 0.533203H5.09999C4.41884 0.533203 3.86666 1.08539 3.86666 1.76654V3.19987H1.76666C1.08551 3.19987 0.533325 3.75205 0.533325 4.4332V11.7665C0.533325 12.4477 1.08551 12.9999 1.76666 12.9999H12.4333C13.1145 12.9999 13.6667 12.4477 13.6667 11.7665V4.4332C13.6667 3.75205 13.1145 3.19987 12.4333 3.19987H10.3333ZM9.19999 4.3332V5.76654H10.3333V4.3332H12.4333C12.4886 4.3332 12.5333 4.37797 12.5333 4.4332V8.5332H1.66666V4.4332C1.66666 4.37797 1.71143 4.3332 1.76666 4.3332H3.86666V5.76654H4.99999V4.3332H9.19999ZM1.66666 9.66654V11.7665C1.66666 11.8218 1.71143 11.8665 1.76666 11.8665H12.4333C12.4886 11.8665 12.5333 11.8218 12.5333 11.7665V9.66654H1.66666Z"
      fill={color ? color : 'currentColor'}
      fillOpacity="0.8"
    />
  </svg>
);

export const LogoutIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 14C2.73333 14 2.5 13.9 2.3 13.7C2.1 13.5 2 13.2667 2 13V3C2 2.73333 2.1 2.5 2.3 2.3C2.5 2.1 2.73333 2 3 2H7.85V3H3V13H7.85V14H3ZM11.1 10.9167L10.3833 10.2L12.0833 8.5H6.25V7.5H12.05L10.35 5.8L11.0667 5.08333L14 8.01667L11.1 10.9167Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const DropdownIconSimpleColor = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24775 11.1399L2.45152 5.6585C1.88576 5.01192 2.34494 4 3.20409 4H12.7966C13.6557 4 14.1149 5.01192 13.5491 5.6585L8.7529 11.1399C8.35449 11.5952 7.64616 11.5952 7.24775 11.1399Z"
      fill={'currentColor'}
    />
  </svg>
);

export const WalletIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1362 0.325799C13.0829 0.0891186 14 0.805156 14 1.78101V3.00024H14.5C15.3284 3.00024 16 3.67181 16 4.50024V13.5002C16 14.3287 15.3284 15.0002 14.5 15.0002H1.5C0.671573 15.0002 0 14.3287 0 13.5002V4.50024C0 3.69444 0.635386 3.03704 1.43248 3.00173L12.1362 0.325799ZM5.56155 3.00024H13V1.78101C13 1.45573 12.6943 1.21705 12.3787 1.29594L5.56155 3.00024ZM1.5 4.00024C1.22386 4.00024 1 4.22409 1 4.50024V13.5002C1 13.7764 1.22386 14.0002 1.5 14.0002H14.5C14.7761 14.0002 15 13.7764 15 13.5002V4.50024C15 4.22409 14.7761 4.00024 14.5 4.00024H1.5Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const Transaction = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50221 5.5133C9.40928 5.47168 9.3 5.53942 9.3 5.64717V6.65045C9.3 6.92659 9.07614 7.15045 8.8 7.15045H2.5V10.0504H8.8C9.07614 10.0504 9.3 10.2743 9.3 10.5504V11.5537C9.3 11.6615 9.40928 11.7292 9.5022 11.6876L13.4857 8.7548C13.4994 8.74471 13.5137 8.73532 13.5283 8.72668C13.6239 8.67037 13.6239 8.53052 13.5283 8.47421C13.5137 8.46557 13.4994 8.45618 13.4857 8.44609L9.50221 5.5133ZM8.3 5.64717C8.3 4.75174 9.2694 4.21431 10.0247 4.6594C10.0393 4.66805 10.0536 4.67743 10.0673 4.68752L14.0608 7.62766C14.7797 8.07481 14.7797 9.12607 14.0608 9.57323L10.0673 12.5134C10.0536 12.5235 10.0393 12.5328 10.0247 12.5415C9.2694 12.9866 8.3 12.4492 8.3 11.5537V11.0504H2C1.72386 11.0504 1.5 10.8266 1.5 10.5504V6.65045C1.5 6.3743 1.72386 6.15045 2 6.15045H8.3V5.64717Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const ExchangeOrderIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.37381 8.08333H10.9026C10.985 8.08333 11.03 8.18634 10.9773 8.25419L10.2129 9.23731C10.174 9.28728 10.1024 9.28728 10.0636 9.23731L9.29914 8.25419C9.24639 8.18634 9.2914 8.08333 9.37381 8.08333Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M5.09738 8.91667H6.62619C6.7086 8.91667 6.75361 8.81366 6.70086 8.74581L5.93645 7.76269C5.89759 7.71272 5.82598 7.71272 5.78712 7.76269L5.02271 8.74581C4.96996 8.81366 5.01497 8.91667 5.09738 8.91667Z"
      fill={color ? color : '#767687'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.41667C7.39644 6.41667 6.85725 6.71124 6.50041 7.17433C6.43204 7.26304 6.30952 7.27557 6.22675 7.2023C6.14397 7.12903 6.13229 6.99772 6.20065 6.909C6.62818 6.35418 7.27558 6 8 6C9.14364 6 10.0947 6.88188 10.2941 8.0457C10.2963 8.05821 10.2983 8.07076 10.3003 8.08333H9.90499C9.72498 7.13241 8.94028 6.41667 8 6.41667ZM6.09501 8.91667C6.27502 9.86759 7.05972 10.5833 8 10.5833C8.60356 10.5833 9.14275 10.2888 9.49959 9.82567C9.56796 9.73695 9.69048 9.72443 9.77325 9.7977C9.85603 9.87097 9.86771 10.0023 9.79935 10.091C9.37182 10.6458 8.72442 11 8 11C6.85636 11 5.90532 10.1181 5.70586 8.9543C5.70371 8.94179 5.70165 8.92924 5.69968 8.91667H6.09501Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M4 0H9.5V1H4C3.44772 1 3 1.44772 3 2V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4.5H14V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2C2 0.89543 2.89543 0 4 0Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M9.5 3V0L14 4.5H11C10.1716 4.5 9.5 3.82843 9.5 3Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const P2pOrdersIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0H9.5V1H4C3.44772 1 3 1.44772 3 2V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4.5H14V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2C2 0.89543 2.89543 0 4 0Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M9.5 3V0L14 4.5H11C10.1716 4.5 9.5 3.82843 9.5 3Z"
      fill={color ? color : '#767687'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.5C5 11.2239 5.22386 11 5.5 11H7.5C7.77614 11 8 11.2239 8 11.5C8 11.7761 7.77614 12 7.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
      fill={color ? color : '#767687'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.5C5 9.22386 5.22386 9 5.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H5.5C5.22386 10 5 9.77614 5 9.5Z"
      fill={color ? color : '#767687'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7.5C5 7.22386 5.22386 7 5.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const CircleIcon = (color?: string) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.375C7.41625 9.375 9.375 7.41625 9.375 5C9.375 2.58375 7.41625 0.625 5 0.625C2.58375 0.625 0.625 2.58375 0.625 5C0.625 7.41625 2.58375 9.375 5 9.375ZM5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const InfoCircle = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M9.4307 6.08789L7.13969 6.375L7.05766 6.75586L7.50883 6.83789C7.8018 6.9082 7.86039 7.01367 7.79594 7.30664L7.05766 10.7754C6.8643 11.6719 7.16312 12.0938 7.86625 12.0938C8.41117 12.0938 9.04398 11.8418 9.33109 11.4961L9.41898 11.0801C9.21977 11.2559 8.9268 11.3262 8.73344 11.3262C8.45805 11.3262 8.35844 11.1328 8.42875 10.793L9.4307 6.08789Z"
      fill={color ? color : '#767687'}
    />
    <circle cx="8.5" cy="4" r="1" fill={color ? color : '#767687'} />
  </svg>
);

export const QuestionCircleIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={color ? color : '#767687'}
    />
    <path
      d="M5.25511 5.78615C5.24752 5.92237 5.3599 6.03271 5.49634 6.03271H6.32082C6.45889 6.03271 6.56868 5.92013 6.58723 5.78331C6.67618 5.12718 7.1265 4.64893 7.92922 4.64893C8.61477 4.64893 9.24318 4.9917 9.24318 5.81689C9.24318 6.45166 8.86867 6.74365 8.27834 7.18799C7.60549 7.67676 7.07229 8.24805 7.11037 9.1748L7.11334 9.39161C7.11521 9.52833 7.22658 9.63818 7.36332 9.63818H8.17434C8.31241 9.63818 8.42434 9.52625 8.42434 9.38818V9.28271C8.42434 8.56543 8.69729 8.35596 9.43361 7.79736C10.043 7.33398 10.6778 6.81982 10.6778 5.74072C10.6778 4.22998 9.40188 3.5 8.00539 3.5C6.73831 3.5 5.34964 4.09061 5.25511 5.78615ZM6.81203 11.5488C6.81203 12.082 7.23732 12.4756 7.82131 12.4756C8.43068 12.4756 8.84963 12.082 8.84963 11.5488C8.84963 10.9966 8.43068 10.6094 7.82131 10.6094C7.23732 10.6094 6.81203 10.9966 6.81203 11.5488Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const ArrowDropdownIcon = (color?: string) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53674e-07 3.92857V6.5H8H16L9.94595 0.5V3.92857H9.53674e-07Z"
      fill={color ? color : '#FAFAFC'}
    />
    <path
      d="M0 10.0714V7.5H16L9.94595 13.5V10.0714H0Z"
      fill={color ? color : '#FAFAFC'}
    />
  </svg>
);

export const CaretIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24873 11.1399L2.45249 5.6585C1.88673 5.01192 2.34591 4 3.20507 4H12.7975C13.6567 4 14.1159 5.01192 13.5501 5.6585L8.75388 11.1399C8.35547 11.5952 7.64714 11.5952 7.24873 11.1399Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

// TODO: it seems this can be replaced with MinusCircleOutlined
export const DashCircle = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#FBFBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C4 7.72386 4.22386 7.5 4.5 7.5H11.5C11.7761 7.5 12 7.72386 12 8C12 8.27614 11.7761 8.5 11.5 8.5H4.5C4.22386 8.5 4 8.27614 4 8Z"
      fill={color ? color : '#FBFBFF'}
    />
  </svg>
);

export const WhiteTetherIcon = ({ size = 16 }: { size?: number } = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 34 31"
    fill="none"
  >
    <path
      d="M20.4406 10.5562V6.64678H29.3344V0.478027H4.64687V6.64678H13.5656V10.5562C6.09375 10.9155 0.484375 12.4155 0.484375 14.2124C0.484375 16.0093 6.0875 17.4999 13.5656 17.8687V30.7718H20.4406V17.8687C27.9125 17.5124 33.5219 16.0124 33.5219 14.2124C33.5219 12.4124 27.9187 10.9155 20.4406 10.5562ZM17.0031 16.6999C8.9125 16.6999 2.35312 15.4499 2.35312 13.9437C2.35312 12.6468 7.13125 11.5593 13.5594 11.2687V15.6843C14.6656 15.7343 15.8156 15.7624 16.9969 15.7624C18.1781 15.7624 19.3344 15.7343 20.4344 15.6843V11.2687C26.8625 11.5593 31.6406 12.6468 31.6406 13.9437C31.6531 15.4655 25.0937 16.6999 17.0031 16.6999Z"
      fill="#FBFBFF"
    />
  </svg>
);

export const WhiteEthIcon = ({ size = 16 }: { size?: number } = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M8.78125 20.5156L20.0031 2.1875L31.225 20.5156L20.0031 27.0531L8.78125 20.5156Z"
      fill="#FBFBFF"
    />
    <path
      d="M20.0016 29.1124L30.8641 22.3687L20.0016 37.8124L8.88281 22.3687L20.0016 29.1124Z"
      fill="#FBFBFF"
    />
  </svg>
);

export const WhiteBitcoinIcon = ({ size = 16 }: { size?: number } = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 26 36"
    fill="none"
  >
    <path
      d="M24.8062 23.2374C24.5875 20.6999 21.9937 19.003 21.9937 19.003C21.9937 19.003 25.3 18.1062 25.6406 14.1874C25.8562 11.6874 24.2812 9.07803 20.3281 7.91553L21.6906 2.76865L18.4469 1.90928L17.0875 7.04365L14.3969 6.33115L15.7562 1.19678L12.5062 0.337402L11.1469 5.47178L5.92187 4.0874L5.0625 7.32803L8.24375 8.16865L4.09375 23.8468L0.9125 22.9999L0 26.4374L5.225 27.8218L3.86562 32.9655L7.12812 33.8437L8.49063 28.6999L11.1812 29.4124L9.80625 34.5624L13.0656 35.4155L14.4281 30.2718L15.1812 30.4687C21.5312 32.0624 25.1812 27.6124 24.8062 23.2374ZM13.6219 9.59365C13.6219 9.59365 14.9406 9.93428 16.8344 10.4437C18.6312 10.928 20 12.5155 19.5781 14.3062C19.1562 16.0968 17.2844 17.2374 15.3187 16.7499C13.5156 16.2999 11.9625 15.8624 11.9625 15.8624L13.6219 9.59365ZM13.7687 26.4093C11.8937 25.953 9.47188 25.2718 9.47188 25.2718L11.1906 18.7624C11.1906 18.7624 13.3781 19.3874 15.325 19.8562C17.6531 20.4312 19.075 21.9593 18.5625 24.0062C18.05 26.053 16.2969 27.0249 13.7687 26.4093Z"
      fill="#FBFBFF"
    />
  </svg>
);

export const CoinIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0983 9.56432L17.6641 11.9985L20.0983 14.4328L22.5325 11.9985L20.0983 9.56432Z"
      fill="#FBFBFF"
    />
    <path
      d="M3.90297 9.56578L1.46875 12L3.90297 14.4342L6.33718 12L3.90297 9.56578Z"
      fill="#FBFBFF"
    />
    <path
      d="M12 17.6663L7.965 13.6313L5.53125 16.0651L12 22.5338L18.4688 16.0651L16.035 13.6313L12 17.6663Z"
      fill="#FBFBFF"
    />
    <path
      d="M12 6.33381L16.035 10.3688L18.4688 7.93506L12 1.46631L5.53125 7.93506L7.965 10.3688L12 6.33381Z"
      fill="#FBFBFF"
    />
    <path
      d="M11.9975 9.59628L9.59375 12L11.9975 14.4037L14.4012 12L11.9975 9.59628Z"
      fill="#FBFBFF"
    />
  </svg>
);

export const PencilSquare = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14.5016 1.93934C14.6969 2.1346 14.6969 2.45118 14.5016 2.64645L13.4587 3.68933L11.4587 1.68933L12.5016 0.646447C12.6969 0.451184 13.0134 0.451185 13.2087 0.646447L14.5016 1.93934Z"
      fill="#FBFBFF"
    />
    <path
      d="M12.7516 4.39644L10.7516 2.39644L3.93861 9.20943C3.88372 9.26432 3.84237 9.33123 3.81782 9.40487L3.01326 11.8186C2.94812 12.014 3.13405 12.1999 3.32949 12.1348L5.74317 11.3302C5.81681 11.3057 5.88372 11.2643 5.93861 11.2094L12.7516 4.39644Z"
      fill="#FBFBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5V13.5Z"
      fill="#FBFBFF"
    />
  </svg>
);

export const SelectIcon = ({ fill }: { fill?: string } = {}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.24677 11.1399L2.45054 5.6585C1.88478 5.01192 2.34396 4 3.20312 4H12.7956C13.6547 4 14.1139 5.01192 13.5482 5.6585L8.75193 11.1399C8.35352 11.5952 7.64518 11.5952 7.24677 11.1399Z"
        fill={fill ?? '#6B6E75'}
      />
    </svg>
  );
};

export const EyeSlashIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0383 16.8564C22.5919 14.5815 24 12.0001 24 12.0001C24 12.0001 19.5 3.75006 12 3.75006C10.4769 3.75006 9.07758 4.09029 7.81451 4.63256L8.97081 5.78887C9.91475 5.44982 10.9266 5.25006 12 5.25006C15.1791 5.25006 17.8185 7.00233 19.7518 8.93572C20.7055 9.88941 21.4468 10.848 21.9491 11.5687C22.0582 11.7252 22.1556 11.8699 22.2409 12.0001C22.1556 12.1302 22.0582 12.2749 21.9491 12.4314C21.4468 13.1522 20.7055 14.1107 19.7518 15.0644C19.5046 15.3116 19.2458 15.5559 18.9759 15.7939L20.0383 16.8564Z"
        fill="#6B6E75"
      />
      <path
        d="M16.9462 13.7643C17.1429 13.2129 17.25 12.619 17.25 12.0001C17.25 9.10056 14.8995 6.75006 12 6.75006C11.3811 6.75006 10.7872 6.85716 10.2358 7.05384L11.4693 8.28732C11.6426 8.26276 11.8198 8.25006 12 8.25006C14.0711 8.25006 15.75 9.92899 15.75 12.0001C15.75 12.1802 15.7373 12.3574 15.7127 12.5308L16.9462 13.7643Z"
        fill="#6B6E75"
      />
      <path
        d="M12.5308 15.7128L13.7642 16.9463C13.2129 17.143 12.6189 17.2501 12 17.2501C9.1005 17.2501 6.75 14.8996 6.75 12.0001C6.75 11.3811 6.85711 10.7872 7.05379 10.2358L8.28727 11.4693C8.26271 11.6427 8.25 11.8199 8.25 12.0001C8.25 14.0711 9.92893 15.7501 12 15.7501C12.1802 15.7501 12.3574 15.7374 12.5308 15.7128Z"
        fill="#6B6E75"
      />
      <path
        d="M5.02416 8.20617C4.75418 8.4442 4.4954 8.68848 4.24816 8.93572C3.29446 9.88941 2.55324 10.848 2.05091 11.5687C1.94181 11.7252 1.84444 11.8699 1.75905 12.0001C1.84444 12.1302 1.94181 12.2749 2.05091 12.4314C2.55324 13.1522 3.29446 14.1107 4.24816 15.0644C6.18155 16.9978 8.82093 18.7501 12 18.7501C13.0734 18.7501 14.0853 18.5503 15.0292 18.2112L16.1855 19.3675C14.9224 19.9098 13.5231 20.2501 12 20.2501C4.5 20.2501 0 12.0001 0 12.0001C0 12.0001 1.40807 9.41859 3.96168 7.14369L5.02416 8.20617Z"
        fill="#6B6E75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4697 21.5304L2.46967 3.53039L3.53033 2.46973L21.5303 20.4697L20.4697 21.5304Z"
        fill="#6B6E75"
      />
    </svg>
  );
};

export const EyeIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 12 19.5 3.75 12 3.75C4.5 3.75 0 12 0 12C0 12 4.5 20.25 12 20.25C19.5 20.25 24 12 24 12ZM1.75905 12C1.84444 12.1302 1.94181 12.2748 2.05091 12.4314C2.55324 13.1521 3.29446 14.1106 4.24816 15.0643C6.18155 16.9977 8.82093 18.75 12 18.75C15.1791 18.75 17.8185 16.9977 19.7518 15.0643C20.7055 14.1106 21.4468 13.1521 21.9491 12.4314C22.0582 12.2748 22.1556 12.1302 22.2409 12C22.1556 11.8698 22.0582 11.7252 21.9491 11.5686C21.4468 10.8479 20.7055 9.88936 19.7518 8.93566C17.8185 7.00227 15.1791 5.25 12 5.25C8.82093 5.25 6.18155 7.00227 4.24816 8.93566C3.29446 9.88936 2.55324 10.8479 2.05091 11.5686C1.94181 11.7252 1.84444 11.8698 1.75905 12Z"
        fill="#6B6E75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25ZM6.75 12C6.75 9.1005 9.1005 6.75 12 6.75C14.8995 6.75 17.25 9.1005 17.25 12C17.25 14.8995 14.8995 17.25 12 17.25C9.1005 17.25 6.75 14.8995 6.75 12Z"
        fill="#6B6E75"
      />
    </svg>
  );
};

export const WhiteEyeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 12 19.5 3.75 12 3.75C4.5 3.75 0 12 0 12C0 12 4.5 20.25 12 20.25C19.5 20.25 24 12 24 12ZM1.75905 12C1.84444 12.1302 1.94181 12.2748 2.05091 12.4314C2.55324 13.1521 3.29446 14.1106 4.24816 15.0643C6.18155 16.9977 8.82093 18.75 12 18.75C15.1791 18.75 17.8185 16.9977 19.7518 15.0643C20.7055 14.1106 21.4468 13.1521 21.9491 12.4314C22.0582 12.2748 22.1556 12.1302 22.2409 12C22.1556 11.8698 22.0582 11.7252 21.9491 11.5686C21.4468 10.8479 20.7055 9.88936 19.7518 8.93566C17.8185 7.00227 15.1791 5.25 12 5.25C8.82093 5.25 6.18155 7.00227 4.24816 8.93566C3.29446 9.88936 2.55324 10.8479 2.05091 11.5686C1.94181 11.7252 1.84444 11.8698 1.75905 12Z"
        fill="#F3F3F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25ZM6.75 12C6.75 9.1005 9.1005 6.75 12 6.75C14.8995 6.75 17.25 9.1005 17.25 12C17.25 14.8995 14.8995 17.25 12 17.25C9.1005 17.25 6.75 14.8995 6.75 12Z"
        fill="#F3F3F8"
      />
    </svg>
  );
};
export const WhiteEyeSlashIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0383 16.8564C22.5919 14.5815 24 12.0001 24 12.0001C24 12.0001 19.5 3.75006 12 3.75006C10.4769 3.75006 9.07758 4.09029 7.81451 4.63256L8.97081 5.78887C9.91475 5.44982 10.9266 5.25006 12 5.25006C15.1791 5.25006 17.8185 7.00233 19.7518 8.93572C20.7055 9.88941 21.4468 10.848 21.9491 11.5687C22.0582 11.7252 22.1556 11.8699 22.2409 12.0001C22.1556 12.1302 22.0582 12.2749 21.9491 12.4314C21.4468 13.1522 20.7055 14.1107 19.7518 15.0644C19.5046 15.3116 19.2458 15.5559 18.9759 15.7939L20.0383 16.8564Z"
        fill="#F3F3F8"
      />
      <path
        d="M16.9462 13.7643C17.1429 13.2129 17.25 12.619 17.25 12.0001C17.25 9.10056 14.8995 6.75006 12 6.75006C11.3811 6.75006 10.7872 6.85716 10.2358 7.05384L11.4693 8.28732C11.6426 8.26276 11.8198 8.25006 12 8.25006C14.0711 8.25006 15.75 9.92899 15.75 12.0001C15.75 12.1802 15.7373 12.3574 15.7127 12.5308L16.9462 13.7643Z"
        fill="#F3F3F8"
      />
      <path
        d="M12.5308 15.7128L13.7642 16.9463C13.2129 17.143 12.6189 17.2501 12 17.2501C9.1005 17.2501 6.75 14.8996 6.75 12.0001C6.75 11.3811 6.85711 10.7872 7.05379 10.2358L8.28727 11.4693C8.26271 11.6427 8.25 11.8199 8.25 12.0001C8.25 14.0711 9.92893 15.7501 12 15.7501C12.1802 15.7501 12.3574 15.7374 12.5308 15.7128Z"
        fill="#F3F3F8"
      />
      <path
        d="M5.02416 8.20617C4.75418 8.4442 4.4954 8.68848 4.24816 8.93572C3.29446 9.88941 2.55324 10.848 2.05091 11.5687C1.94181 11.7252 1.84444 11.8699 1.75905 12.0001C1.84444 12.1302 1.94181 12.2749 2.05091 12.4314C2.55324 13.1522 3.29446 14.1107 4.24816 15.0644C6.18155 16.9978 8.82093 18.7501 12 18.7501C13.0734 18.7501 14.0853 18.5503 15.0292 18.2112L16.1855 19.3675C14.9224 19.9098 13.5231 20.2501 12 20.2501C4.5 20.2501 0 12.0001 0 12.0001C0 12.0001 1.40807 9.41859 3.96168 7.14369L5.02416 8.20617Z"
        fill="#F3F3F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4697 21.5304L2.46967 3.53039L3.53033 2.46973L21.5303 20.4697L20.4697 21.5304Z"
        fill="#F3F3F8"
      />
    </svg>
  );
};

export const WalletMenuIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1362 0.325554C13.0829 0.0888744 14 0.804911 14 1.78077V2.99999H14.5C15.3284 2.99999 16 3.67156 16 4.49999V13.5C16 14.3284 15.3284 15 14.5 15H1.5C0.671573 15 0 14.3284 0 13.5V4.49999C0 3.69419 0.635386 3.0368 1.43248 3.00148L12.1362 0.325554ZM5.56155 2.99999H13V1.78077C13 1.45548 12.6943 1.2168 12.3787 1.2957L5.56155 2.99999ZM1.5 3.99999C1.22386 3.99999 1 4.22385 1 4.49999V13.5C1 13.7761 1.22386 14 1.5 14H14.5C14.7761 14 15 13.7761 15 13.5V4.49999C15 4.22385 14.7761 3.99999 14.5 3.99999H1.5Z"
      fill={'currentColor'}
    />
  </svg>
);

export const TravelCardIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09999 2.66654C6.04476 2.66654 5.99999 2.71131 5.99999 2.76654V4.19987H10.2V2.76654C10.2 2.71131 10.1552 2.66654 10.1 2.66654H6.09999ZM11.3333 4.19987V2.76654C11.3333 2.08539 10.7811 1.5332 10.1 1.5332H6.09999C5.41884 1.5332 4.86666 2.08539 4.86666 2.76654V4.19987H2.76666C2.08551 4.19987 1.53333 4.75205 1.53333 5.4332V12.7665C1.53333 13.4477 2.08551 13.9999 2.76666 13.9999H13.4333C14.1145 13.9999 14.6667 13.4477 14.6667 12.7665V5.4332C14.6667 4.75205 14.1145 4.19987 13.4333 4.19987H11.3333ZM10.2 5.3332V6.76654H11.3333V5.3332H13.4333C13.4886 5.3332 13.5333 5.37797 13.5333 5.4332V9.5332H2.66666V5.4332C2.66666 5.37797 2.71143 5.3332 2.76666 5.3332H4.86666V6.76654H5.99999V5.3332H10.2ZM2.66666 10.6665V12.7665C2.66666 12.8218 2.71143 12.8665 2.76666 12.8665H13.4333C13.4886 12.8665 13.5333 12.8218 13.5333 12.7665V10.6665H2.66666Z"
      fill={color ? color : '#FBFBFF'}
    />
  </svg>
);

export const BarsFilter = () => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 6.5C4 6.22386 4.22386 6 4.5 6H7.5C7.77614 6 8 6.22386 8 6.5C8 6.77614 7.77614 7 7.5 7H4.5C4.22386 7 4 6.77614 4 6.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.5C2 3.22386 2.22386 3 2.5 3H9.5C9.77614 3 10 3.22386 10 3.5C10 3.77614 9.77614 4 9.5 4H2.5C2.22386 4 2 3.77614 2 3.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H11.5C11.7761 0 12 0.223858 12 0.5C12 0.776142 11.7761 1 11.5 1H0.5C0.223858 1 0 0.776142 0 0.5Z"
    />
  </svg>
);

export const SandglassIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9496 10.285L6.47399 6L10.9496 1.715C10.9522 1.71214 10.9549 1.70942 10.9577 1.70688C11.0975 1.56702 11.1927 1.38888 11.2312 1.19496C11.2698 1.00104 11.25 0.800048 11.1743 0.617379C11.0987 0.43471 10.9706 0.278565 10.8062 0.168678C10.6418 0.0587902 10.4486 9.16165e-05 10.2509 0H1.25087C1.05321 0.000215034 0.860041 0.0590028 0.695773 0.168938C0.531505 0.278873 0.403501 0.435024 0.327929 0.617667C0.252357 0.800311 0.232607 1.00125 0.271173 1.19512C0.30974 1.38898 0.404894 1.56706 0.544616 1.70688L0.552116 1.715L5.02774 6L0.552116 10.285L0.544616 10.2931C0.404894 10.4329 0.30974 10.611 0.271173 10.8049C0.232607 10.9987 0.252357 11.1997 0.327929 11.3823C0.403501 11.565 0.531505 11.7211 0.695773 11.8311C0.860041 11.941 1.05321 11.9998 1.25087 12H10.2509C10.4486 12 10.642 11.9414 10.8064 11.8316C10.9709 11.7217 11.0991 11.5656 11.1748 11.3829C11.2505 11.2002 11.2704 10.9992 11.2319 10.8052C11.1933 10.6112 11.0982 10.433 10.9584 10.2931C10.9553 10.2906 10.9524 10.2879 10.9496 10.285ZM3.86274 3.5H7.63899L5.75087 5.3075L3.86274 3.5ZM10.2509 1L8.68337 2.5H2.81837L1.25087 1H10.2509ZM1.25087 11L5.25087 7.17125V8.5C5.25087 8.63261 5.30354 8.75979 5.39731 8.85355C5.49108 8.94732 5.61826 9 5.75087 9C5.88347 9 6.01065 8.94732 6.10442 8.85355C6.19819 8.75979 6.25087 8.63261 6.25087 8.5V7.17125L10.2509 11H1.25087Z"
      fill="#DEDEE3" />
  </svg>
);
