import { useEffect } from 'react';

export const useBodyClickListener = (isOpen: boolean, onClose: () => void) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleClick = () => {
      onClose();
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [isOpen, onClose]);
};
