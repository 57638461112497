import { axios } from '../exios';
import { EXCHANGE_MAP_API_URL } from '../../packages/keycloak-client/constants';

export const confirmDeliverCrypto = async (id: number) => {
  const response = await axios.put(
    `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${id}/deliver-crypto`
  );

  return response;
};
