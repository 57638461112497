import { FC, ReactNode } from "react"

import { DealsStatusIcon } from "shared/ui/deals-status-icon"

interface IProps {
  name: string | number;
  label: ReactNode;
}

export const OfferStatusOption: FC<IProps> = (props) => {
  const { name, label } = props;

  return (
    <>
      <DealsStatusIcon status={name.toString()} hideDefault size={14} />
      {label}
    </>
  );
};
