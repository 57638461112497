import { useResize } from 'hooks/useResize';
import { useEffect } from 'react';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';

export const useModalToggleOnResizeOrClose = (
  isOpen: boolean,
  onCloseCondition: Function,
  onOpenCondition: Function
) => {
  const { isScreen720 } = useResize();
  const { isModalOpen, closeModal } = useModalState();
  useEffect(() => {
    if (
      (isScreen720 && isModalOpen(ModalKeys.ModalHeaderMenu)) ||
      (isModalOpen(ModalKeys.ModalHeaderMenu) && !isOpen)
    ) {
      closeModal(ModalKeys.ModalHeaderMenu);
      onCloseCondition();
    }
    if (!isScreen720 && isOpen) {
      onOpenCondition();
    }
  }, [isOpen, isScreen720]);
};
