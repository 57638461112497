import { useEffect } from 'react';
import { useNotificationAsideState } from './useNotificationAsideState';

export const useUpdateOnOpenNotifications = () => {
  const activeTab = useNotificationAsideState((state) => state.activeTab);

  const notifications = useNotificationAsideState(
    (state) => state.notifications
  );
  const setNotifications = useNotificationAsideState(
    (state) => state.setNotifications
  );

  useEffect(() => {
    if (activeTab === 'all') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        },
      ]);
    }
    if (activeTab === 'my_deals' || activeTab === 'corporate') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        },
        {
          field: 'type',
          operator: '=',
          value: activeTab,
        },
      ]);
    }
  }, [activeTab, notifications.length]);
};
