import {
  ItemWithSubtextStyled,
  ItemMainTextStlyed,
  ItemSubtextStyled,
} from '../styles';

interface IServicesItemProps {
  text: string;
  subtext: string;
  opened?: true;
}

export const ServicesItem = ({ text, subtext, opened }: IServicesItemProps) => (
  <ItemWithSubtextStyled data-subtext={true} data-is-current-service={opened}>
    <ItemMainTextStlyed>{text}</ItemMainTextStlyed>
    <ItemSubtextStyled>{subtext}</ItemSubtextStyled>
  </ItemWithSubtextStyled>
);
