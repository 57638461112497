import { styled } from 'styled-components';
import { Modal } from 'antd';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';

// TODO: finish adapting
export const ModalStyled = styled(Modal)`
  &.ant-modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    max-width: 100vw;
    padding: 0;
    background-color: #19191f;
    background-image: none;

    @media ${MEDIA_BREAKPOINT.MD} {
      padding: 80px 32px;
    }

    @media ${MEDIA_BREAKPOINT.XL} {
      padding: 54px 94px;
    }

    .ant-modal-body {
      height: 100%;
    }

    .ant-modal-content {
      position: relative;
      height: 100%;
      padding: 0;
      border-radius: 0;
      background-image: none;
    }

    .ant-modal-close {
      top: 16px;
      right: 16px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: #141414;
      color: white;
      border: 1px solid #424242;
      font-size: 18px;

      &:hover {
        background-color: #2b2b36;
      }

      @media ${MEDIA_BREAKPOINT.MD} {
        top: 24px;
        right: 24px;
      }
    }
  }
`;
