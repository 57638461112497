import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import { mainTheme } from './components/ui/themes';
import './i18n';
import './styles.css';
import { FullScreenLoader } from './components/full-screen-loader';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <React.Suspense fallback={<FullScreenLoader />}>
      <ConfigProvider theme={mainTheme}>
        <App />
      </ConfigProvider>
    </React.Suspense>
  </BrowserRouter>
);
