import * as React from 'react';
import { Space, Spin } from 'antd';
import styled from 'styled-components';

type Props = {
  //
};

export function FullScreenLoader(props: Props) {
  return (
    <Space
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Spin />
    </Space>
  );
}

export const AdaptiveFullScreenLoader = () => {
  return (
    <SpaceStyled>
      <div className="ant-spin">
        <div className="ant-spin-dots">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </SpaceStyled>
  );
};

const SpaceStyled = styled(Space)`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
