import styled, { keyframes } from 'styled-components';
import { Tooltip } from 'antd';

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
`;

export const NotificationListItemContainerStyled = styled.div`
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  word-wrap: anywhere;
  line-height: 22px;
`;

export const NotificationListItemHeaderStyled = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-start;
  margin-bottom: 5px;
  svg {
    margin-top: 4px;
  }
`;

export const NotificationIconBlock = styled.div`
  svg {
    fill: rgba(255, 255, 255);
  }
`;

export const NotificationListDotTooltipStyled = styled(Tooltip)`
  cursor: pointer;
`;

export const NotificationListTooltipContentStyled = styled.div`
  cursor: pointer;
  padding: 2px 3px;
`;

export const NotificationDotContainerItemStyled = styled.div`
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 auto;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #14141f;
  &:hover {
    cursor: pointer;
    border: 1px solid #08979c;
  }
`;

export const NotificationDotItemStyled = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 5px;
  background-color: #08979c;
  animation: ${pulseAnimation} 1.5s infinite;
`;

export const NotificationText = styled.span`
  color: rgba(255, 255, 255, 0.8);
`;

export const NotificationListItemTitleStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
`;

export const NotificationListItemDateStyled = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 16px;
`;
