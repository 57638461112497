import { NotificationAsideContent } from 'widgets/notificationAside/ui/NotificationAside/NotificationAsideContent/NotificationAsideContent';
import { ModalKeys } from '../../model/enums';
import { useModalState } from '../../model/useModalState';
import { ModalStyled } from './ModalNotifications.styles';
import { useUpdateOnOpenNotifications } from 'widgets/notificationAside/model/useUpdateOnOpenNotifications';
import { useWindowScrollLock } from 'shared/hooks/useWindowScrollLock';
import { useHeaderState } from 'state/header/useHeaderState';

export const ModalNotifications = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalNotifications)
  );

  const closeModal = useModalState((state) => state.closeModal);
  const setActiveDropdown = useHeaderState((state) => state.setActiveDropdown);

  const closeAddressModal = () => {
    closeModal(ModalKeys.ModalNotifications);
    setActiveDropdown(null);
  };

  useUpdateOnOpenNotifications();

  useWindowScrollLock(isModalOpen);

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeAddressModal}
      footer={null}
      maskClosable={false}
      centered
      width={'100%'}
    >
      <NotificationAsideContent />
    </ModalStyled>
  );
};
