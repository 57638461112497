import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import jwtDecode from 'jwt-decode';
import {
  ACCOUNT_URL,
  API_URL,
  EDENEX_ACCOUNT_URL,
} from '../packages/keycloak-client/constants';
import { axios } from '../shared/exios';
import { default as baseAxios } from 'axios';
import {
  deleteCookieByName,
  getCookieByName,
  setCookieByName,
} from '../shared/helpers/controlCookies';
import i18n from 'i18n';

type TUserAPI = {
  uid: string;
  account: {
    nickname: string;
    email: string;
    status: 'active' | 'banned' | 'deleted';
    finms_status: 'active' | 'banned' | null;
    coin_status: 'active' | 'banned' | 'moratorium' | null;
    lang: string;
    userId: number;
    uid: string;
    total: number;
    totalFiat: number;
    last_login: string;
  };
};

type TClientCardState = {
  isAuth: boolean;
  isLoading: boolean;
  serverTime: number;
  userAPI: TUserAPI;
  userFinmsApi: TUserFinms;
  logout: () => void;
  refreshToken: (config: any) => Promise<void>;
  setServerTime: (value: number) => void;
  getUserProfileFromAPI: () => Promise<TUserAPI['account']>;
  setIsAuth: (value: boolean) => void;
  getUserProfileFromAPIBirja: () => void;
  setUserFinms: (value: TUserFinms) => void;
  balance: {
    USDC: {
      amount: number | string;
    };
    USDT: {
      amount: number | string;
    };
  };
  total: any;
  totalFiat: any;
};

export type TDecodedToken = {
  acr: string;
  aud: string;
  azo: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
};

export type TUserFinms = {
  id: number;
  uid: string;
  companyId: number;
  roleId: number;
  username: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  email: string;
  status: string;
  contactInfo: string | null;
  createdAt: string;
  updatedAt: string;
  language: string;
  totalDebt: string;
  totalDebtAllCourseExist: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    companyId: number;
    createdAt: string;
    updatedAt: string;
  };
  company?: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    currencyKey: string;
    avatar: string;
    country: string | null;
    description: string | null;
    currency: {
      id: number;
      companyId: number;
      name: string;
      shortname: string;
      zerosNumber: number;
      status: string;
      type: string;
      createdAt: string | null;
      updatedAt: string | null;
      network: string | null;
    };
  };
};

export const useAuthState = create<TClientCardState>()(
  devtools(
    (set, get) => ({
      isAuth: false,
      isLoading: true,
      serverTime: 0,
      userAPI: {
        uid: '',
        account: {
          nickname: '',
          email: '',
          status: 'active',
          finms_status: null,
          coin_status: null,
          userId: 0,
          uid: '',
          total: 0,
          lang: '',
          totalFiat: 0,
          last_login: '',
        },
      },
      userFinmsApi: {
        id: 0,
        uid: '',
        companyId: 0,
        roleId: 0,
        username: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        email: '',
        status: '',
        contactInfo: null,
        createdAt: '',
        updatedAt: '',
        language: '',
        totalDebt: '0',
        totalDebtAllCourseExist: false,
        role: {
          id: 0,
          name: '',
          description: '',
          companyId: 0,
          createdAt: '',
          updatedAt: '',
        },
      },
      balance: {
        USDT: {
          amount: 0,
        },
        USDC: {
          amount: 0,
        },
      },
      total: '',
      totalFiat: '',
      setIsAuth: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isAuth = value;
          })
        );
      },
      refreshToken: async (config) => {
        try {
          if (localStorage.getItem('referee_refresh_token')) {
            const res = await baseAxios.post(
              `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
              {
                refresh_token: localStorage
                  .getItem('referee_refresh_token')!
                  .replace('Bearer ', ''),
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: '',
                },
              }
            );
            try {
              const { access_token, refresh_token } = res!.data?.data;
              const decodedToken: TDecodedToken = jwtDecode(access_token);

              if (refresh_token && access_token) {
                localStorage.setItem('referee_uid', decodedToken?.sub);
                localStorage.setItem('referee_token', access_token);
                localStorage.setItem('referee_refresh_token', refresh_token);

                baseAxios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${access_token}`;

                if (
                  typeof config === 'object' &&
                  !!Object.keys(config)?.length
                ) {
                  return await axios(config);
                }
              }
            } catch (e) {
              console.error('config refreshToken error', e);
            }
          } else if (!getCookieByName('refresh_token'))
            throw new Error('No refresh token');
          const res = await baseAxios.post(
            `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
            {
              refresh_token: getCookieByName('refresh_token')!.replace(
                'Bearer ',
                ''
              ),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: '',
              },
            }
          );

          try {
            const { access_token, refresh_token } = res!.data?.data;
            const decodedToken: TDecodedToken = jwtDecode(access_token);

            if (config && refresh_token && access_token) {
              setCookieByName('email', decodedToken?.email);
              setCookieByName('uid', decodedToken?.sub);
              setCookieByName('token', access_token);
              setCookieByName('refresh_token', refresh_token);
              baseAxios.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${access_token}`;

              if (typeof config === 'object' && !!Object.keys(config)?.length) {
                return await axios(config);
              }
            }
          } catch (e) {
            console.error('config refreshToken error', e);
          }
        } catch (e) {
          console.error('refreshToken error', e);
          useAuthState.getState().logout();
        }
      },
      setServerTime: (value: number) => {
        set(
          produce((draft) => {
            draft.serverTime = value;
          })
        );
      },
      setClientTime: (value: number) => {
        set(
          produce((draft) => {
            draft.clientTime += value;
          })
        );
      },
      logout: async () => {
        localStorage.clear();
        deleteCookieByName('token');
        deleteCookieByName('refresh_token');
        deleteCookieByName('uid');
        deleteCookieByName('email');
        deleteCookieByName('remainingTime');
        deleteCookieByName('refreshTokenTimestamp');
        deleteCookieByName('currentServerTime');
        deleteCookieByName('inviteToken');
        deleteCookieByName('email_verified');
        deleteCookieByName('inviteLink');
        deleteCookieByName('fromEdenex');
        deleteCookieByName('fromFinms');
        deleteCookieByName('fromPortal');
        deleteCookieByName('currentPointId');
        deleteCookieByName('channels');
        deleteCookieByName('currentServerTime');
        set(
          produce((draft) => {
            draft.isAuth = false;
          })
        );
      },

      getUserProfileFromAPIBirja: async () => {
        const response = await axios.get(`${API_URL}/account/api/me`);
        if (!!response) {
          set(
            produce((draft) => {
              draft.balance = response?.data?.balance;
              draft.total = response?.data?.total;
              draft.totalFiat = response?.data?.total_fiat;
            }),
            false,
            {
              type: 'useAuthStore => getUserProfileFromAPI',
            }
          );
        }
      },

      getUserProfileFromAPI: async () => {
        const response = await axios.get<TUserAPI['account']>(
          `${EDENEX_ACCOUNT_URL}/edenex-account/api/me`
        );
        const exchangeResponse = await axios.get(`${API_URL}/account/api/me`);
        if (!!response) {
          i18n.changeLanguage(response?.data?.lang);
          setCookieByName('i18nLang', response?.data?.lang);
          set(
            produce((draft) => {
              draft.userAPI.account = {
                ...draft.userAPI.account,
                ...response.data,
              };
              draft.userAPI.account.total = exchangeResponse.data.total;
            }),
            false,
            {
              type: 'useAuthStore => getUserProfileFromAPI',
            }
          );
        }
        return response.data;
      },
      setUserFinms: async (value) =>
        set(
          produce((draft) => {
            draft.userFinmsApi = value;
          })
        ),
    }),
    {
      anonymousActionType: 'useAuthState action',
      name: 'useClientStateV2',
    }
  )
);
