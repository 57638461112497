import { create } from 'zustand';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';

interface HeaderState {
  activeDropdown: ModalKeys | null;
  setActiveDropdown: (type: ModalKeys | null) => void;
}

export const useHeaderState = create<HeaderState>()((set) => ({
  activeDropdown: null,
  setActiveDropdown: (type) => set({ activeDropdown: type }),
}));
