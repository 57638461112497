import { TDeal, TDealsStatus } from 'interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEAL_STATUS } from 'shared/constants/deal-status';

export type TDefaultStatuses =
  | 'pending'
  | 'accepted'
  | 'processing'
  | 'warranty_collateral'
  | 'waiting_for_fiat_payment'
  | 'ready'
  | 'completed'
  | 'expired'
  | 'on_argument'
  | 'cancelled';

export const PROCESSING_STATUSES = new Set<TDealsStatus>([
  DEAL_STATUS.processingCancellation,
  DEAL_STATUS.processingCompletion,
  DEAL_STATUS.processingExpiration,
  DEAL_STATUS.processingFiatDelivery,
  DEAL_STATUS.processingHold,
  DEAL_STATUS.processingHoldFiatPayment,
]);

const LABELS = {
  payment: 'Оплата',
  waitingForPayment: 'Ожидание оплаты',
  approvingPayment: 'Подтверждение оплаты',
};

export const STATIC_STATUS: {
  [key in TDefaultStatuses | 'complaint' | 'waiting_for_fiat_receiving']: string;
} = {
  pending: 'На рассмотрении',
  accepted: 'Принята',
  processing: 'Обработка транзакции',
  warranty_collateral: 'Обеспечение гаранта',
  waiting_for_fiat_payment: LABELS.waitingForPayment,
  waiting_for_fiat_receiving: LABELS.approvingPayment,
  ready: 'Готова к обмену',
  completed: 'Выполнена',
  expired: 'Просрочена',
  on_argument: 'Открыт спор',
  complaint: 'Жалоба',
  cancelled: 'Отменена',
} as const;

export const getStatusLabel = (args: {
  status: TDealsStatus;
  isGuarantee?: boolean;
  isSelling?: boolean;
}): string => {
  const {
    status,
    isGuarantee = false,
    isSelling = false,
  } = args;

  const staticStatuses = STATIC_STATUS as { [key in TDealsStatus]?: string }

  if (staticStatuses[status]) {
    return staticStatuses[status]!;
  }

  const isProcessing = PROCESSING_STATUSES.has(status);

  if (isProcessing) {
    return STATIC_STATUS.processing;
  }

  if (!isGuarantee) {
    if (status === DEAL_STATUS.waitingForCryptoDelivery) {
      return isSelling ? LABELS.payment : LABELS.waitingForPayment;
    } else if (status === DEAL_STATUS.waitingForFiatDelivery) {
      return isSelling ? LABELS.waitingForPayment : LABELS.payment;
    } else if (status === DEAL_STATUS.waitingForFiatReceiving) {
      return LABELS.approvingPayment;
    }
  }

  console.error(`Status label was not found`, args);

  return 'Неизвестный статус';
};

export const useDealStatusLabel = (deal: TDeal) => {
  const { t, i18n } = useTranslation();

  const statusLabel = useMemo(() => {
    const statusLabel = getStatusLabel({
      status: deal.status,
      isGuarantee: !!deal.offer?.is_guarantee,
      isSelling: !!deal.offer?.is_buying_crypto,
    })

    return t(statusLabel);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, t, i18n.language]);

  return statusLabel;
};
