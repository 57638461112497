import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { LogoBig } from '../../ui/icons';
import {
  LanguageStyled,
  LayoutInnerLeftStyled,
  LayoutInnerRightStyled,
  LayoutInnerStyled,
  LayoutStyled,
  WrapperLanguageStyled,
} from './styles';
import { useResize } from '../../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import { LanguageModal } from '../../ui/modalLanguage';
import { Header } from '../../ui/header';
import { useAuthState } from '../../../state/useAuthState';
import FadeWrapper from '../../FadeWrapper';
import { useCookiesCustom } from '../../../hooks/useCookieCustom';

const LayoutAuth = (): JSX.Element => {
  const isLoading = useAuthState((state) => state.isLoading);
  const getUserProfileFromAPI = useAuthState(
    (state) => state.getUserProfileFromAPI
  );
  const { cookie, setCookie, removeCookie } = useCookiesCustom();
  const { Content } = Layout;
  const { i18n, t } = useTranslation();
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const { isScreen767 } = useResize();

  useEffect(() => {
    setIsOpenLanguageModal(false);
  }, [isScreen767]);

  useEffect(() => {
    if (!!cookie.token) {
      void getUserProfileFromAPI();
    }
  }, [cookie.token]);

  useEffect(() => {
    i18n.changeLanguage(cookie['i18nLang'] || 'en');
  }, [cookie['i18nLang']]);

  return (
    <>
      <FadeWrapper isVisible={isLoading}>
        <LayoutStyled>
          <Header />
          <LayoutInnerStyled $isScreen767={isScreen767}>
            {isScreen767 && (
              <>
                <LayoutInnerLeftStyled>
                  <LogoBig />
                </LayoutInnerLeftStyled>
              </>
            )}
            <LayoutInnerRightStyled $isScreen767={isScreen767}>
              <Content>
                <Outlet />
                {!isScreen767 && (
                  <WrapperLanguageStyled>
                    {t('authorizationForms.languageSelection')}
                    <LanguageStyled
                      onClick={() => setIsOpenLanguageModal(true)}
                    >
                      {i18n.language}
                    </LanguageStyled>
                  </WrapperLanguageStyled>
                )}
              </Content>
            </LayoutInnerRightStyled>
          </LayoutInnerStyled>
          {isOpenLanguageModal && (
            <LanguageModal
              open={isOpenLanguageModal}
              onCancel={() => setIsOpenLanguageModal(false)}
              title={t('authorizationForms.languageSelection')}
            />
          )}
        </LayoutStyled>
      </FadeWrapper>
    </>
  );
};

export default LayoutAuth;
