import './ChatAsideBell.css';
import { useEffect } from 'react';
import { CommentOutlined } from '@ant-design/icons';
import { BadgeStyled, ContainerStyled } from './ChatAsideBell.style';
import { useChatsState } from '../../../../state/chats/useChatsState';
import { useAuthState } from 'state/useAuthState';

interface ChatAsideBellProps {
  onClick: () => void;
}

export const ChatAsideBell = ({ onClick }: ChatAsideBellProps) => {
  const clientCount = useChatsState(
    (state) => state.unreadMessagesCounts.clientCount
  );
  const corporateCount = useChatsState(
    (state) => state.unreadMessagesCounts.corporateCount
  );
  const setUnreadMessagesCount = useChatsState(
    (state) => state.setUnreadMessagesCount
  );
  const isAdmin = !!useAuthState((state) => state.userFinmsApi?.company);

  useEffect(() => {
    setUnreadMessagesCount(isAdmin);
  }, [isAdmin]);

  return (
    <ContainerStyled
      onClick={onClick}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <BadgeStyled
        count={clientCount + corporateCount}
        size={'default'}
        color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
        showZero={true}
        offset={[7, 0]}
      >
        <CommentOutlined />
      </BadgeStyled>
    </ContainerStyled>
  );
};
