import { ErrorBoundary } from '../../components/ErrorBoundary';
import React, { Suspense, useState } from 'react';
import { Button, Form, Input, Popover } from 'antd';
import { useResize } from '../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import {
  FormBorderStyled,
  FormButtonCancelStyled,
  FormFooterStyled,
  FormItemStyled,
  FormStyled,
  FormSubTitleStyled,
  FormTitleStyled,
  PrimaryFormButtonStyled,
  StyledTextCard,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { TG_SUPPORT } from '../../shared/constants';
import { useLoginState } from '../../state/useLoginState';
import { hideEmail } from '../../shared/helpers/hideEmail';
import { ROUTER_PATH } from '../../router/path';
import { CrossMarkIcon } from '../../assets/img/icons/icons';

export const LoginConfirm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isScreen767, isScreen991 } = useResize();

  const getSessionTokenByHash = useLoginState(
    (state) => state.getSessionTokenByHash
  );

  const setIsErrorMaxCountSelectCode = useLoginState(
    (state) => state.setIsErrorMaxCountSelectCode
  );

  const setIsErrorTimeoutCode = useLoginState(
    (state) => state.setIsErrorTimeoutCode
  );

  const [errorText, setErrorText] = useState('');
  const [code, setCode] = useState<number | null>(null);
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const redirectUrl = localStorage.getItem('redirectUrl') || '';

  if (!localStorage.getItem('loginHash')) {
    navigate(ROUTER_PATH.FormsPages.LOGIN);
    return null;
  }

  const handleSubmitCode = () => {
    if (errorText) return;

    const inviteToken = localStorage.getItem('inviteToken');

    form.validateFields().then((value) => {
      setIsLoading(true);
      getSessionTokenByHash(value.totp)
        .then(() => {
          if (redirectUrl) {
            if (inviteToken) {
              navigate(
                `${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}&redirect_url=${redirectUrl}`
              );
            } else {
              window.location.href = redirectUrl;
            }
          } else {
            navigate(ROUTER_PATH.AuthRoutes.MY_DEALS);
          }

          setTimeout(() => {
            localStorage.removeItem('loginHash');
          }, 3000);
        })
        .catch((e) => {
          e.response.data.errors.code.forEach((el: string) => {
            if (el.includes('Two factor authentication code is expired')) {
              setErrorText(el);
            } else if (el.includes('Hash or code is invalid')) {
              setErrorText(el);
            }
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const validateCode = (): string | null => {
    if (errorText === 'Two factor authentication code is expired') {
      setIsErrorTimeoutCode(true);
      navigate(ROUTER_PATH.FormsPages.LOGIN);
      localStorage.removeItem('loginHash');
    }
    if (
      errorText === 'Hash or code is invalid, number of remaining attempts - 2'
    ) {
      return t('Неверный код. У вас осталось попытки', {
        count: 2,
      }).toString();
    }
    if (
      errorText === 'Hash or code is invalid, number of remaining attempts - 1'
    ) {
      return t('Неверный код. У вас осталось попыткa', {
        count: 1,
      }).toString();
    }
    if (
      errorText ===
        'Hash or code is invalid, number of remaining attempts - 0' ||
      errorText === 'The maximum number of code entries has been reached - 3'
    ) {
      setIsErrorMaxCountSelectCode(true);
      navigate(ROUTER_PATH.FormsPages.LOGIN);
      localStorage.removeItem('loginHash');
    }

    return null;
  };

  const codeValid = validateCode();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d+$/;
    if (reg.test(inputValue) || inputValue === '') {
      form.setFieldValue('totp', inputValue);
      setCode(!!inputValue ? +(inputValue as string) : null);
      setVisibleTooltip(false);
    } else {
      setVisibleTooltip(true);
      form.setFieldValue('totp', code);
    }
  };

  const handleCancel = () => {
    form.setFieldValue('totp', '');
    localStorage.removeItem('loginHash');
    navigate(ROUTER_PATH.FormsPages.LOGIN);
  };

  const openWindowTG = () => window.open(TG_SUPPORT);

  return (
    <ErrorBoundary>
      <Suspense fallback={'Loading...'}>
        <FormBorderStyled $isScreen767={isScreen767} $isScreen991={isScreen991}>
          <FormTitleStyled>{t('authorizationForms.signIn')}</FormTitleStyled>
          <FormSubTitleStyled>
            {t('На адрес электронной почты был отправлен код идентификации', {
              email: hideEmail(localStorage.getItem('loginEmail')!.toString()),
            })}
          </FormSubTitleStyled>
          <FormStyled
            onFinish={handleSubmitCode}
            layout={'vertical'}
            requiredMark={false}
            form={form}
            autoComplete="off"
            onChange={() => setErrorText('')}
          >
            <FormSubTitleStyled>
              {t('authorizationForms.emptyField')}
            </FormSubTitleStyled>
            <Popover
              open={visibleTooltip}
              placement="left"
              content={
                <StyledTextCard>
                  <CrossMarkIcon />
                  <span>{t(`Допускаются символы двоеточие`)} 0-9</span>
                </StyledTextCard>
              }
            >
              <FormItemStyled
                name="totp"
                rules={[
                  {
                    required: true,
                    message: t('Введите код идентификации для авторизации'),
                  },
                ]}
                validateStatus={
                  codeValid || visibleTooltip ? 'error' : 'success'
                }
                help={codeValid}
              >
                <Input
                  placeholder={`${t('authorizationForms.identificationCode')}`}
                  onPressEnter={handleSubmitCode}
                  onChange={handleChange}
                  maxLength={8}
                />
              </FormItemStyled>
            </Popover>

            {/*<FormSubTitleStyled>Отправить новый код</FormSubTitleStyled>*/}

            <PrimaryFormButtonStyled
              loading={isLoading}
              type={'primary'}
              htmlType="submit"
              id={'confirm-2fa'}
            >
              {t('authorizationForms.confirm')}
            </PrimaryFormButtonStyled>
            <FormButtonCancelStyled onClick={handleCancel} id={'cancel-2fa'}>
              {t('authorizationForms.cancel')}
            </FormButtonCancelStyled>
          </FormStyled>
          <FormFooterStyled $isScreen767={isScreen767}>
            <div>{t('authorizationForms.problemsWithCode')}</div>
            <Button onClick={openWindowTG} type={'ghost'}>
              {t('authorizationForms.help')}
            </Button>
          </FormFooterStyled>
        </FormBorderStyled>
      </Suspense>
    </ErrorBoundary>
  );
};
