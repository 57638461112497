import React, { ReactNode, useState } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SelectProps } from 'rc-select/lib/Select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { ConfigProvider } from 'antd';
import { CaretDownOutlined, MinusCircleOutlined } from '@ant-design/icons';

import {
  FilterContainerStyled,
  LabelStyled,
  SelectDropdownStyled,
  SelectStyled,
} from './OfferFilter.styles';
import { CustomDatePicker } from '../../../DatePicker/CustomDatePicker';
import { TFilterSimple } from 'pages/my-deals/model/types';

import './index.css';
import { TOptions } from './types';
import { OfferStatusOption } from './OfferStatusOption';
import { DEAL_STATUS } from 'shared/constants/deal-status';

interface OfferFilterProps {
  options: TFilterSimple[];
  setFilter: (
    values: string | number | string[] | number[],
    type: string
  ) => void;
  setIsProcessingSelected: (isSelected: boolean) => void;
  setValueDate?: (values: string[] | string, type: string) => void;
  isMultiple?: boolean;
  nameDefaultProp?: DefaultTFuncReturn;
  isDisabled?: boolean;
  type: string;
  label?: DefaultTFuncReturn;
  search?: boolean;
  datePicker?: boolean;
  valueDate?: string[];
}

export const OfferFilter = (props: OfferFilterProps) => {
  const {
    datePicker,
    options,
    search,
    setFilter,
    nameDefaultProp,
    isDisabled,
    type,
    label,
    isMultiple = false,
    setValueDate,
    valueDate,
    setIsProcessingSelected,
  } = props;

  const { t } = useTranslation();

  const opts: TOptions[] = [];

  if (nameDefaultProp) {
    opts.push({
      id: 'all',
      value: nameDefaultProp,
      label: nameDefaultProp,
      checked: false,
    });
  }

  opts.push(
    ...options!.map((option) => ({
      id: option.name,
      value: t(option.label),
      label: t(option.label),
      checked: option.checked,
    }))
  );

  if (type === 'statusFilters') {
    opts.forEach((el) => {
      el.label = <OfferStatusOption label={el.label} name={el.id} />;
    });
  }

  const handleChange = (value: string, option: TOptions | TOptions[]) => {
    // if clear was clicked
    if (!value) {
      const emptyValue = nameDefaultProp || '';
      setFilter(isMultiple ? [emptyValue] : emptyValue, type);
      setIsProcessingSelected(false);
      return;
    }

    if (isMultiple && Array.isArray(option)) {
      // TODO: refactor т.к. all может быть не первым элементом
      if (!!option.length && !opts[0].checked && option[0].id === 'all') {
        const values = option.reduce<TOptions['id'][]>((acc, el, i) => {
          if (i !== 0) acc.push(el.id);
          return acc;
        }, []);

        if (option.find((option) => option.id === DEAL_STATUS.processing)) {
          setIsProcessingSelected(true);
        }
        setFilter(values as string[] | number[], type);
        return;
      }

      if (option.find((option) => option.id === DEAL_STATUS.processing)) {
        setIsProcessingSelected(true);
      } else {
        setIsProcessingSelected(false);
      }

      setFilter(
        opts.slice(-1)[0]?.value === nameDefaultProp
          ? [Infinity]
          : (option.map((opt) => opt.id) as string[] | number[]),
        type
      );
    } else if (!Array.isArray(option) && option) {
      setFilter(option.id, type);
      return;
    }
  };

  const selectedValue =
    opts.find((opt) => opt.checked)?.value ||
    (nameDefaultProp && !opts?.some((opt) => opt.checked)
      ? nameDefaultProp
      : undefined);
  const checkedOptions = opts.filter((opt) => opt.checked);
  const selectedValues = checkedOptions.length
    ? checkedOptions
    : [nameDefaultProp];

  if (!!datePicker && setValueDate) {
    return (
      <FilterContainerStyled>
        <LabelStyled>{label}</LabelStyled>
        <CustomDatePicker
          valueDate={valueDate}
          setValue={(el) => {
            setValueDate(el, type);
          }}
        />
      </FilterContainerStyled>
    );
  }

  return (
    <FilterContainerStyled>
      <LabelStyled>{label}</LabelStyled>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              borderRadius: 8,
              colorPrimaryHover: '#7c2f94',
              colorBgElevated: '#2B2B36',
              borderRadiusLG: 8,
              controlItemBgHover: '#474755',
              controlItemBgActive: '#0E0E13',
              paddingXXS: 0,
              colorTextPlaceholder: '#FBFBFF',
              colorTextDisabled: '#767687',
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlHeight: 40,
              colorBorder: '#474755',
              colorBgContainer: '#2B2B36',
              colorText: '#FBFBFF',
              colorErrorHover: '#F16063',
              paddingSM: 16,
            },
          },
        }}
      >
        <SelectStyled
          showSearch={search}
          value={
            isMultiple
              ? (selectedValues as SelectProps['value'])
              : selectedValue
          }
          style={{ width: '100%' }}
          allowClear
          onChange={handleChange}
          options={opts}
          mode={isMultiple ? 'multiple' : undefined}
          disabled={isDisabled}
          tagRender={TagRender}
          maxTagCount={'responsive'}
          dropdownRender={DropdownWrapper}
          optionLabelProp={'value'}
          menuItemSelectedIcon={null}
          clearIcon={<MinusCircleOutlined />}
          suffixIcon={<CaretDownOutlined />}
        />
      </ConfigProvider>
    </FilterContainerStyled>
  );
};

const TagRender = (props: CustomTagProps) => <>{props.value}</>;

const DropdownWrapper = (el: ReactNode) => (
  <SelectDropdownStyled>{el}</SelectDropdownStyled>
);
