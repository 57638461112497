import { MenuStyled, ModalStyled } from './ModalUserMenu.styles';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';

export const ModalUserMenu = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalUserMenu)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalUserMenu).options
  );

  const closeUserMenuModal = () => {
    closeModal(ModalKeys.ModalUserMenu);
  };

  const modalContainer =
    document.querySelector<HTMLElement>(`.modal-container[data-key=${ModalKeys.ModalUserMenu}]`) || document.body;

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeUserMenuModal}
      footer={null}
      getContainer={modalContainer}
    >
      <MenuStyled items={modalOptions.items} />
    </ModalStyled>
  );
};
