import { lazy, useEffect, useMemo, useRef, useState } from 'react';
import {
  EXCHANGE_MAP_URL,
  FINMS_URL,
} from '../../../../../packages/keycloak-client/constants';
import { MenuProps } from 'antd';
import i18n, { t } from 'i18next';
import { LinksDropdown } from './components/links-dropdown';
import { getFinmsMe } from '../../../../../shared/api/me';
import { useAuthState } from '../../../../../state/useAuthState';
import Icon, { StarOutlined } from '@ant-design/icons';
import UserBalance from './components/user-balance';
import { NotificationsAsideBell } from '../../../../../widgets/notificationAside/ui/NotificationsAsideBell/NotificationsAsideBell';
import { ChatAsideBell } from '../../../../../widgets/chatAside/ui/ChatAsideBell/ChatAsideBell';
import { SideBarLogo } from 'assets/img/logo/logo';
import { RenderModal } from 'shared/ui/RenderModal';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { MenuDropdown } from './components/menu-dropdown';
import { ChatAside } from 'widgets/chatAside/ui/ChatAside/ChatAside';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { useCentrifugeState } from '../../../../../state/centrifuge/useCentrifugeState';
import { ServerPublicationContext } from 'centrifuge';
import { useChatsState } from '../../../../../state/chats/useChatsState';
import { NotificationAside } from 'widgets/notificationAside/ui/NotificationAside/NotificationAside';
import {
  LabelContainerStyled,
  DescriptionStyled,
  HeaderWrapper,
  LinkStyled,
  HeaderButtons,
  FlexStyled,
  DividerStyled,
  DropdownContainer,
} from './CustomHeader.styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { useShallow } from 'zustand/react/shallow';
import { useHeaderState } from 'state/header/useHeaderState';
import { useChatListState } from 'pages/chat-list/state/useChatListState';

const UserDropdown = lazy(() =>
  import('./components/user-dropdown').then((module) => ({
    default: module.UserDropdown,
  }))
);

/*состояние открытия бокового дробдауна на декстопной версии зависит от isModalOpen(ModalKeys.ModalChatInformation)*/

const CustomHeader: React.FC = () => {
  const { setUserFinms, userFinmsApi, getUserProfileFromAPI } = useAuthState();
  const { isScreen720, isScreen1280 } = useWindowBreakpointState(
    useShallow((state) => ({
      isScreen720: state.computed.isScreen720,
      isScreen1280: state.computed.isScreen1280,
    }))
  );
  const activeDropdown = useHeaderState((state) => state.activeDropdown);
  const setActiveDropdown = useHeaderState((state) => state.setActiveDropdown);
  const isAdmin = !!useAuthState((state) => state.userFinmsApi?.company);

  const { openModal, closeModal } = useModalState();

  const setUnreadMessagesCount = useChatsState(
    (state) => state.setUnreadMessagesCount
  );

  const readChatMessages = useChatListState((state) => state.readChatMessages);
  const setUnreadMessages = useChatListState(
    (state) => state.setUnreadMessages
  );

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const createCentrifugeInstance = useCentrifugeState(
    (state) => state.createCentrifugeInstance
  );

  const connectToCentrifugeInstance = useCentrifugeState(
    (state) => state.connectToCentrifugeInstance
  );

  const disconnectCentrifugeInstance = useCentrifugeState(
    (state) => state.disconnectCentrifugeInstance
  );

  const items: MenuProps['items'] = useMemo(() => {
    const elems = [
      {
        key: 'Platform',
        label: (
          <>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Platform</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Кошелёк запятая сделки и настройки аккаунта')}
            </DescriptionStyled>
          </>
        ),
        style: {},
      },
      {
        key: 'Exchange_Map',
        label: (
          <a href={EXCHANGE_MAP_URL}>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Exchange_Map</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Сделки с гарантом запятая мониторинг и партнерство')}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      },
    ];
    if (userFinmsApi.company) {
      elems.push({
        key: 'Fin_MS',
        label: (
          <a href={FINMS_URL}>
            <LabelContainerStyled>
              <StarOutlined />
              <span>Fin_MS</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('Управление пунктами обмена')}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      });
    }
    return elems;
  }, [userFinmsApi, i18n.language]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getUserProfileFromAPI();
    getFinmsMe()
      .then((data) => {
        setUserFinms(data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setUnreadMessagesCount(isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    createCentrifugeInstance();
    return () => {
      disconnectCentrifugeInstance();
    };
  }, []);

  useEffect(() => {
    if (centrifugeInstance) {
      connectToCentrifugeInstance();
    }
  }, [centrifugeInstance]);

  useEffect(() => {
    const publicationHandler = (ctx: ServerPublicationContext) => {
      if (ctx?.data?.type === 'new_message') {
        setUnreadMessagesCount(isAdmin);

        if (ctx?.data?.message?.is_mine) {
          const { entity_type, entity_id, client_uid } =
            ctx?.data?.message?.chat;
          readChatMessages({
            entityType: entity_type,
            entityId: entity_id,
            clientUid: client_uid,
          });
        }
      }

      if (ctx?.data?.type === 'chat_metrics') {
        setUnreadMessagesCount(isAdmin);

        const { entity_type, entity_id, client_uid } = ctx?.data?.chat;
        const unreadMessagesCount = ctx?.data?.unread_message_count;

        setUnreadMessages(
          {
            entityType: entity_type,
            entityId: entity_id,
            clientUid: client_uid,
          },
          unreadMessagesCount
        );
      }
    };

    centrifugeInstance?.on('publication', publicationHandler);

    return () => {
      centrifugeInstance?.removeListener('publication', publicationHandler);
    };
  }, [centrifugeInstance, isAdmin]);

  const handleAsideBellClick = (type: ModalKeys) => {
    if (activeDropdown === type) {
      setActiveDropdown(null);
      closeModal(type);
    } else {
      if (activeDropdown) closeModal(activeDropdown);
      setActiveDropdown(type);
      openModal(type);
    }
  };

  useClickOutside({
    ref: dropdownRef,
    condition: !!activeDropdown,
    onClickOutside: () => setActiveDropdown(null),
  });

  return (
    <HeaderWrapper>
      {!isScreen720 && (
        <LinkStyled to={'/'}>
          <Icon component={SideBarLogo} />
        </LinkStyled>
      )}
      {/* <HeaderLinks>
          <a href={`${STOCK_EXCHANGE_URL}/cryptocurrency-exchange`}>
            <Typography.Text>{t('header.menu.exchange')}</Typography.Text>
          </a>
          <a href={`${FINMS_URL}`}>
            <Typography.Text>Fin_MS</Typography.Text>
          </a>
          <a href={`${EXCHANGE_MAP_URL}`}>
            <Typography.Text>EXCHANGE_MAP</Typography.Text>
          </a>
        </HeaderLinks> */}
      <HeaderButtons>
        {isScreen720 && <UserBalance />}
        <ChatAsideBell
          onClick={() => handleAsideBellClick(ModalKeys.ModalChatInformation)}
        />
        <NotificationsAsideBell
          onClick={() => handleAsideBellClick(ModalKeys.ModalNotifications)}
        />
        <UserDropdown />
        {isScreen1280 && (
          <FlexStyled>
            <DividerStyled />
            <LinksDropdown items={items} />
          </FlexStyled>
        )}
        {!isScreen1280 && <MenuDropdown />}
      </HeaderButtons>
      {isScreen720 && (
        <DropdownContainer
          ref={dropdownRef}
          isOpen={
            activeDropdown === ModalKeys.ModalChatInformation ||
            activeDropdown === ModalKeys.ModalNotifications
          }
        >
          {activeDropdown === ModalKeys.ModalChatInformation && <ChatAside />}
          {activeDropdown === ModalKeys.ModalNotifications && (
            <NotificationAside />
          )}
        </DropdownContainer>
      )}
      {!isScreen720 && activeDropdown && (
        <RenderModal currentModalKey={activeDropdown} useGlobalWrapper />
      )}
      <RenderModal
        currentModalKey={ModalKeys.ModalUserMenu}
        isShowHeader
        useGlobalWrapper
      />
    </HeaderWrapper>
  );
};

export { CustomHeader };
