import React from 'react';
import { ROUTES } from './router';
import { useRoutes } from 'react-router-dom';

function App() {
  const loader = document.querySelector('.ant-spin');

  if (loader) {
    loader.remove();
  }

  return <>{useRoutes(ROUTES)}</>;
}

export default App;
