import React, { Fragment } from 'react';
import { INotification } from '../../../model/types';
import { useNotificationParser } from '../../../helpers/notificationParser';
import {
  NotificationDotContainerItemStyled,
  NotificationDotItemStyled,
  NotificationIconBlock,
  NotificationListDotTooltipStyled,
  NotificationListItemContainerStyled,
  NotificationListItemDateStyled,
  NotificationListItemHeaderStyled,
  NotificationListItemTitleStyled,
  NotificationListTooltipContentStyled,
  NotificationText,
} from './NotificationAsideListItem.style';
import { UserOutlined } from '@ant-design/icons';
import { CardTravelIcon } from '../../../../../assets/img/icons/icons';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useNotificationAsideState } from '../../../model/useNotificationAsideState';

type NotificationAsideListProps = {
  notification: INotification;
};

const NotificationAsideListItem = ({
  notification,
}: NotificationAsideListProps) => {
  const setReadNotification = useNotificationAsideState(
    (state) => state.setReadNotification
  );
  const setNotifications = useNotificationAsideState(
    (state) => state.setNotifications
  );
  const activeTab = useNotificationAsideState((state) => state.activeTab);
  const setIsNotificationsLoading = useNotificationAsideState(
    (state) => state.setIsNotificationsLoading
  );

  const { texts, elems } = useNotificationParser(
    notification?.text,
    notification?.data,
    notification?.link ?? 'none',
    notification.topic_key,
    notification.type
  );

  const readNotificationHandler = () => {
    setIsNotificationsLoading(true);
    if (activeTab === 'all') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        },
      ]);
    }
    if (activeTab === 'my_deals' || activeTab === 'corporate') {
      setNotifications([
        {
          field: 'is_read',
          operator: '=',
          value: false,
        },
        {
          field: 'type',
          operator: '=',
          value: activeTab,
        },
      ]);
    }
    setReadNotification(notification.id, notification.type);
  };

  return (
    <NotificationListItemContainerStyled>
      <NotificationListItemHeaderStyled>
        <NotificationIconBlock>
          {notification.type === 'corporate' && <CardTravelIcon />}
          {notification.type === 'my_deals' && <UserOutlined />}
        </NotificationIconBlock>
        <NotificationListItemTitleStyled>
          {notification.topic}
        </NotificationListItemTitleStyled>
        <NotificationListDotTooltipStyled
          placement={'left'}
          title={
            <NotificationListTooltipContentStyled
              role={'button'}
              onClick={readNotificationHandler}
            >
              {t('Отметить как прочитанное')}
            </NotificationListTooltipContentStyled>
          }
        >
          <NotificationDotContainerItemStyled
            role={'button'}
            onClick={readNotificationHandler}
          >
            <NotificationDotItemStyled />
          </NotificationDotContainerItemStyled>
        </NotificationListDotTooltipStyled>
      </NotificationListItemHeaderStyled>
      {texts.map((text: string, index) => {
        return (
          <Fragment key={index}>
            <NotificationText>{text}</NotificationText>
            {elems ? elems[index] : ''}
          </Fragment>
        );
      })}
      <NotificationListItemDateStyled>
        {dayjs(new Date(notification.updated_at)).format('DD.MM.YYYY HH:mm')}
      </NotificationListItemDateStyled>
    </NotificationListItemContainerStyled>
  );
};

export default NotificationAsideListItem;
