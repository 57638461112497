// import { ScreenBreakpoints } from "./enums";

// type TBreakpointKeys = keyof typeof ScreenBreakpoints

export const BREAKPOINT = {
  SM: 360,
  MD: 720,
  LG: 992,
  XL: 1280,
  XXL: 1440,
} as const

export const MEDIA_BREAKPOINT = {
  SM: `(min-width: ${BREAKPOINT.SM}px)`,
  MD: `(min-width: ${BREAKPOINT.MD}px)`,
  LG: `(min-width: ${BREAKPOINT.LG}px)`,
  XL: `(min-width: ${BREAKPOINT.XL}px)`,
  XXL: `(min-width: ${BREAKPOINT.XXL}px)`,
} as const
