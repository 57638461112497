import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ChatAsideHeaderStyled = styled.div<{ $isCorporate?: boolean }>`
  background: #14141f;
  padding: 28px 32px 0;
  gap: 32px;
  flex-direction: row;
  min-height: 75px;
  height: 75px;
  @media (max-width: 719px) {
    padding: 12px 32px 0;
    gap: 0px;
    flex-direction: column;
    min-height: 85px;
    height: 85px;

    ${({ $isCorporate }) =>
      !$isCorporate &&
      css`
        padding-top: 16px;
        min-height: unset;
        height: 50px;
      `}
  }
  @media (max-width: 980px) {
    border-top-right-radius: 200px;
    border-top-left-radius: 200px;
  }
  padding-right: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const ChatAsideTitleStyled = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: rgba(251, 251, 255, 1);
  @media (min-width: 720px) {
    margin-top: -10px;
    font-size: 24px;
  }
`;

export const ChatAsideFooterStyled = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  background: inherit;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 719px) {
    height: 65px;
  }
  @media (max-width: 979px) {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

export const ChatAsideLinkStyled = styled(Link)`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;
