import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ChatListLeftStyled,
  ChatListRightStyled,
  ChatListStyled,
} from './assets/styles';
import ChatListHeader from './components/ChatListHeader/ChatListHeader';
import ChatListItems from './components/ChatListItems/ChatListItems';
import { TTabs, useChatListState } from './state/useChatListState';
import ChatListFilters from './components/ChatListFilters/ChatListFilters';
import { ListItemSkeleton } from 'shared/ui/list-item-skeleton';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { OfferChatWrapper } from '../../features/Chats/ui/OfferChatWrapper/OfferChatWrapper';
import { useChatsState } from '../../state/chats/useChatsState';
import { RenderModal } from 'shared/ui/RenderModal';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { useCentrifugeState } from 'state/centrifuge/useCentrifugeState';

const ChatList = () => {
  const [isDesktop, isScreen720] = useWindowBreakpointState((state) => [
    state.computed.isScreen1280,
    state.computed.isScreen720,
  ]);
  const openedEntity = useChatsState((state) => state.openedEntity);
  const typeTabs = useChatListState((state) => state.typeTabs);
  const setTypeTabs = useChatListState((state) => state.setTypeTabs);
  const openChatId = useChatListState((state) => state.openChatId);
  const isOpenChat = useChatListState((state) => state.isOpenChat);
  const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);
  const resetFields = useChatListState((state) => state.resetFields);
  const isLoadingSkeleton = useChatListState(
    (state) => state.isLoadingSkeleton
  );
  const resetState = useChatsState((state) => state.resetState);
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);
  const setOpenChatId = useChatListState((state) => state.setOpenChatId);

  const handleCloseChat = () => {
    resetState();
    setIsOpenChat(false);
    setOpenChatId(null);
    setOpenedEntity(null, null, null);
  };
  const navigate = useNavigate();

  const { openModal, closeModal } = useModalState();

  const partnersChatsList = useChatListState(
    (state) => state.partnersChatsList
  );
  const phexChatsList = useChatListState((state) => state.phexChatsList);

  const setIsLoadingSkeleton = useChatListState(
    (state) => state.setIsLoadingSkeleton
  );

  const handleTatyTabs = (value: TTabs) => {
    setIsLoadingSkeleton(true);
    setTypeTabs(value);
  };

  useEffect(() => {
    return () => resetFields();
  }, [resetFields]);

  useEffect(() => {
    if (openedEntity.id && openedEntity.entityType === 'offer' && openChatId) {
      setIsOpenChat(true);
      if (!isDesktop) {
        openModal(ModalKeys.ModalChatWindow, {
          isListPage: true,
        });
      }
    }
  }, [openedEntity, openedEntity.id, isOpenChat, openChatId]);

  useEffect(() => {
    if (isDesktop) {
      closeModal(ModalKeys.ModalChatWindow);
      setIsOpenChat(false);
    }
  }, [closeModal, isDesktop, setIsOpenChat]);

  return (
    <ChatListStyled $isOpen={isOpenChat}>
      <ChatListLeftStyled $isScreen720={isScreen720} $isOpen={isOpenChat}>
        <ChatListHeader onCrossClick={() => navigate(-1)} />
        <ChatListFilters typeTabs={typeTabs} setTypeTabs={handleTatyTabs} />
        {isLoadingSkeleton ? (
          <WrapSkeletonContainerStyled>
            {Array.from({ length: 6 }).map((el, i) => (
              <ListItemSkeletonStyled key={i} />
            ))}
          </WrapSkeletonContainerStyled>
        ) : (
          <ChatListItems
            typeTabs={typeTabs}
            items={typeTabs === 'personal' ? partnersChatsList : phexChatsList}
          />
        )}
      </ChatListLeftStyled>
      {isDesktop && isOpenChat && (
        <ChatListRightStyled $isOpen={isOpenChat}>
          <OfferChatWrapper closeChat={handleCloseChat} isModal={false} />
        </ChatListRightStyled>
      )}
      {!isDesktop && (
        <RenderModal currentModalKey={ModalKeys.ModalChatWindow} />
      )}
    </ChatListStyled>
  );
};

export default ChatList;

export const ListItemSkeletonStyled = styled(ListItemSkeleton)`
  height: 80px;
`;

export const WrapSkeletonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
