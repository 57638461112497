import React, { FC, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  SideBarLogo,
  SidebarLogoSmall,
} from '../../../../../assets/img/logo/logo';
import styled, { css } from 'styled-components';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useGetSideBarElements } from './useGetSideBarElements';
import { useChatListState } from '../../../../../pages/chat-list/state/useChatListState';
import { ROUTER_PATH } from '../../../../../router/path';
import { MenuItemGroupType } from 'antd/es/menu/hooks/useItems';
import Sider from 'antd/es/layout/Sider';
import { useWindowBreakpointState } from '../../../../../state/useWindowBreakpointState';
import { useShallow } from 'zustand/react/shallow';

const Sidebar: FC = () => {
  let loc = useLocation();
  const isOpenChat = useChatListState((state) => state.isOpenChat);
  const [collapsed, setCollapsed] = useState(false);

  const { isScreen720, isScreen1280, isScreen1440 } = useWindowBreakpointState(
    useShallow((state) => ({
      isScreen720: state.computed.isScreen720,
      isScreen1280: state.computed.isScreen1280,
      isScreen1440: state.computed.isScreen1440,
    }))
  );

  const { PROFILE_ELEMENTS } = useGetSideBarElements();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const activeKeys = () => {
    const index = loc.pathname.indexOf('/', 1);
    const result =
      index !== -1 ? loc.pathname.substring(0, index) : loc.pathname;

    const key: string[] = [];
    PROFILE_ELEMENTS.forEach((item: any) => {
      if (!!item?.children) {
        item.children.forEach((el: MenuItemGroupType) => {
          if ((el.key as string).includes(result)) {
            key.push(el.key as string);
          }
        });
      }
    });

    return [result, ...key];
  };

  useEffect(() => {
    const key: string[] = [];
    PROFILE_ELEMENTS.forEach((item: any) => {
      if (!!item?.children) {
        const pash = item.children.find((el: MenuItemGroupType) =>
          loc.pathname.includes(el.key as string)
        );

        if (pash) {
          key.push(item.key);
        }
      }
    });
    setOpenKeys(key);
  }, [PROFILE_ELEMENTS.length, loc.pathname]);

  const toggleCollapseIfIsChatOpened = () => {
    if (isOpenChat) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    setCollapsed(!isScreen1440);
  }, [isScreen1440]);

  useEffect(() => {
    toggleCollapseIfIsChatOpened();
  }, [isOpenChat]);

  if (!isScreen1280) {
    return null;
  }

  return (
    <SidebarWrapperStyled
      onClick={() => {
        setCollapsed(true);
      }}
    >
      <SidebarStyled
        width={240}
        collapsible
        collapsed={collapsed}
        onClick={(event) => {
          event.stopPropagation();
        }}
        defaultCollapsed={!collapsed}
        trigger={null}
        theme={'dark'}
        $isFixed={isScreen1440}
      >
        <SideBarContentContainer>
          <LogoContainer $collapsed={collapsed}>
            <Link to={ROUTER_PATH.AuthRoutes.MY_DEALS}>
              <LogoContainerIcon $collapsed={collapsed}>
                <Icon component={collapsed ? SidebarLogoSmall : SideBarLogo} />
              </LogoContainerIcon>
            </Link>
            <LogoContainerArrow
              $collapsed={collapsed}
              onClick={(event) => {
                event.stopPropagation();
                setCollapsed(!collapsed);
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </LogoContainerArrow>
          </LogoContainer>

          <CommonMenu>
            <MenuStyled
              mode="inline"
              theme="dark"
              selectedKeys={[loc.pathname, ...activeKeys(), ...openKeys]}
              items={PROFILE_ELEMENTS}
              onClick={(target) => {
                navigate(target.key);
              }}
              openKeys={openKeys}
              onOpenChange={setOpenKeys}
            />
          </CommonMenu>
        </SideBarContentContainer>
      </SidebarStyled>
    </SidebarWrapperStyled>
  );
};

export { Sidebar };

const SidebarStyled = styled(Sider)<{
  $isFixed: boolean;
}>`
  height: 100vh;
  position: sticky !important;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  ${({ $isFixed }) =>
    !$isFixed &&
    css`
      position: fixed !important;
      z-index: 1000;
    `}

  a > div {
    padding-left: 0;
  }
`;

const SideBarContentContainer = styled.div`
  padding-top: 22px;
  background: #14141f;
  border-right: 1px solid #413d5080;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LogoContainer = styled.div<{ $collapsed?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 22px;
    padding-left: 16px;
    justify-content: space-between;
    padding-right: 16px;
    ${(props) =>
      props.$collapsed &&
      css`
        justify-content: center;
        padding-right: 0;
      `}
}
`;

const LogoContainerIcon = styled.div<{ $collapsed?: boolean }>`
  display: flex;
  justify-content: center;

  ${(props) =>
    !props.$collapsed &&
    css`
      padding-left: 16px;
    `}
`;

const LogoContainerArrow = styled.div<{ $collapsed?: boolean }>`
  color: #fff;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.$collapsed &&
    css`
      padding-left: 10px;
    `}
`;

const CommonMenu = styled.div`
  height: 100%;
`;

const MenuStyled = styled(Menu)`
  .ant-menu-item {
    margin: 0;
    width: 100%;
    display: inline-block;
  }

  .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
    display: inline-block;
  }
`;

const SidebarWrapperStyled = styled.div<{ $isFixed?: boolean }>`
  ${({ $isFixed }) =>
    $isFixed
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 900;
        `
      : css`
          box-sizing: content-box;
        `}
`;
