import { useNavigate } from 'react-router-dom';
import { FunctionComponent, memo, useEffect } from 'react';

import { FINMS_URL } from 'packages/keycloak-client/constants';
import notClsx from 'shared/helpers/not-clsx';
import {
  TTabs,
  useChatListState,
} from 'pages/chat-list/state/useChatListState';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { IChat } from 'interfaces';
import ChatListItemMessageCounter from './components/MessageCounter/ChatListItemMessageCounter';
import ChatListItemOfferStatus from './components/OfferStatus/ChatListItemOfferStatus';
import ChatListItemOfferStep from './components/OfferStep/ChatListItemOfferStep';
import ChatListItemTypePair from './components/TypePair/ChatListItemTypePair';
import { ChatListItemCompany } from './ui/ChatListItemParticipant';

import { ChatListItemCard } from './ChatListItem.style';
import { ChatListItemClient } from './ui/ChatListItemParticipant';
import { TChatListTranslations } from './hooks/useChatListItemTranslations';
import { DesktopLayout, MobileLayout } from './ui/layouts';
import { useChatsState } from '../../../../../../state/chats/useChatsState';

type ChatListItemProps = {
  item: IChat;
  typeTabs: TTabs;
  translations: TChatListTranslations;
};

const ChatListItem: FunctionComponent<ChatListItemProps> = memo(
  ({ item, typeTabs, translations }) => {
    const navigate = useNavigate();

    const resetState = useChatsState((state) => state.resetState);
    const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);
    const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);
    const openChatId = useChatListState((state) => state.openChatId);
    const setOpenChatId = useChatListState((state) => state.setOpenChatId);

    const handleOpenChat = () => {
      if (typeTabs === 'personal') {
        if (item.entity_type === 'offer_deal') {
          setOpenChatId(item.id);
          navigate(`/deals/${item.offer_deal_id}`);
        }
        if (item.entity_type === 'offer') {
          resetState();
          setIsOpenChat(true);
          setOpenChatId(item.id);
          setOpenedEntity('offer', item.offer_id.toString(), item.client_uid);
        }
      } else if (typeTabs === 'corporate') {
        if (item.entity_type === 'offer_deal') {
          const dealTyprUrlPart = item.offer?.is_guarantee
            ? 'transactions'
            : 'no-guarantee-transactions';
          window.location.href = `${FINMS_URL}/${dealTyprUrlPart}/request/${item.offer_deal_id}`;
        }
        if (item.entity_type === 'offer') {
          resetState();
          setIsOpenChat(true);
          setOpenChatId(item.id);
          setOpenedEntity('offer', item.offer_id.toString(), item.client_uid);
        }
      }
    };

    const isDesktop = useWindowBreakpointState(
      (state) => state.computed.isScreen1280
    );

    const isBuyingCrypto = Boolean(item.offer?.is_buying_crypto);
    const isCorporate = typeTabs === 'corporate';
    const isBuyingOrder = isCorporate ? !isBuyingCrypto : isBuyingCrypto;
    const cardClassName = notClsx(
      isBuyingOrder ? 'is-buying-order' : undefined,
      isCorporate && 'is-corporate-layout'
    );

    const elements = {
      pair: (
        <ChatListItemTypePair
          currentTabKey={typeTabs}
          isRefereeInChat={item.has_referee}
          pair={`${item.offer?.crypto_payment_system_currency?.currency_key}-${item.offer?.cash_currency_code}`}
          isSellingCrypto={item.offer?.is_buying_crypto}
          translations={translations}
        />
      ),
      company: (
        <ChatListItemCompany
          companyName={item.offer?.exchange_point?.name}
          metrics={item?.offer?.exchange_point?.metrics}
        />
      ),
      client: isCorporate ? (
        <ChatListItemClient
          clientNickname={
            (item?.offer_deal?.nickname || item?.client_nickname) ?? ''
          }
        />
      ) : null,
      offerStatus: (
        <ChatListItemOfferStatus
          isActiveStatus={'active' === item.offer?.status}
          translations={translations}
        />
      ),
      offerStep: (
        <ChatListItemOfferStep
          step={item.offer_deal?.status}
          isGuarantee={!!item.offer_deal?.offer?.is_guarantee}
          isSelling={!!item.offer_deal?.offer?.is_buying_crypto}
          id={item?.offer_deal?.name}
          closedAt={item?.closed_at}
          createdAt={item?.created_at}
          dealId={item?.offer_deal_id}
          translations={translations}
        />
      ),
      counter: <ChatListItemMessageCounter count={item?.unread_count} />,
    };

    useEffect(() => {
      return () => {
        resetState();
      };
    }, []);

    return (
      <ChatListItemCard
        className={cardClassName}
        onClick={handleOpenChat}
        $isActive={item.id === openChatId}
      >
        {isDesktop ? (
          <DesktopLayout
            className={notClsx(isCorporate && 'is-corporate-layout')}
          >
            {elements.pair}
            {elements.company}
            {elements.client}
            {elements.offerStatus}
            {elements.offerStep}
            {elements.counter}
          </DesktopLayout>
        ) : (
          <MobileLayout elements={elements} />
        )}
      </ChatListItemCard>
    );
  }
);

export default ChatListItem;
