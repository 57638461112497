import {
  AlertStyled,
  AttachmentPopoverContentStyled,
  ChatFooterStyled,
  PopoverRowStyled,
  SendButtonStyled,
  TextAreaWrapper,
} from '../Chat.styles';
import { Form, Tooltip, UploadFile } from 'antd';
import Styles from '../Chat.module.css';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import FilePreview from '../../components/filePreview/FilePreview';
import CloseAlert from '../../assets/CloseAlert.svg';
import { Upload } from '../../components/upload';
import { Plus } from '../../assets/PlusIcon';
import { SendIcon } from '../../assets/SendIcon';
import { useChatsState } from '../../../../state/chats/useChatsState';
import { IChatFormValues } from '../../../../shared/api/ChatsAPI/ChatsTypes';

type ChatFooterProps = {
  dealID: string;
};

export const ChatFooter = ({ dealID }: ChatFooterProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [form] = Form.useForm<IChatFormValues>();
  const message = Form.useWatch('message', form);

  const isLoading = useChatsState((state) => state.isLoadingMessages);
  const sendMessage = useChatsState((state) => state.sendMessage);

  const postAttachmentStep1User = useChatsState(
    (state) => state.postAttachmentStep1User
  );
  const postAttachmentStep2 = useChatsState(
    (state) => state.postAttachmentStep2
  );
  const postAttachmentStep3User = useChatsState(
    (state) => state.postAttachmentStep3User
  );

  const [messageText, setMessageText] = useState<string>('');
  const [files, setFiles] = useState<UploadFile[] | null>(null);

  const [isErrorFileType, setIsErrorFileType] = useState(false);
  const [isErrorFileSize, setIsErrorFileSize] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const contentAttachPopover = (
    <AttachmentPopoverContentStyled>
      <PopoverRowStyled>{t('чат.Форматы аттачментов')}</PopoverRowStyled>
      <PopoverRowStyled>
        {t('чат.Максимальный размер аттачмента')}
      </PopoverRowStyled>
    </AttachmentPopoverContentStyled>
  );

  const isButtonDisabled = () => {
    return files === null && messageText?.trim()?.length === 0;
  };

  const validateFile = (fileList: any[]) => {
    if (fileList && fileList.length === 1) {
      const file = fileList[0];
      const isValidFileType = /\.jpg$|\.pdf$|\.png$/.test(file.name);
      if (!isValidFileType) {
        setIsErrorFileType(true);
        setFiles(null);
        return;
      }
      const isValidFileSize = file.size < 2000000;
      if (!isValidFileSize) {
        setIsErrorFileSize(true);
        setFiles(null);
        return;
      }
      setFiles(fileList);
      setIsErrorFileType(false);
      setIsErrorFileSize(false);
    } else {
      setIsErrorFileType(true);
      setIsErrorFileSize(true);
      setFiles(null);
    }
  };

  const handleDeleteFile = () => {
    setIsErrorFileType(false);
    setIsErrorFileSize(false);
    setFiles(null);
    setPreviewImage('');
  };

  const onPressEnterHandler = () => {
    if (files?.length) {
      handleOnSubmit().then(() => {
        inputRef.current?.focus();
      });
    }
    if (
      (files !== null && !messageText?.trim().length) ||
      !messageText?.trim().length
    ) {
      return;
    }
    handleOnSubmit().then(() => {
      inputRef.current?.focus();
    });
  };

  const handleOnSubmit = async () => {
    form.resetFields();
    handleDeleteFile();
    setMessageText('');
    try {
      const has_attachment = !!files?.length;
      const sendMessageResponse = await sendMessage(
        dealID,
        message,
        has_attachment,
        'user'
      );
      const messageId = sendMessageResponse.data.data.id;

      if (files) {
        const index = files[0].name.lastIndexOf('.');
        const fileName = files[0].name.substring(0, index);
        const fileExtension = files[0].type?.split('/')[1];

        // step 1 - create attachment
        const { lastUploadLink, lastCreatedAttachmentId } =
          await postAttachmentStep1User({
            message_id: messageId,
            file_name: fileName,
            file_extension: fileExtension,
          });

        // step 2 - upload attachment to minio
        await postAttachmentStep2({
          upload_link: lastUploadLink,
          files: files,
          type: files[0].type,
        });

        // step 3 - change is upload in attachment
        await postAttachmentStep3User(lastCreatedAttachmentId);
      }
    } catch (error) {
      console.error('Error sending message and uploading attachment:', error);
    }
  };

  return (
    <ChatFooterStyled>
      {isErrorFileSize && (
        <AlertStyled
          closable={true}
          message={t('чат.Файл превышает 2 MB')}
          type="error"
          onClose={handleDeleteFile}
          closeIcon={<img src={CloseAlert} alt={'close'} />}
        />
      )}
      {isErrorFileType && (
        <AlertStyled
          closable={true}
          message={t('чат.Вы можете загрузить только JPG, PNG, PDF')}
          type="error"
          onClose={handleDeleteFile}
          closeIcon={<img src={CloseAlert} alt={'close'} />}
        />
      )}
      <Form
        form={form}
        className={Styles.form}
        onFinish={handleOnSubmit}
        autoComplete="off"
      >
        <Tooltip title={contentAttachPopover} align={{ offset: [14, 10] }}>
          <Form.Item className={Styles.form__item}>
            <Upload
              icon={
                <Plus
                  size={16}
                  color={isButtonDisabled() ? '#767687' : '#FBFBFF'}
                />
              }
              onModal={false}
              onChange={({ fileList }) => {
                validateFile(fileList);
              }}
              disabled={isLoading}
            />
          </Form.Item>
        </Tooltip>
        <div className={Styles.form__item_input_container}>
          <FilePreview
            files={files}
            handleDeleteFile={handleDeleteFile}
            setPreviewImage={setPreviewImage}
            previewImage={previewImage}
          />
          <Form.Item
            name={'message'}
            className={`${Styles.form__item}`}
            normalize={(value, prevValue) => {
              return value.length > 250 ? value.slice(0, 250) : value;
            }}
          >
            <TextAreaWrapper
              ref={inputRef}
              onPressEnter={onPressEnterHandler}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setMessageText(e.target.value);
              }}
              placeholder={String(t('чат.Введите сообщение'))}
              autoSize={{ maxRows: 6 }}
              disabled={isLoading}
              showCount={{
                formatter: (info) => {
                  return `${info.count} / 250`;
                },
              }}
              $messageIsEmpty={!messageText?.length}
            />
          </Form.Item>
        </div>
        <Form.Item className={Styles.form__item}>
          <SendButtonStyled
            type="primary"
            htmlType="submit"
            icon={
              <SendIcon color={isButtonDisabled() ? '#767687' : '#FBFBFF'} />
            }
            disabled={isButtonDisabled()}
          />
        </Form.Item>
      </Form>
    </ChatFooterStyled>
  );
};
