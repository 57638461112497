import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const ModalStyled = styled(Modal)<{ $isScreen720: boolean }>`
  .ant-modal-content {
    background: ${({ $isScreen720 }) =>
      $isScreen720 ? '#14141F !important' : ''};
    padding: ${({ $isScreen720 }) => ($isScreen720 ? '16px' : '40px')};
    height: ${({ $isScreen720 }) => ($isScreen720 ? '100dvh ' : 'auto')};
  }

  .ant-modal-close {
    width: ${({ $isScreen720 }) =>
      $isScreen720 ? '32px !important' : '24px !important'};
    height: ${({ $isScreen720 }) =>
      $isScreen720 ? '32px !important' : '24px !important'};
  }

  .ant-modal-body {
    height: 100%;
  }

  .ant-modal-close:hover {
    border: 1px solid white;
  }
  .ant-modal-close {
    margin-top: ${({ $isScreen720 }) => ($isScreen720 ? '-32px' : '-2px')};
    margin-right: ${({ $isScreen720 }) => ($isScreen720 ? '-28px' : '-8px')};

    svg {
      height: 13px;
      width: 13px;
    }

    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
`;

export const ModalDescriptionStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(222, 222, 227, 1);
`;

export const ModalTextGroupStyled = styled.div<{ $isScreen720: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $isScreen720 }) => ($isScreen720 ? '16px' : '32px')};
`;

export const ModalTitleStyled = styled.div<{ $isScreen720: boolean }>`
  font-size: ${({ $isScreen720 }) => ($isScreen720 ? '22px' : '24px')};
  line-height: ${({ $isScreen720 }) => ($isScreen720 ? '27px' : '29px')};
  font-weight: 700;
  color: rgba(251, 251, 255, 1);
`;

export const BackButtonStyled = styled(Button)<{ $isScreen720: boolean }>`
  width: ${({ $isScreen720 }) => ($isScreen720 ? '100%' : 'auto')};
  min-width: ${({ $isScreen720 }) => ($isScreen720 ? 'auto' : '180px')};
  border: 1px solid rgba(99, 32, 229, 1);
  background-color: transparent;

  &&:hover {
    border: 1px solid rgba(99, 32, 229, 1);
    background: linear-gradient(#2b2b36, #2b2b36) padding-box padding-box,
      linear-gradient(52deg, #7d50ff 0%, #7785ff 100%) border-box border-box;
  }
`;

export const AcceptButtonStyled = styled(Button)`
  width: 100%;
`;

export const ModalRowStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const ModalBodyStyled = styled.div<{ $isScreen720: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: ${({ $isScreen720 }) => ($isScreen720 ? '16px' : '32px')};
`;

export const ButtonGroupStyled = styled.div<{ $isScreen720: boolean }>`
  display: flex;
  gap: ${({ $isScreen720 }) => ($isScreen720 ? '8px' : '16px')};
  justify-content: flex-end;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
