import {
  AvatarStyled,
  ChatHeaderMainInfoStyled,
  ChatHeaderStyled,
  RefereeIconStyled,
} from '../Chat.styles';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Tooltip } from 'antd';
import {
  CardTravelIcon,
  RefereeOutlinedCircle,
} from '../../../../assets/img/icons/icons';
import { RatingTooltip } from '../../../../shared/ui/tooltips/RatingTooltip';
import { IExchangePointMetrics } from '../../../../interfaces';

type ChatHeaderProps = {
  isDealOnArgument: boolean;
  exchangePointName: string;
  metrics: IExchangePointMetrics | undefined;
};

export const ChatHeader = ({
  isDealOnArgument,
  exchangePointName,
  metrics,
}: ChatHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ChatHeaderStyled>
      <ChatHeaderMainInfoStyled>
        <AvatarStyled>
          <CardTravelIcon />
        </AvatarStyled>
        {isDealOnArgument && (
          <Tooltip
            trigger={'hover'}
            title={t('чат.Рефери тултип')}
            placement={'bottomLeft'}
            align={{ offset: [-11, 1] }}
          >
            <RefereeIconStyled>
              <RefereeOutlinedCircle />
            </RefereeIconStyled>
          </Tooltip>
        )}
        <RatingTooltip metrics={metrics}>
          <div>{exchangePointName}</div>
        </RatingTooltip>
      </ChatHeaderMainInfoStyled>
      {/*<ChatHeaderSubInfoStyled>{`${t('чат.Зарегистрирован')}: ${dayjs(*/}
      {/*  clientRegisteredDate*/}
      {/*).format('DD.MM.YY')}`}</ChatHeaderSubInfoStyled>*/}
    </ChatHeaderStyled>
  );
};
