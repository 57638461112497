import styled from 'styled-components';

export const ContainerStyled = styled.div`
  border: none;
  background: none;
  padding-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  svg {
    color: white;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 5px;
  }
`;
