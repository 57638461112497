import { styled } from 'styled-components';

import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';

export const ChatListStyled = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  transition: margin-right 0.5s ease;
  width: 100%;
`;

export const ChatListLeftStyled = styled.div<{
  $isOpen: boolean;
  $isScreen720: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $isOpen, $isScreen720 }) =>
    $isOpen && $isScreen720 ? '75%' : '100%'};
  padding: 16px;
  transition: width 0.5s ease;
  flex: 1.5;
  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 24px;
  }
`;

export const ChatListRightStyled = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  height: 100%;
  max-height: calc(100vh - 72px);
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.5s ease;
  max-width: 400px;
  min-width: 400px;
`;
