import { css, keyframes, styled } from 'styled-components';
import { DELAY_ANIMATION } from './constants/constants';

export const BurgerButtonStyled = styled.button`
  padding: 11px;
  border: 1px solid #ffffff26;
  line-height: 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
`;

const MenuContainer = styled.div`
  &&& {
    .ant-menu {
      min-width: 335px;
    }
    .ant-menu-submenu-title,
    .ant-menu-item {
      height: 48px;
    }
    .ant-menu-item:has([data-subtext]) {
      height: 65px;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
    padding-left: 24px !important;
  }

  .ant-menu-item-selected {
    background: transparent;
  }
  .ant-menu-item-only-child > .ant-menu-title-content {
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-menu-title-content {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
  }
  svg {
    width: 16px;
    color: #767687;
  }
`;

export const MenuModalContainer = styled(MenuContainer)`
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  .ant-menu-submenu-arrow {
    display: none;
  }

  &&&& {
    .ant-menu-item:has([data-is-current-page]),
    .ant-menu-submenu-title:has([data-is-current-page]) {
      position: relative;
      left: 1px;
      max-width: calc(100% - 1px);
    }
    .ant-menu-item:has([data-is-current-page]),
    .ant-menu-submenu-title:has([data-is-current-page]) {
      border-left: 3px solid transparent;
      background: linear-gradient(0deg, #14141f, #14141f) padding-box,
        linear-gradient(0deg, #6e27f8 0%, #20d9c0 100%) border-box;
    }
  }
`;

const fadeIn = keyframes`
  0% {
    max-height: 0px;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100%{
    opacity: 1;
    max-height: 1000px;
  }
`;

const fadeOut = keyframes`
  0% {
    max-height: 1000px;
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100%{
    opacity: 0;
    max-height: 0px;
  }
`;

export const MenuBurgerContainer = styled(MenuContainer)<{ $isOpen: boolean }>`
  position: absolute;
  top: 68px;
  right: 0px;
  background: #2b2b36;
  z-index: 10;
  width: 335px;
  overflow: hidden;

  .ant-menu-item:has([data-is-current-page]),
  .ant-menu-item:has([data-is-current-service]),
  .ant-menu-submenu-title:has([data-is-current-page]) {
    border-left: 3px solid transparent;
    background: linear-gradient(0deg, #14141f, #14141f) padding-box,
      linear-gradient(0deg, #6e27f8 0%, #20d9c0 100%) border-box;
  }

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeIn} ${DELAY_ANIMATION}s ease forwards;
        `
      : css`
          animation: ${fadeOut} ${DELAY_ANIMATION}s ease forwards;
        `}
`;

export const MenuDropdownContainer = styled.div`
  position: relative;
`;

export const ItemWithSubtextStyled = styled.div`
  display: grid;
`;

export const ItemSubtextStyled = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: #ffffff7f;
`;

export const ItemMainTextStlyed = styled.span`
  font-size: 14px;
  line-height: 20px;
`;
