import { styled } from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  &.ant-modal {
    max-width: 100vw;

    .ant-modal-content {
      background: #14141f !important;
      padding: 0;
      margin: 0;
      border-radius: 0px;

      .ant-modal-close {
        width: 40px !important;
        height: 40px !important;
        color: white;
        margin-right: -15px;
        margin-top: -32px;
      }

      .ant-modal-body {
        min-height: 100vh;
        border-radius: 0px;
      }
    }
  }
`;
