import { useEffect } from 'react';
import { IParticipant } from '../../../../shared/api/ChatsAPI/ChatsTypes';
import { useChatsState } from '../../../../state/chats/useChatsState';
import { OfferChatHeader } from './OfferChatHeader';
import { OfferChatBody } from './OfferChatBody';
import { OfferChatContainerStyled } from './OfferChatStyles';
import { OfferChatFooter } from './OfferChatFooter';
import { useChatListState } from '../../../../pages/chat-list/state/useChatListState';

type OfferChatWrapperProps = {
  isModal: boolean;
  closeChat?: () => void;
};

export const OfferChatWrapper = ({
  isModal,
  closeChat,
}: OfferChatWrapperProps) => {
  const typeTabs = useChatListState((state) => state.typeTabs);

  const openedEntity = useChatsState((state) => state.openedEntity);
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);

  const currentChatInfo = useChatsState((state) => state.currentChatInfo);
  const getOfferChatInfo = useChatsState((state) => state.getOfferChatInfo);
  const resetState = useChatsState((state) => state.resetState);

  const participantExchangePoint = currentChatInfo?.participants?.find(
    (user: IParticipant) => {
      return user.type === 'exchange_point';
    }
  );

  const participantClient = currentChatInfo?.participants?.find(
    (user: IParticipant) => {
      return user.type === 'user';
    }
  );

  useEffect(() => {
    return () => {
      setOpenedEntity(null, null, null);
    };
  }, []);

  useEffect(() => {
    if (typeTabs === 'personal' && openedEntity.id && openedEntity.clientUid) {
      getOfferChatInfo(openedEntity.id, openedEntity.clientUid, 'user');
    }
    if (typeTabs === 'corporate' && openedEntity.id && openedEntity.clientUid) {
      getOfferChatInfo(
        openedEntity.id,
        openedEntity.clientUid,
        'exchange_point'
      );
    }
    return () => {
      resetState();
    };
  }, [openedEntity, openedEntity.id, typeTabs]);

  return (
    <OfferChatContainerStyled className={isModal ? 'is-modal' : ''}>
      {openedEntity.id && currentChatInfo.entity_id && (
        <>
          <OfferChatHeader
            exchangePointName={participantExchangePoint?.caption}
            clientName={participantClient?.caption}
            chatCreatedDate={currentChatInfo.created_at}
            offerID={openedEntity.id.toString()}
            role={typeTabs === 'personal' ? 'user' : 'admin'}
            isModal={isModal}
            closeChat={closeChat}
          />
          <OfferChatBody
            clientUid={currentChatInfo.client_uid}
            entityID={openedEntity.id.toString()}
            role={typeTabs === 'personal' ? 'user' : 'admin'}
          />
          <OfferChatFooter
            clientUid={currentChatInfo.client_uid}
            offerID={openedEntity.id.toString()}
            role={typeTabs === 'personal' ? 'user' : 'admin'}
          />
        </>
      )}
    </OfferChatContainerStyled>
  );
};
