import { useEffect, useMemo, useState } from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { BadgeStyled, TabsStyled } from './NotificationAsideTabs.style';
import { useNotificationAsideState } from '../../../model/useNotificationAsideState';
import { useAuthState } from '../../../../../state/useAuthState';
import { TNotificationsTabs } from '../../../model/types';

const config: ThemeConfig = {
  token: {
    colorPrimary: '#1890ff',
  },
};

export const NotificationAsideTabs = () => {
  const { t, i18n } = useTranslation();

  const userFinmsApi = useAuthState((state) => state.userFinmsApi);

  const totalCount = useNotificationAsideState(
    (state) => state.totalNotificationsCount
  );
  const corporateCount = useNotificationAsideState(
    (state) => state.totalNotificationsCorporateCount
  );
  const myDealsCount = useNotificationAsideState(
    (state) => state.totalNotificationsMyDealsCount
  );

  const isNotificationsLoading = useNotificationAsideState(
    (state) => state.isNotificationsLoading
  );
  const setIsNotificationsLoading = useNotificationAsideState(
    (state) => state.setIsNotificationsLoading
  );

  const activeTab = useNotificationAsideState((state) => state.activeTab);
  const setActiveTab = useNotificationAsideState((state) => state.setActiveTab);

  const [hideTabs, setHideTabs] = useState(true);

  const onTabClickHandler = (value: string) => {
    if (value === activeTab) return;
    setIsNotificationsLoading(true);
    setActiveTab(value as TNotificationsTabs);
  };

  const tabsWithCompany = useMemo(
    () => [
      {
        key: 'all',
        label: (
          <BadgeStyled
            count={totalCount ?? 0}
            size={'default'}
            color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
            showZero={true}
            offset={[15, 7]}
          >
            <div>{t('Все')}</div>
          </BadgeStyled>
        ),
      },
      {
        key: 'my_deals',
        label: (
          <BadgeStyled
            count={myDealsCount ?? 0}
            size={'default'}
            color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
            showZero={true}
            offset={[15, 7]}
          >
            <div>{t('Сделки')}</div>
          </BadgeStyled>
        ),
      },
      {
        key: 'corporate',
        label: (
          <BadgeStyled
            count={corporateCount ?? 0}
            size={'default'}
            color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
            showZero={true}
            offset={[15, 7]}
          >
            <div>{t('Корп точка уведомления')}</div>
          </BadgeStyled>
        ),
      },
    ],
    [i18n.language, isNotificationsLoading, activeTab, totalCount]
  );

  useEffect(() => {
    setHideTabs(totalCount === 0);
  }, [totalCount]);

  if (hideTabs) {
    return null;
  }

  return (
    <ConfigProvider theme={config}>
      <TabsStyled
        defaultActiveKey="1"
        items={
          !!userFinmsApi.companyId
            ? tabsWithCompany
            : tabsWithCompany.filter((el) => el.key !== 'corporate')
        }
        onTabClick={onTabClickHandler}
      />
    </ConfigProvider>
  );
};
