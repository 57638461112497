import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { notification } from 'antd';
import { CloseIcon, InfoCircle } from '../../../../../assets/img/icons/icons';
import { axios } from '../../../../exios';
import { EXCHANGE_MAP_API_URL } from '../../../../../packages/keycloak-client/constants';
import { Input } from 'antd';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalStyled,
  ModalTextGroupStyled,
  ModalTitleStyled,
} from './ModalOpenDispute.styles';
import { useNavigate } from 'react-router-dom';
import { useResize } from 'hooks/useResize';

const { TextArea } = Input;

export const ModalOpenDispute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isScreen720 } = useResize();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalOpenDispute)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalOpenDispute).options
  );

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalOpenDispute);
  };

  const [value, setValue] = useState('');

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const handleNavigateToDeal = () => {
    closeModal(ModalKeys.ModalOpenDispute);
    navigate(`/deals/${modalOptions.id}`);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      const response = await axios.put(
        `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${modalOptions.id}/argue`,
        {
          reason: value,
        }
      );

      if (modalOptions.updateCallback) {
        modalOptions.updateCallback(
          response.data.data.id,
          response.data.data.status
        );
      }

      notification.success({
        message: t('Спор открыт'),
        icon: <InfoCircle />,
        closeIcon: <CloseIcon />,
        description: (
          <div>
            {t('Спор по сделке')}{' '}
            <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
              {modalOptions.name}
            </ButtonAsLinkStyled>{' '}
            {t(
              `на ${
                !modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } открыт точка`,
              { currency: modalOptions.currency }
            )}
          </div>
        ),
        style: {
          width: '360px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalOpenDispute);
      modalOptions.refetch && modalOptions.refetch();
    }
  };

  const modalContainer =
    document.getElementById('modal-container') || document.body;

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={560}
      closable={true}
      $isScreen720={!isScreen720}
      getContainer={modalContainer}
      centered
    >
      <ModalBodyStyled>
        <ModalTextGroupStyled>
          <ModalTitleStyled $isScreen720={!isScreen720}>
            {t('Открыть спор вопрос')}
          </ModalTitleStyled>
          <ModalDescriptionStyled $isScreen720={!isScreen720}>
            <div>
              {t(
                'После открытия спора статус сделки изменится запятая и Вы не сможете восстановить её точка'
              )}
            </div>
            <div>
              {t('Вы уверены запятая что хотите открыть спор по сделке')}
              <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
                {modalOptions.name}
              </ButtonAsLinkStyled>
              {t(
                `${
                  !modalOptions.isBuyingCrypto ? 'на покупку' : 'на продажу'
                } валюты вопрос`,
                {
                  currency: modalOptions.currency,
                }
              )}
            </div>
          </ModalDescriptionStyled>
          <CommentBlockStyled $isScreen720={!isScreen720}>
            <CommentTitleStyled>
              {t('Укажите причину спора')}
            </CommentTitleStyled>
            <TextArea
              showCount
              maxLength={5000}
              onChange={onChangeHandler}
              placeholder={String(t('Введите комментарий'))}
              value={value}
            />
          </CommentBlockStyled>
        </ModalTextGroupStyled>

        <ButtonGroupStyled $isScreen720={!isScreen720}>
          <BackButtonStyled
            onClick={handleCloseModal}
            $isScreen720={!isScreen720}
          >
            {t('Назад')}
          </BackButtonStyled>
          <AcceptButtonStyled
            onClick={handleChangeOfferRequestStatus}
            type="primary"
          >
            {t('Открыть спор')}
          </AcceptButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
