import { useEffect } from 'react';
import { IParticipant } from '../../../../shared/api/ChatsAPI/ChatsTypes';
import { useChatsState } from '../../../../state/chats/useChatsState';
import { TDeal } from '../../../../interfaces';
import { ChatContainerStyled } from '../Chat.styles';
import { ChatHeader } from './ChatHeader';
import { ChatBody } from './ChatBody';
import { ChatFooter } from './ChatFooter';

type OfferRequestChatWrapperProps = {
  dealInfo: TDeal;
  isModal?: boolean;
};

export const RequestChatWrapper = ({
  dealInfo,
  isModal,
}: OfferRequestChatWrapperProps) => {
  const openedEntity = useChatsState((state) => state.openedEntity);
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);

  const currentChatInfo = useChatsState((state) => state.currentChatInfo);
  const getChatInfo = useChatsState((state) => state.getChatInfo);

  const participantExchangePoint = currentChatInfo?.participants?.find(
    (user: IParticipant) => {
      return user.type === 'exchange_point';
    }
  );

  useEffect(() => {
    setOpenedEntity('offer_deal', dealInfo.id.toString(), dealInfo.author_uid);
    return () => {
      setOpenedEntity(null, null, null);
    };
  }, [dealInfo.id]);

  useEffect(() => {
    getChatInfo(dealInfo.id.toString(), 'user', dealInfo.author_uid);
  }, [openedEntity.id, dealInfo.author_uid]);

  return (
    <ChatContainerStyled className={isModal ? 'is-modal' : ''}>
      {openedEntity.id && (
        <>
          <ChatHeader
            isDealOnArgument={dealInfo.status === 'on_argument'}
            exchangePointName={
              participantExchangePoint?.caption ??
              dealInfo?.offer?.exchange_point?.name ??
              ''
            }
            metrics={dealInfo?.offer?.exchange_point?.metrics}
          />
          <ChatBody dealID={openedEntity.id.toString()} />
          <ChatFooter dealID={openedEntity.id.toString()} />
        </>
      )}
    </ChatContainerStyled>
  );
};
