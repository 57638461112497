import { useEffect } from 'react';
import { getSubmenuOfKey } from '../helpers/getSubmenuOfKey';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useMenuDropdownState } from '../model/useMenuDropdownState';
import { useLocation } from 'react-router-dom';

export const useSelectSubmenuOnRedirect = (items: ItemType[]) => {
  const { setOpenSubtitles } = useMenuDropdownState();
  const location = useLocation();
  useEffect(() => {
    const submenu = getSubmenuOfKey(items, location.pathname);
    if (submenu) {
      setOpenSubtitles([submenu]);
    }
  }, [location.pathname]);
};
