import { IChat } from 'interfaces';
import { EntityType } from 'shared/api/ChatsAPI/ChatsTypes';

interface IChats {
  personalChats: IChat[];
  corporateChats: IChat[];
}

export interface SearchChatOptions {
  entityType: EntityType;
  entityId: number;
  clientUid: string;
}

export const findChatByEntity = (
  chats: IChats,
  searchChatOptions: SearchChatOptions
) => {
  const { entityType, entityId, clientUid } = searchChatOptions;

  const searchFunction =
    entityType === 'offer'
      ? (chat: IChat) =>
          chat.client_uid === clientUid && chat.offer_id === entityId
      : (chat: IChat) =>
          chat.client_uid === clientUid && chat.offer_deal_id === entityId;

  const partnerChat = chats.personalChats.find(searchFunction);
  if (partnerChat) {
    return partnerChat;
  }

  const phexChat = chats.corporateChats.find(searchFunction);
  if (phexChat) {
    return phexChat;
  }
};
