import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';

export const HEADER_COMPONENTS = {
  BackButton: styled(Button)`
    display: flex;
    align-items: center;
    height: 19px;
    border: 1px solid transparent;
    padding: 0;

    &:hover,
    &:active {
      //border-bottom: 1px solid #d458ff;
    }
  `,
};

export const LinkStyled = styled(Link)`
  &&& {
    line-height: 0;
  }
`;

export const DropdownContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 71px;
  right: 0;
  z-index: 3;
  background: #14141f;
  padding: 0 1px 1px 1px;
  height: calc(100vh - 72px);
  width: 480px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(65, 61, 80, 0.5);

  transform: translateY(${(props) => (props.isOpen ? '0' : '-20px')});
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  transition: all 0.2s ease;
`;

export const BurgerButton = styled.button`
  padding: 11px;
  border: 1px solid #ffffff26;
  line-height: 0;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
`;

export const HeaderWrapper = styled(Header)`
  &&& {
    background-color: #14141f;
    position: sticky;
    padding-inline: 16px;
    height: 72px;
    top: 0;
    z-index: 990;
  }

  border-bottom: 1px solid;
  line-height: 0;
  border-color: rgba(65, 61, 80, 0.5);
  display: flex;
  justify-content: end;
  align-items: center;

  @media screen and (width < 1280px) {
    justify-content: space-between;
  }
`;

export const HeaderButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
`;
export const LabelContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;
export const DescriptionStyled = styled.p`
  color: #ffffffcc;
  font-size: 12px;
  margin: 0;
`;
export const DividerStyled = styled.div`
  height: 24px;
  width: 1px;
  background: #ffffff26;
  display: flex;
`;
export const FlexStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
