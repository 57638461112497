export const DEAL_STATUS = {
  pending: 'pending',
  accepted: 'accepted',
  warrantyCollateral: 'warranty_collateral',
  waitingForFiatPayment: 'waiting_for_fiat_payment',
  waitingForCryptoDelivery: 'waiting_for_crypto_delivery',
  waitingForFiatDelivery: 'waiting_for_fiat_delivery',
  waitingForFiatReceiving: 'waiting_for_fiat_receiving',
  processing: 'processing',
  processingHold: 'processing_hold',
  processingHoldFiatPayment: 'processing_hold_fiat_payment',
  processingCancellation: 'processing_cancellation',
  processingExpiration: 'processing_expiration',
  processingCompletion: 'processing_completion',
  processingFiatDelivery: 'processing_fiat_delivery',
  ready: 'ready',
  completed: 'completed',
  expired: 'expired',
  onArgument: 'on_argument',
  complaint: 'complaint',
  cancelled: 'cancelled',
} as const;

export const DEAL_STATUSES_CASH = [
  DEAL_STATUS.pending,
  DEAL_STATUS.accepted,
  DEAL_STATUS.ready,
  DEAL_STATUS.completed,
];

export const DEAL_STATUSES_DEFAULT = [
  DEAL_STATUS.pending,
  DEAL_STATUS.accepted,
  DEAL_STATUS.waitingForFiatPayment,
  DEAL_STATUS.ready,
  DEAL_STATUS.completed,
];
