import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRegisterState } from '../../state/useRegisterState';
import {
  ErrorCodeStyled,
  FormBorderStyled,
  FormButtonStyled,
  FormFooterStyled,
  FormSubTitleStyled,
  FormTitleStyled,
} from '../../common/styles';
import CustomCheckbox from '../../components/ui/custom-checkbox/CustomCheckbox';
import styled, { css } from 'styled-components';
import { ROUTER_PATH } from '../../router/path';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import React, { ChangeEvent, Suspense, useEffect, useState } from 'react';
import {
  nicknameValidator,
  passwordValidator,
} from '../../shared/helpers/validators';
import { useTranslation } from 'react-i18next';
import { useResize } from '../../hooks/useResize';
import { TG_SUPPORT } from '../../shared/constants';
import { InputWithTooltip } from 'components/ui/InputWithTooltip';
import { SupportEmail } from 'shared/ui/SupportEmail';
import { hideEmail } from '../../shared/helpers/hideEmail';

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const register = useRegisterState((state) => state.register);
  const setNickname = useRegisterState((state) => state.setNickname);
  const setEmail = useRegisterState((state) => state.setEmail);
  const setPassword = useRegisterState((state) => state.setPassword);
  const setPasswordRepeat = useRegisterState(
    (state) => state.setPasswordRepeat
  );
  const setIsAgree = useRegisterState((state) => state.setIsAgree);
  const step = useRegisterState((state) => state.step);
  const setStep = useRegisterState((state) => state.setStep);
  const email = useRegisterState((state) => state.email);
  const [validState, setValidState] = React.useState<string[]>([]);
  const [nicknameValidState, setNicknameValidState] = useState<string[]>([]);

  const { isScreen767 } = useResize();
  const [showEmailError, setShowEmailError] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!!urlParams.get('redirect_url')) {
      localStorage.setItem(
        'redirectUrl',
        urlParams.get('redirect_url') as string
      );
    }
  }, [urlParams.get('redirect_url')]);

  const navToConfidential = () =>
    navigate(ROUTER_PATH.FormsPages.PRIVACY_POLICY);

  const navToTerms = () => navigate(ROUTER_PATH.FormsPages.TERMS_OF_USE);

  const navToLogin = () => {
    setStep(1);
    navigate(ROUTER_PATH.FormsPages.LOGIN);
    localStorage.removeItem('registerStep');
  };

  const handleNickname = (e: ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setShowEmailError(false);
    setEmail(e.target.value);
  };

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordRepeat = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(e.target.value);
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then(() => {
        register({ isChangeStep: true }).catch((err) => {
          if (
            err.message.includes('The email has already been taken.') ||
            err.errors['email']
          ) {
            // form.setFields([{
            //   name: 'email',
            //   errors: [t('authorizationForms.accountSameEmailAlreadyExists')],
            // }]);
            setShowEmailError(true);
          }
          if (
            err.message.includes('The requested nickname is busy.') ||
            err.errors['nickname']
          ) {
            form.setFields([
              {
                name: 'nickname',
                errors: [t('Никнейм уже занят')],
              },
            ]);
          }
        });
      })
      .catch((err) => {
        if (
          err.errorFields.some((error: any) =>
            error.errors.includes(t('authorizationForms.passwordsDoesntMatch'))
          )
        ) {
          form.setFields([
            {
              name: 'password',
              errors: [t('authorizationForms.passwordsDoesntMatch')],
            },
          ]);
        }
      });
  };
  const checkboxLabel = () => {
    return (
      <>
        {t('authorizationForms.agreement')}
        <TextWithUnderLineStyled onClick={navToConfidential}>
          {' '}
          {t('authorizationForms.privacyPolicy')}
        </TextWithUnderLineStyled>{' '}
        {t('authorizationForms.and')}{' '}
        <TextWithUnderLineStyled onClick={navToTerms}>
          {t('authorizationForms.termsUse')}
        </TextWithUnderLineStyled>
      </>
    );
  };

  useEffect(() => {
    const registerStep = localStorage.getItem('registerStep');
    if (!!registerStep?.length) {
      setStep(Number(registerStep));
    }
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={'Loading...'}>
        {step === 1 && (
          <FormBorderStyled $isScreen767={isScreen767}>
            <FormTitleStyled>
              {t('authorizationForms.createAccount')}
            </FormTitleStyled>
            {showEmailError && (
              <ErrorCodeStyled>
                {t('Регистрация на этот адрес электронной почты недоступна')}
              </ErrorCodeStyled>
            )}
            <Form
              layout={'vertical'}
              requiredMark={false}
              form={form}
              style={{ width: '100%' }}
            >
              <Form.Item
                label={t('authorizationForms.enterNickname')}
                name="nickname"
                rules={[
                  {
                    validator: (_, value) =>
                      nicknameValidator(value, setNicknameValidState),
                    message: `${t('Никнейм не соответствует требованиям')}`,
                  },
                ]}
                style={{ marginBottom: 24 }}
              >
                <InputWithTooltip
                  onChange={handleNickname}
                  validState={nicknameValidState}
                  type="nickname"
                />
              </Form.Item>
              <Form.Item
                label={t('authorizationForms.enterEmail')}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: `${t('authorizationForms.invalidEmailFormat')}`,
                  },
                  { required: true, message: `${t('titles.enterEmail')}` },
                ]}
                style={{ marginBottom: 24 }}
              >
                <Input
                  placeholder={t('authorizationForms.email').toString()}
                  onChange={handleEmail}
                />
              </Form.Item>
              <Form.Item
                label={t('authorizationForms.enterPassword')}
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${t('authorizationForms.enterPassword')}`,
                  },
                  {
                    validator: (_, value) =>
                      passwordValidator(value, setValidState),
                    message: `${t('authorizationForms.incorrectPassword')}`,
                  },
                ]}
                style={{ marginBottom: 24 }}
              >
                <InputWithTooltip
                  type="password"
                  onChange={handlePassword}
                  validState={validState}
                />
              </Form.Item>

              <Form.Item
                label={t('authorizationForms.repeatPassword')}
                name="password_repeat"
                rules={[
                  {
                    required: true,
                    message: `${t('authorizationForms.repeatPassword')}`,
                  },
                  ({ getFieldValue, setFields, getFieldError }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        setFields([
                          {
                            name: 'password',
                            errors: getFieldError('password').filter(
                              (err) =>
                                err !==
                                t('authorizationForms.passwordsDoesntMatch')
                            ),
                          },
                        ]);
                        return Promise.resolve();
                      }
                      setFields([
                        {
                          name: 'password',
                          errors: [
                            ...getFieldError('password').filter(
                              (err) =>
                                err !==
                                t('authorizationForms.passwordsDoesntMatch')
                            ),
                            t('authorizationForms.passwordsDoesntMatch'),
                          ],
                        },
                      ]);
                      return Promise.reject(
                        new Error(
                          `${t('authorizationForms.passwordsDoesntMatch')}`
                        )
                      );
                    },
                  }),
                ]}
                style={{ marginBottom: 32 }}
              >
                <Input.Password
                  placeholder={`${t('authorizationForms.password')}`}
                  onChange={handlePasswordRepeat}
                  onPressEnter={handleCreate}
                />
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              `${t('authorizationForms.consentMandatory')}`
                            )
                          ),
                  },
                ]}
                name="agreement"
                style={{ marginBottom: 32 }}
              >
                <CustomCheckbox onChange={setIsAgree} label={checkboxLabel()} />
              </Form.Item>
              <FormButtonStyled id={'create-account'} onClick={handleCreate}>
                {t('authorizationForms.createAccount')}
              </FormButtonStyled>
            </Form>
            <FormFooterStyled>
              <div>{t('authorizationForms.doYouHaveAccount')}</div>
              <Button
                type={'ghost'}
                onClick={navToLogin}
                id={'login-in-system'}
              >
                {t('authorizationForms.logIn')}
              </Button>
            </FormFooterStyled>
            <SupportTextWrapperStyled $isScreen767={isScreen767}>
              <SupportEmail />
            </SupportTextWrapperStyled>
          </FormBorderStyled>
        )}
      </Suspense>
      <Suspense fallback={'Loading...'}>
        {step === 2 && (
          <FormBorderStyled $isScreen767={isScreen767}>
            <FormTitleStyled>
              {t('authorizationForms.verifyYourAccount')}
            </FormTitleStyled>
            <FormSubTitleStyled>
              {t('authorizationForms.accountNotActivated')} {hideEmail(email)}{' '}
              {t('authorizationForms.followLinkEmail')}
            </FormSubTitleStyled>
            <FormButtonStyled id={'to-main-page'} onClick={navToLogin}>
              {t('authorizationForms.toMain')}
            </FormButtonStyled>
            <FormFooterStyled>
              <div>{t('authorizationForms.problemsLink')}</div>
              <Button onClick={() => window.open(TG_SUPPORT)} type={'ghost'}>
                {t('authorizationForms.help')}
              </Button>
            </FormFooterStyled>
          </FormBorderStyled>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

const TextWithUnderLineStyled = styled.span`
  text-decoration: underline;
  transition: 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #7b59fd;
  }
`;

const SupportTextWrapperStyled = styled.div<{
  $isScreen767?: boolean;
}>`
  width: 100%;
  margin-bottom: 16px;
  ${({ $isScreen767 }) =>
    !$isScreen767 &&
    css`
      text-align: center;
    `}
`;

export default Register;
