import { styled } from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  overflow-y: auto;
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
  &&& {
    .ant-modal-content {
      background: #2b2b36;
    }
  }
`;
