import { ItemType } from 'antd/es/menu/hooks/useItems';

export const getSubmenuOfKey = (
  menu: ItemType[],
  key: string
): string | null => {
  const findParentKey = (
    menu: ItemType[],
    targetKey: string,
    parentKey: string | null = null
  ): string | null => {
    for (const item of menu) {
      if (item && 'children' in item) {
        if (item.key === targetKey) return parentKey;

        const children = item.children;
        const result = children && findParentKey(children, targetKey, key);

        if (result) return result;
      }
    }
    return null;
  };

  return findParentKey(menu, key);
};
