import Icon, {
  ClockCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import {
  TetherOutlined,
  LockOutlined,
  TransactionOutlined,
  ArrowRightOutlined,
} from 'assets/img/icons/icon';
import { TDealsStatus } from 'interfaces';
import { DEAL_STATUS } from 'shared/constants/deal-status';
import { PROCESSING_STATUSES } from 'shared/hooks/use-deal-status-label';

interface DealStatusProps {
  className?: string;
  status: string;
  size?: number;
  hideDefault?: boolean;
  isNonGuarantee?: boolean;
  isSelling?: boolean;
}

const ICON_COLOR = {
  default: '#69B1FF',
  green: '#36CFC9',
  yellow: '#FFC069',
  red: '#FF7875',
  grey: '#A3A3A3',
};

// TODO: update for new statuses and is_guarantee
export const DealsStatusIcon = (props: DealStatusProps) => {
  const {
    className,
    status,
    size = 16,
    hideDefault,
    isNonGuarantee,
    isSelling,
  } = props;

  const getProps = ({
    color,
  }: {
    color?: string;
  } = {}) => ({
    className,
    style: {
      color: color || ICON_COLOR.default,
      fontSize: size,
    },
  });

  if (status === DEAL_STATUS.pending) {
    return <ClockCircleOutlined {...getProps()} />;
  }

  if (status === DEAL_STATUS.accepted) {
    return <CheckCircleOutlined {...getProps()} />;
  }

  if (status === DEAL_STATUS.warrantyCollateral) {
    return (
      <Icon
        component={TetherOutlined}
        {...getProps({ color: ICON_COLOR.yellow })}
      />
    );
  }

  if (status === DEAL_STATUS.waitingForFiatPayment) {
    return <ClockCircleOutlined {...getProps()} />;
  }

  if (status === DEAL_STATUS.ready) {
    return <Icon component={LockOutlined} {...getProps()} />;
  }

  if (
    status === DEAL_STATUS.processing ||
    PROCESSING_STATUSES.has(status as TDealsStatus)
  ) {
    return (
      <Icon
        component={TransactionOutlined}
        {...getProps({ color: ICON_COLOR.yellow })}
      />
    );
  }

  if (status === DEAL_STATUS.completed) {
    return <CheckCircleOutlined {...getProps({ color: ICON_COLOR.green })} />;
  }

  if (status === DEAL_STATUS.expired) {
    return <ClockCircleOutlined {...getProps({ color: ICON_COLOR.red })} />;
  }

  if (status === DEAL_STATUS.onArgument) {
    return (
      <ExclamationCircleOutlined {...getProps({ color: ICON_COLOR.yellow })} />
    );
  }

  if (status === DEAL_STATUS.cancelled) {
    return <CloseCircleOutlined {...getProps({ color: ICON_COLOR.red })} />;
  }

  if (status === DEAL_STATUS.complaint) {
    return (
      <ExclamationCircleOutlined {...getProps({ color: ICON_COLOR.red })} />
    );
  }

  if (isNonGuarantee === true) {
    if (
      (status === DEAL_STATUS.waitingForCryptoDelivery && isSelling) ||
      (status === DEAL_STATUS.waitingForFiatDelivery && !isSelling)
    ) {
      return <Icon component={ArrowRightOutlined} {...getProps()} />;
    } else {
      return <ClockCircleOutlined {...getProps()} />;
    }
  }

  return hideDefault ? null : (
    <ClockCircleOutlined {...getProps({ color: ICON_COLOR.grey })} />
  );
};
