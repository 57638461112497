import { EXCHANGE_MAP_API_URL } from 'packages/keycloak-client/constants';
import { axios } from 'shared/exios';

export const confirmFundsSend = async (id: number) => {
  const response = await axios.put(
    `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${id}/deliver-fiat`
  );

  return response;
};
