import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalRequisiteInfo,
  ModalRequisiteText,
  ModalRequisiteTitle,
  ModalStyled,
  ModalTitleStyled,
} from './ModalConfirmPayment.styles';
import { notification } from 'antd';
import { CloseIcon } from 'assets/img/icons/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CurrencyIcon } from 'shared/ui/CurrencyIcon';
import { confirmPayment } from 'shared/api/confirmPayment';
import { useResize } from 'hooks/useResize';

export const ModalConfirmPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isScreen720 } = useResize();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmPayment)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const { id, name, currency, updateCallback, refetch, deal } = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmPayment).options
  );

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalConfirmPayment);
  };
  const handleNavigateToDeal = () => {
    closeModal(ModalKeys.ModalConfirmPayment);
    navigate(`/deals/${id}`);
  };
  const handleChangeOfferRequestStatus = async () => {
    try {
      const response = await confirmPayment(id);

      if (updateCallback) {
        updateCallback(response.data.data.id, response.data.data.status);
      }

      notification.success({
        message: t('Средства отправлены'),
        icon: (
          <CheckCircleOutlined style={{ color: 'rgba(88, 191, 146, 1)' }} />
        ),
        closeIcon: <CloseIcon />,
        description: (
          <div>
            {t('Средства по сделке')}{' '}
            <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
              {name}
            </ButtonAsLinkStyled>{' '}
            {t(`на покупку успешно отправлены`, {
              currency: currency,
            })}
          </div>
        ),
        style: {
          width: '360px',
        },
      });
    } catch {
      notification.error({
        icon: <CloseCircleOutlined style={{ color: '#F16063' }} />,
        closeIcon: <CloseIcon />,
        message: t('Ошибка выполнения запроса'),
        description: t(
          'Произошла ошибка при выполнении запроса. Пожалуйста, попробуйте повторить запрос позже.'
        ),
        style: { width: '360px' },
      });
    } finally {
      closeModal(ModalKeys.ModalConfirmPayment);
      refetch && refetch();
    }
  };

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={560}
      $isScreen720={isScreen720}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled $isScreen720={isScreen720}>
          {t('Подтверждение отправки оплаты')}
        </ModalTitleStyled>
        <div>
          <ModalDescriptionStyled>
            {t(
              `Подтвердите запятая что вы перевели средства пункту обмена с помощью выбранного способа оплаты${
                deal?.requisite?.currency?.type === 'bank'
                  ? ' запятая по сделке на покупку'
                  : ''
              }`,
              {
                currency: currency,
              }
            )}
          </ModalDescriptionStyled>
          <ModalRequisiteInfo>
            <div>
              <ModalRequisiteTitle>{t('Способ оплаты')}</ModalRequisiteTitle>
              <ModalRequisiteText>
                <CurrencyIcon
                  code={deal?.requisite?.currency?.code}
                  type={deal?.requisite?.currency?.type}
                />
                {deal?.requisite?.currency?.name}
              </ModalRequisiteText>
            </div>
            <div>
              <ModalRequisiteTitle>{t('Реквизиты')}</ModalRequisiteTitle>
              <ModalRequisiteText>
                {deal?.requisite?.requisites}
              </ModalRequisiteText>
            </div>
            {/*deal?.requisite?.currency?.type === 'bank' && (
              <div>
                <ModalRequisiteTitle>{t('ФИО клиента')}</ModalRequisiteTitle>
                <ModalRequisiteText>{deal?.full_name}</ModalRequisiteText>
              </div>
            )*/}
          </ModalRequisiteInfo>
        </div>
        <ButtonGroupStyled $isScreen720={isScreen720}>
          <BackButtonStyled onClick={handleCloseModal}>
            {t('Назад')}
          </BackButtonStyled>
          <AcceptButtonStyled
            onClick={handleChangeOfferRequestStatus}
            type="primary"
          >
            {t('Подтвердить')}
          </AcceptButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
