export enum ModalKeys {
  ModalCancelTransaction = 'ModalCancelTransaction',
  ModalConfirmReceiptOfCash = 'ModalConfirmReceiptOfCash',
  ModalOpenDispute = 'ModalOpenDispute',
  ModalShowPointOnMap = 'ModalShowPointOnMap',
  ModalChatWindow = 'ModalChatWindow',
  ModalChatInformation = 'ModalChatInformation',
  ModalNotifications = 'ModalNotifications',
  ModalConfirmPayment = 'ModalConfirmPayment',
  ModalConfirmPaymentCrypto = 'ModalConfirmPaymentCrypto',
  ModalUserMenu = 'ModalUserMenu',
  ModalDealsFilters = 'ModalDealsFilter',
  ModalConfirmFundsSend = 'ModalConfirmFundsSend',
  ModalHeaderMenu = 'ModalHeaderMenu',
  ModalComplaint = 'ModalComplaint',
  ModalConfirmFundsReceived = 'ModalConfirmFundsReceived',
}
