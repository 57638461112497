import { useEffect } from 'react';

type UseClickOutsideProps = {
  ref: React.RefObject<HTMLElement>;
  condition: boolean;
  onClickOutside: () => void;
  disabled?: boolean;
};

export const useClickOutside = ({
  ref,
  condition,
  onClickOutside,
  disabled = false,
}: UseClickOutsideProps) => {
  useEffect(() => {
    if (disabled || !condition) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, condition, onClickOutside, disabled]);
};
