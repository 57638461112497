import {
  ChatAsideFooterStyled,
  ChatAsideHeaderStyled,
  ChatAsideLinkStyled,
  ChatAsideTitleStyled,
} from './ChatAside.style';
import { useEffect, useMemo } from 'react';
import { ChatAsideList } from '../ChatAsideList/ChatAsideList';
import { useTranslation } from 'react-i18next';
import { ChatTabs } from './ChatTabs/ChatTabs';
import { useChatAsideState } from '../../model/useChatAsideState';
import { useHeaderState } from 'state/header/useHeaderState';
import { useAuthState } from 'state/useAuthState';
import { ServerPublicationContext } from 'centrifuge';
import { useCentrifugeState } from '../../../../state/centrifuge/useCentrifugeState';

export const ChatAside = () => {
  const { t } = useTranslation();

  const personalChatsList = useChatAsideState(
    (state) => state.personalChatList
  );

  const corporateChatsList = useChatAsideState(
    (state) => state.corporateChatList
  );

  const chatType = useChatAsideState((state) => state.chatType);

  const { userFinmsApi } = useAuthState();

  const fetchChats = useChatAsideState((state) => state.fetchChats);

  const resetChatLists = useChatAsideState((state) => state.resetChatLists);

  const setUnreadMessages = useChatAsideState(
    (state) => state.setUnreadMessages
  );

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const isAdmin = !!useAuthState((state) => state.userFinmsApi?.company);

  const setActiveDropdown = useHeaderState((state) => state.setActiveDropdown);

  const chatList = useMemo(() => {
    const currentChatList =
      chatType === 'personal' ? personalChatsList : corporateChatsList;

    return {
      chats: currentChatList.data,
      totalChats: currentChatList.totalPages,
      totalItems: currentChatList.totalItems,
    };
  }, [chatType, personalChatsList, corporateChatsList]);

  useEffect(() => {
    fetchChats();
    return () => {
      resetChatLists();
    };
  }, []);

  useEffect(() => {
    const publicationHandler = (ctx: ServerPublicationContext) => {
      if (ctx?.data?.type === 'chat_metrics') {
        const { entity_type, entity_id, client_uid } = ctx?.data?.chat;
        const unreadMessagesCount = ctx?.data?.unread_message_count;

        setUnreadMessages(
          {
            entityType: entity_type,
            entityId: entity_id,
            clientUid: client_uid,
          },
          unreadMessagesCount
        );
      }
    };

    centrifugeInstance?.on('publication', publicationHandler);

    return () => {
      centrifugeInstance?.removeListener('publication', publicationHandler);
    };
  }, [centrifugeInstance, isAdmin]);

  const isCorporate = userFinmsApi.company?.id != null;

  return (
    <>
      <ChatAsideHeaderStyled $isCorporate={isCorporate}>
        <ChatAsideTitleStyled>{t('Чаты')}</ChatAsideTitleStyled>
        {isCorporate && <ChatTabs />}
      </ChatAsideHeaderStyled>
      <ChatAsideList {...chatList} />
      <ChatAsideFooterStyled>
        <ChatAsideLinkStyled
          to={'/chat'}
          onClick={() => setActiveDropdown(null)}
        >
          {t('Смотреть все чаты')}
        </ChatAsideLinkStyled>
      </ChatAsideFooterStyled>
    </>
  );
};
