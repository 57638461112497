import { ModalStyled } from './ModalChatWindow.styles';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { RequestChatWrapper } from 'features/Chats/ui/RequestChatWrapper/RequestChatWrapper';
import { OfferChatWrapper } from 'features/Chats/ui/OfferChatWrapper/OfferChatWrapper';

export const ModalChatWindow = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalChatWindow)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const closeChatModal = () => {
    closeModal(ModalKeys.ModalChatWindow);
  };

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalChatWindow).options
  );

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeChatModal}
      footer={null}
      maskClosable={false}
      centered
      width={'100%'}
    >
      {modalOptions.isListPage ? (
        <OfferChatWrapper isModal />
      ) : (
        <RequestChatWrapper dealInfo={modalOptions.deal} isModal />
      )}
    </ModalStyled>
  );
};
