import { styled } from 'styled-components';

export const StyledPickerWrapper = styled.div`
  .ant-picker-content .ant-picker-cell-range-hover::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
  }

  .ant-picker-content .ant-picker-cell-range-hover::after {
    border: none !important;
  }

  .ant-picker-content .ant-picker-cell-range-hover-end::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
    border-radius: 0 4px 4px 0;
  }

  .ant-picker-content .ant-picker-cell-range-hover-end::after {
    border: none !important;
  }

  .ant-picker-content .ant-picker-cell-range-hover-start::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
    border-radius: 4px 0 0 4px;
  }

  .ant-picker-content .ant-picker-cell-range-hover-start::after {
    border: none !important;
  }

  /*Picker colors start*/

  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #413d5080;
  }

  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ).ant-picker-cell-range-hover-end::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ).ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: #413d50;
  }

  /*Picker colors end*/
  .coin-wrapper-secondary {
    background: linear-gradient(75.86deg, #088473 6.84%, #0ec6ad 107.11%);
    border-radius: 8px;
    padding: 12px;
    width: 48px;
    height: 48px;
  }

  .coin-wrapper-primary {
    background: linear-gradient(75.86deg, #6320e6 6.84%, #7b59fd 99.53%);
    border-radius: 8px;
    padding: 12px;
    width: 48px;
    height: 48px;
  }

  .coin-wrapper-primary + .flex-column,
  .coin-wrapper-secondary + .flex-column {
    margin-left: 16px;
  }

  .ant-picker-cell {
    padding: 4px !important;
  }
`;
