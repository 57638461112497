import { useLocation } from 'react-router-dom';

interface IMenuStandartItemProps {
  children: React.ReactNode;
  pathkey: string;
}

export const MenuStandartItem = ({
  children,
  pathkey,
}: IMenuStandartItemProps) => {
  const location = useLocation();
  return (
    <div
      data-is-current-page={location.pathname === pathkey ? 'true' : undefined}
    >
      {children}
    </div>
  );
};
