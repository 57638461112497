import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ServerPublicationContext } from 'centrifuge';
import { useCentrifugeState } from '../../../../state/centrifuge/useCentrifugeState';
import { useChatsState } from 'state/chats/useChatsState';
import { IMessage } from 'shared/api/ChatsAPI/ChatsTypes';
import {
  ChatBodyStyled,
  EmptyMessagesStyled,
  SpinContainerStyled,
} from '../Chat.styles';
import { MessageItem } from '../MessageItem';
import {
  OfferChatBodyWrapperStyled,
  OfferMessagesWrapper,
} from './OfferChatStyles';
import { useChatListState } from '../../../../pages/chat-list/state/useChatListState';

type ChatBodyProps = {
  role: 'user' | 'admin';
  clientUid: string;
  entityID: string;
};

export const OfferChatBody = ({ role, clientUid, entityID }: ChatBodyProps) => {
  const { t } = useTranslation();

  const typeTabs = useChatListState((state) => state.typeTabs);

  const openedEntity = useChatsState((state) => state.openedEntity);

  const lastMessageRef = useRef<any>(null);
  const timeoutRef = useRef(null);

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const currentMessages = useChatsState((state) => state.currentMessages);
  const getMessagesOffer = useChatsState((state) => state.getMessagesOffer);

  const addNewMessageFromWS = useChatsState(
    (state) => state.addNewMessageFromWS
  );

  const isLoading = useChatsState((state) => state.isLoadingMessages);

  const [messages, setMessages] = useState<IMessage[]>(currentMessages);

  const addMessageFromWSCallBack = (message: IMessage) => {
    if (messages[messages?.length - 1]?.id !== message.id) {
      addNewMessageFromWS(message);
    }
  };

  useEffect(() => {
    if (role === 'user' && openedEntity.clientUid) {
      getMessagesOffer(entityID.toString(), 'user', openedEntity.clientUid);
    }
    if (role === 'admin' && openedEntity.clientUid) {
      getMessagesOffer(
        entityID.toString(),
        'exchange_point',
        openedEntity.clientUid
      );
    }
    return () => {
      setMessages([]);
    };
  }, [entityID, role, openedEntity.clientUid, openedEntity]);

  useEffect(() => {
    let messageWithAttachment: IMessage | null;
    const publicationHandler = (ctx: ServerPublicationContext) => {
      if (
        ctx?.channel?.includes('chat-exchange-point') &&
        typeTabs === 'personal'
      ) {
        return;
      } else if (
        ctx?.channel?.includes('chat-user') &&
        typeTabs === 'corporate'
      ) {
        return;
      } else if (
        ctx?.channel?.includes('chat-exchange-point') &&
        typeTabs === 'corporate' &&
        ctx?.data?.message?.is_mine !== true &&
        ctx?.data?.message?.has_attachment === false &&
        ctx?.data?.message?.participant?.participant_id !==
          openedEntity.clientUid
      ) {
        return;
      } else if (
        ctx?.data?.type === 'new_message' &&
        ctx?.data?.message?.entity_id.toString() === openedEntity.id &&
        ctx?.data?.message?.chat?.entity_type === openedEntity.entityType &&
        ctx?.data?.message?.is_system !== true
      ) {
        if (ctx?.data?.message?.has_attachment === false) {
          addMessageFromWSCallBack(ctx?.data?.message);
        } else {
          messageWithAttachment = ctx?.data?.message;
        }
      } else if (
        ctx?.data?.type === 'attachment_uploaded' &&
        messageWithAttachment &&
        messageWithAttachment.id.toString() ===
          ctx.data.attachment.message_id.toString()
      ) {
        messageWithAttachment = {
          ...messageWithAttachment,
          attachment: ctx?.data?.attachment,
        };
        addNewMessageFromWS(messageWithAttachment);
        messageWithAttachment = null;
      }
    };

    centrifugeInstance?.on('publication', publicationHandler);

    return () => {
      centrifugeInstance?.removeListener('publication', publicationHandler);
    };
  }, [centrifugeInstance, typeTabs, openedEntity]);

  useEffect(() => {
    setMessages(currentMessages);
    return () => {
      setMessages([]);
    };
  }, [currentMessages, currentMessages?.length, clientUid, role]);

  useEffect(() => {
    if (currentMessages?.length === 0 || messages?.length === 0 || isLoading) {
      return;
    }
    const lastMessage = lastMessageRef.current;
    if (lastMessage && lastMessage.parentElement) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // @ts-ignore
      timeoutRef.current = setTimeout(() => {
        requestAnimationFrame(() => {
          lastMessage.parentElement.scrollTo({
            top: lastMessage.offsetTop + lastMessage.offsetHeight,
            behavior: 'smooth',
          });
        });
      }, 700);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [messages, currentMessages, isLoading]);

  return (
    <OfferChatBodyWrapperStyled $isEmpty={messages?.length === 0}>
      <ChatBodyStyled>
        {isLoading && (
          <SpinContainerStyled>
            <Spin />
          </SpinContainerStyled>
        )}
        {!isLoading && !!messages?.length && (
          <>
            <OfferMessagesWrapper>
              {messages?.map((message: IMessage) => {
                return <MessageItem key={message.id} message={message} />;
              })}
            </OfferMessagesWrapper>
            <div ref={lastMessageRef} />
          </>
        )}
        {!isLoading && !messages?.length && (
          <EmptyMessagesStyled>{t('чат.Нет сообщений')}</EmptyMessagesStyled>
        )}
      </ChatBodyStyled>
    </OfferChatBodyWrapperStyled>
  );
};
