import type { FC, NamedExoticComponent } from 'react';
import { memo, Suspense } from 'react';
import { AdaptiveFullScreenLoader } from '../../shared/ui/full-screen-loader';

const LoadComponent = (Component: FC): NamedExoticComponent<any> =>
  memo((props: object) => (
    <>
      <Suspense fallback={<AdaptiveFullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    </>
  ));

export { LoadComponent };
