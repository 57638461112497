import { ScaleControl, TileLayer } from 'react-leaflet';
import { MapControls } from 'shared/ui/MapControls/MapControls';
import { MapContainerStyled, MapInput, MapWrapper } from './LeafletMap.styles';
import Marker from 'assets/img/icons/map/marker.svg';
import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';
import L from 'leaflet';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

interface ILeafletMapProps {
  currentPoint?: [number, number];
  initialPosition: [number, number];
  displayAddress: string;
}

export const LeafletMap = (props: ILeafletMapProps) => {
  const { currentPoint, initialPosition, displayAddress } = props;

  const [map, setMap] = useState<any>(null);
  const [point, setPoint] = useState<[number, number] | undefined>();
  const [curMarker, setCurMarker] = useState<any>(null);

  const mapRef = useRef<L.Map>(null);
  // const animateRef = useRef(true);

  // Обработка нажатия на току на карте, может потом пригодится
  // function SetViewOnClick({
  //   animateRef,
  // }: {
  //   animateRef: React.MutableRefObject<boolean>;
  // }) {
  //   const map = useMapEvent('click', (e) => {
  //     map.setView(e.latlng, map.getZoom(), {
  //       animate: animateRef.current || false,
  //     });
  //   });

  //   return null;
  // }

  useEffect(() => {
    if (!point) {
      curMarker?.remove();
      mapRef?.current?.setView(initialPosition);
    } else {
      mapRef?.current?.setView(point);
    }

    const pointIcon = L.icon({
      iconUrl: Marker,
      iconAnchor: [17, 42],
      shadowRetinaUrl: '',
    });

    const timeout = setTimeout(() => {
      const mapInstance: L.Map | null = mapRef.current;
      if (mapInstance && point) {
        const marker = L.marker(point, { icon: pointIcon });
        curMarker && curMarker.remove();
        mapInstance.addLayer(marker);
        setCurMarker(marker);
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [point]);

  useLayoutEffect(() => {
    setPoint(currentPoint);
  }, [currentPoint]);

  const getMap = useCallback(() => {
    return (
      <>
        <MapContainerStyled
          center={currentPoint ?? initialPosition}
          zoom={13}
          ref={mapRef}
          minZoom={3}
          maxBounds={[
            [90, 180], // South
            [-90, -180], // North
            [0, 0], // West
            [0, 0], // East
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          {/* <SetViewOnClick animateRef={animateRef} /> */}
          <ScaleControl position="bottomright" />
        </MapContainerStyled>
        <MapControls mapRef={mapRef} />
      </>
    );
  }, [currentPoint, initialPosition]);

  useEffect(() => {
    setMap(getMap());
  }, [getMap]);

  return (
    <MapWrapper>
      {map}
      <MapInput className="map-input" value={displayAddress} disabled />
    </MapWrapper>
  );
};
