import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import React from 'react';

export type TState = {
  isOpen: boolean;
  isClosing: boolean;
  clickedSubmenu: string | null;
  openSubtitles: string[];
  setOpenSubtitles: (subtitles: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
  setIsClosing: (isClosing: boolean) => void;
  setClickedSubmenu: (key: string | null) => void;
};

export const useMenuDropdownState = create<TState>()(
  devtools((set, get) => ({
    isOpen: false,
    isClosing: false,
    clickedSubmenu: null,
    openSubtitles: [],
    setOpenSubtitles: (subtitles) => {
      set({ openSubtitles: subtitles });
    },
    setIsOpen: (isOpen) => {
      set({ isOpen });
    },
    setIsClosing: (isClosing) => {
      set({ isClosing: isClosing });
    },
    setClickedSubmenu: (key) => set({ clickedSubmenu: key }),
  }))
);
