import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { notification } from 'antd';
import { CloseIcon, InfoCircle } from '../../../../../assets/img/icons/icons';
import { axios } from '../../../../exios';
import { EXCHANGE_MAP_API_URL } from '../../../../../packages/keycloak-client/constants';
import { Input, Form } from 'antd';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalStyled,
  ModalTextGroupStyled,
  ModalTitleStyled,
  RequiredMark,
} from './ModalComplaint.styles';
import { useNavigate } from 'react-router-dom';
import { useResize } from 'hooks/useResize';
import { IconNotificationError } from '../../../../../components/ui/icons';
import { t } from 'i18next';
import { AxiosError } from 'axios';

const { TextArea } = Input;
interface ValidationError {
  name: string; // Например, 'ValidationError'
  message: string;
  errorFields?: Array<{ name: string[]; errors: string[] }>; // Структура ошибки валидации Ant Design
}
export const ModalComplaint = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isScreen720 } = useResize();
  const [form] = Form.useForm();
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalComplaint)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalComplaint).options
  );

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalComplaint);
  };

  const handleNavigateToDeal = () => {
    closeModal(ModalKeys.ModalComplaint);
    navigate(`/deals/${modalOptions.id}`);
  };

  const handleComplaint = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      const response = await axios.put(
        `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${modalOptions.id}/complain`,
        {
          reason: values.reason,
        }
      );

      modalOptions?.updateCallback?.(
        response.data.data.id,
        response.data.data.status
      );

      notification.success({
        message: t('Жалоба отправлена'),
        icon: <InfoCircle />,
        closeIcon: <CloseIcon />,
        description: (
          <div>
            {t('Жалоба на сделку')}{' '}
            <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
              {modalOptions.name}
            </ButtonAsLinkStyled>{' '}
            {t(
              `на ${
                !modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } отправлена`,
              { currency: modalOptions.currency }
            )}
          </div>
        ),
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.error('Axios error:', error.response?.data || error.message);
        notification.error({
          icon: <IconNotificationError />,
          closeIcon: <CloseIcon />,
          message: t('Ошибка выполнения запроса'),
          description: t('Произошла ошибка при выполнении запроса'),
        });
      }
    } finally {
      closeModal(ModalKeys.ModalComplaint);
      modalOptions.refetch && modalOptions.refetch();
    }
  };

  const modalContainer =
    document.getElementById('modal-container') || document.body;

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={560}
      closable={true}
      $isScreen720={!isScreen720}
      getContainer={modalContainer}
      centered
    >
      <Form form={form} onFinish={handleComplaint}>
        <ModalBodyStyled>
          <ModalTextGroupStyled>
            <ModalTitleStyled $isScreen720={!isScreen720}>
              {t('Подача жалобы')}
            </ModalTitleStyled>
            <ModalDescriptionStyled $isScreen720={!isScreen720}>
              <div>
                {t(
                  'После отправки жалобы запятая действия по сделке больше не будут доступны'
                )}
              </div>
            </ModalDescriptionStyled>
            <CommentBlockStyled $isScreen720={!isScreen720}>
              <CommentTitleStyled>
                {t('Укажите причину жалобы')} <RequiredMark>*</RequiredMark>
              </CommentTitleStyled>
              <Form.Item
                name="reason" // Указываем имя поля
                rules={[
                  {
                    required: true,
                    message: t('Поле должно быть заполнено') as string,
                  },
                  {
                    min: 10,
                    message: t('Минимальная длина дефис 10 символов') as string,
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={5000}
                  placeholder={String(t('Введите комментарий'))}
                />
              </Form.Item>
            </CommentBlockStyled>
          </ModalTextGroupStyled>

          <ButtonGroupStyled $isScreen720={!isScreen720}>
            <BackButtonStyled
              onClick={handleCloseModal}
              $isScreen720={!isScreen720}
            >
              {t('Назад')}
            </BackButtonStyled>
            <AcceptButtonStyled type="primary" htmlType="submit">
              {t('Отправить')}
            </AcceptButtonStyled>
          </ButtonGroupStyled>
        </ModalBodyStyled>
      </Form>
    </ModalStyled>
  );
};
