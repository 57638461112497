import React from 'react';
import { ROUTER_PATH } from '../../../../../router/path';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import {
  CommonProfileControlIcon,
  DealsIcon,
  SecurityIcon,
  WalletMenuIcon,
} from '../../../../../assets/img/icons/icon';

type MenuItem = Required<MenuProps>['items'][number];

export const useGetSideBarElements = () => {
  const { t } = useTranslation();

  const PROFILE_ELEMENTS: MenuItem[] = [
    {
      key: 'key-1',
      theme: 'dark',
      icon: (
        <span>
          <WalletMenuIcon />
        </span>
      ),
      label: t('КОШЕЛЁК'),
      children: [
        {
          key: ROUTER_PATH.AuthRoutes.WALLETS,
          label: t('Баланс'),
        },
        {
          key: ROUTER_PATH.AuthRoutes.TRANSACTIONS,
          label: t('Транзакции'),
        },
      ],
    },
    {
      key: 'key-2',
      theme: 'dark',
      icon: (
        <span>
          <DealsIcon />
        </span>
      ),
      label: t('СДЕЛКИ'),
      children: [
        {
          key: ROUTER_PATH.AuthRoutes.MY_DEALS,
          label: t('Обмен с гарантом'),
        },
        {
          key: ROUTER_PATH.AuthRoutes.MY_DEALS_NO_GUARANTEE,
          label: t('Сторонний обмен'),
        },
      ],
    },
    {
      key: ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX,
      icon: (
        <span>
          <CommonProfileControlIcon />
        </span>
      ),
      label: t('ДАННЫЕ АККАУНТА'),
    },
    {
      key: ROUTER_PATH.AuthRoutes.SAFETY_INDEX,
      icon: (
        <span>
          <SecurityIcon />
        </span>
      ),
      label: t('БЕЗОПАСНОСТЬ'),
      // childerns: [
      // TODO: Вернуть 2FA
      // {
      //   pathKey: ROUTER_PATH.AuthRoutes.SAFETY_2FA,
      //   title: '2FA',
      // },
      // {
      //   // TODO: Добавить защиту по IP модуль
      //   pathKey: '',
      //   title: 'Защита по IP-адресу',
      // },
      // TODO: Добавить раздел devices
      // {
      //   pathKey: ROUTER_PATH.AuthRoutes.SAFETY_DEVICES,
      //   title: t('sidebar.profile.safety.devices'),
      // },
      // ],
    },
    // {
    //   pathKey: ROUTER_PATH.AuthRoutes.OPTIONS,
    //   iconComponent: GearIcon,
    //   title: t('НАСТРОЙКИ'),
    // },
  ];

  return {
    PROFILE_ELEMENTS,
  };
};
