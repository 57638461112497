import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalRequisiteInfo,
  ModalRequisiteText,
  ModalRequisiteTitle,
  ModalStyled,
  ModalTitleStyled,
} from './ModalConfirmPaymentCrypto.styles';
import { Button, notification, Space } from 'antd';
import { CloseIcon } from 'assets/img/icons/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { confirmPayment } from 'shared/api/confirmPayment';
import { useResize } from 'hooks/useResize';
import { ROUTER_PATH } from '../../../../../router/path';
import { NotificationInfoIcon } from '../../../../../assets/img/icons/icon';
import { useState } from 'react';
import { confirmFiatReceiving } from '../../../../api/confirmFiatReceiving';
import { confirmDeliverCrypto } from '../../../../api/confirmDeliverCrypto';

export const ModalConfirmPaymentCrypto = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isScreen720 } = useResize();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmPaymentCrypto)
  );
  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmPaymentCrypto).options
  );

  const closeModal = useModalState((state) => state.closeModal);

  const { id, name, currency, updateCallback, refetch, deal } = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmPaymentCrypto).options
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalConfirmPaymentCrypto);
  };
  const handleNavigateToDeal = () => {
    closeModal(ModalKeys.ModalConfirmPaymentCrypto);
    navigate(`/deals/${id}`);
  };
  const handleChangeOfferRequestStatus = async () => {
    setIsLoading(true);
    let response;
    try {
      if (modalOptions?.deal.offer?.type === 'cash') {
        response = await confirmFiatReceiving(id);
      } else {
        response = await confirmDeliverCrypto(id);
      }

      if (updateCallback) {
        updateCallback(response.data.data.id, response.data.data.status);
      }

      notification.success({
        message: t('Оплата отправлена'),
        icon: (
          <CheckCircleOutlined style={{ color: 'rgba(88, 191, 146, 1)' }} />
        ),
        closeIcon: <CloseIcon />,
        description: (
          <div>
            {t('Оплата по сделке')}{' '}
            <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
              {name}
            </ButtonAsLinkStyled>{' '}
            {t(`на покупку успешно отправлены`, {
              currency: currency,
            })}
          </div>
        ),
        style: {
          width: '360px',
        },
      });
    } catch (e: any) {
      const isNotBalanceEnough = !!e.response.data.errors.id.find(
        (el: string) => el.includes('Balance is not enough')
      );

      const key = 'is-not-balance-enough';

      const btn = (
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => {
              navigate(ROUTER_PATH.AuthRoutes.WALLET);
              notification.destroy(key);
            }}
          >
            {t('Пополнить баланс')}
          </Button>
        </Space>
      );

      if (isNotBalanceEnough) {
        notification.error({
          icon: <NotificationInfoIcon />,
          closeIcon: <CloseIcon />,
          message: t('Невозможно подтвердить оплату'),
          description: t(
            'Подтверждение не может быть выполнено, недостаточно средств на кошельке. Пополните баланс'
          ),
          style: { width: '360px' },
          btn,
          key,
        });
      } else {
        notification.error({
          icon: <CloseCircleOutlined style={{ color: '#F16063' }} />,
          closeIcon: <CloseIcon />,
          message: t('Ошибка выполнения запроса'),
          description: t(
            'Произошла ошибка при выполнении запроса. Пожалуйста, попробуйте повторить запрос позже.'
          ),
          style: { width: '360px' },
        });
      }
    } finally {
      setIsLoading(false);
      closeModal(ModalKeys.ModalConfirmPaymentCrypto);
      refetch && refetch();
    }
  };

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={560}
      $isScreen720={isScreen720}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled $isScreen720={isScreen720}>
          {t('Оплата')}
        </ModalTitleStyled>
        <div>
          <ModalDescriptionStyled>
            {t(`Средства будут переведены на счёт пункта обмена`)}
          </ModalDescriptionStyled>
          <ModalRequisiteInfo>
            <ModalRequisiteTitle>{t('Сумма оплаты')}</ModalRequisiteTitle>
            <ModalRequisiteText>
              {deal?.crypto_amount}
              &nbsp;
              {deal?.offer.crypto_payment_system_currency.currency_key}
            </ModalRequisiteText>
          </ModalRequisiteInfo>
        </div>
        <ButtonGroupStyled $isScreen720={isScreen720}>
          <BackButtonStyled onClick={handleCloseModal}>
            {t('Назад')}
          </BackButtonStyled>
          <AcceptButtonStyled
            onClick={handleChangeOfferRequestStatus}
            type="primary"
            loading={isLoading}
          >
            {t('Оплатить')}
          </AcceptButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
