import { useEffect } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { ContainerStyled } from './NotificationAsideBell.style';
import { useNotificationAsideState } from '../../model/useNotificationAsideState';
import { getCookieByName } from '../../../../shared/helpers/controlCookies';
import jwtDecode from 'jwt-decode';
import { Badge } from 'antd';
import { useCentrifugeState } from '../../../../state/centrifuge/useCentrifugeState';

interface NotificationsAsideBellProps {
  onClick: () => void;
}

export const NotificationsAsideBell = ({
  onClick,
}: NotificationsAsideBellProps) => {
  const totalCount = useNotificationAsideState(
    (state) => state.totalNotificationsCount
  );
  const setNotifications = useNotificationAsideState(
    (state) => state.setNotifications
  );
  const setTotalNotificationsCount = useNotificationAsideState(
    (state) => state.setTotalNotificationsCount
  );

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const connectToNotificationWs = () => {
    const decodedToken: any = jwtDecode(getCookieByName('token') || '');
    if (centrifugeInstance) {
      centrifugeInstance.on('publication', (ctx) => {
        if (ctx.channel === decodedToken.sub && decodedToken) {
          setNotifications();
          setTotalNotificationsCount();
        }
      });
    }
  };

  useEffect(() => {
    connectToNotificationWs();
    setTotalNotificationsCount();
  }, []);

  return (
    <>
      <ContainerStyled
        onClick={onClick}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Badge
          count={totalCount}
          size={'default'}
          color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
          showZero={true}
          offset={[7, 0]}
        >
          <BellOutlined />
        </Badge>
      </ContainerStyled>
    </>
  );
};
