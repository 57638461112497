import { Input } from 'antd';
import { MapContainer } from 'react-leaflet';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import styled from 'styled-components';

export const MapContainerStyled = styled(MapContainer)`
  &.leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 0;

    @media ${MEDIA_BREAKPOINT.MD} {
      border-radius: 16px;
    }
  }
`

export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MapInput = styled(Input)`
  &.ant-input {
    position: absolute;
    top: 16px;
    right: 64px;
    left: 16px;
    width: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.12);
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    z-index: 1000;

    @media ${MEDIA_BREAKPOINT.MD} {
      width: 360px;
      right: auto;
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media ${MEDIA_BREAKPOINT.XL} {
      width: 500px;
    }
  }
`;
