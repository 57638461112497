import { ModalStyled } from './ModalHeaderMenu.styles';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { Menu } from 'antd';
import { useWindowScrollLock } from 'shared/hooks/useWindowScrollLock';

export const ModalHeaderMenu = () => {
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalHeaderMenu)
  );

  const closeModal = useModalState((state) => state.closeModal);

  useWindowScrollLock(isModalOpen);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalHeaderMenu).options
  );

  const { items, Container, onClick, onContainerClick, openedKeys } =
    modalOptions;

  const closeMenuModal = () => {
    closeModal(ModalKeys.ModalHeaderMenu);
    modalOptions.onClose();
  };

  const modalContainer =
    document.querySelector<HTMLElement>(
      `.modal-container[data-key=${ModalKeys.ModalUserMenu}]`
    ) || document.body;

  return (
    <ModalStyled
      title={null}
      open={isModalOpen}
      onCancel={closeMenuModal}
      footer={null}
      getContainer={modalContainer}
    >
      <Container onClick={onContainerClick}>
        <Menu
          mode="inline"
          openKeys={openedKeys}
          onClick={onClick}
          items={items}
        />
      </Container>
    </ModalStyled>
  );
};
