import styled, { css } from 'styled-components';
import { Alert, Button, Input } from 'antd';
import { ITextAreWrapper } from 'shared/api/ChatsAPI/ChatsTypes';

// ---------- header ----------
export const ChatFooterStyled = styled.div`
  min-height: 56px;
  margin: 0 24px 24px;
`;

export const ChatHeaderStyled = styled.div`
  position: relative;
  border-top: 1px solid #413d50;
  border-bottom: 1px solid #413d50;
  display: flex;
  flex-direction: column;
  height: 76px;
  padding: 24px;
`;

// ---------- body ----------

export const ChatBodyStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  max-width: 380px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  margin: 16px 24px 0;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a98d8d2d;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(169, 141, 141, 0.22);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const ChatContainerStyled = styled.div`
  position: relative;
  background-color: #14141f;
  color: white;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;

  height: 100%;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;

  && {
    &.is-modal {
      height: 100dvh;
      width: unset;
      padding-left: 24px;
      padding-right: 24px;

      ${ChatBodyStyled} {
        max-width: 100%;
        margin: 16px 0px 0;
      }
      ${ChatHeaderStyled} {
        height: 84px;
        box-sizing: border-box;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }

      ${ChatFooterStyled} {
        margin: 0 0px 24px;
      }

      @media (max-width: 720px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
`;

// ---------- header ----------

export const ChatHeaderMainInfoStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const RefereeIconStyled = styled.div`
  position: absolute;
  left: 38px;
  top: 16px;
`;

export const AvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #6e27f8 -85.22%, #20d9c0 164.99%);
  border-radius: 3px;
  width: 24px;
  height: 24px;
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const PointIconStyled = styled.img`
  height: 16px;
  width: 16px;
  position: relative;
`;

export const RefereeAvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b2b36;
  border-radius: 3px;
  width: 24px;
  height: 24px;
`;

export const UserAvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b2b36;
  border-radius: 3px;
  width: 24px;
  height: 24px;
`;

export const ChatHeaderSubInfoStyled = styled.div`
  color: white;
  opacity: 0.5;
  font-size: 12px;
`;

export const SpinContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: calc(100vh - 80px);
  .ant-spin-dot-item {
    background: #1668dc;
  }
  overflow: hidden;
`;

export const EmptyMessagesStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 80vh;
  background-color: #474755;
  width: fit-content;
  margin: auto;
  padding: 4px 16px;
  border-radius: 4px;
  font-size: 12px;
`;

export const MessagesWrapper = styled.div`
  margin-top: auto;
  margin-right: 16px;
`;

export const MessageContainerStyled = styled.div<{ $isMine?: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: ${(props) => (props.$isMine ? 'flex-end' : 'flex-start')};
  margin-bottom: 16px;
`;

export const MessageAvatarContainer = styled.div`
  align-self: flex-end;
`;

export const MessageStyled = styled.div<{
  $isMine?: boolean;
  $hasImage?: boolean;
}>`
  word-break: break-word;
  min-width: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  border-radius: ${(props) =>
    props.$isMine ? '8px 8px 0 8px' : '8px 8px 8px 0'};
  padding: ${(props) => (props.$hasImage ? '0' : '8px 0px')};
  margin: 0 8px;
  background-color: ${(props) => (props.$isMine ? '#672aea' : '#2b2b36')};
  ${({ $isMine }) =>
    $isMine
      ? css`
          &:before {
            content: '';
            position: absolute;
            right: 0px;
            bottom: 0;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px;
            border-bottom: 5px solid #672aea;
            border-left: 5px solid #672aea;
            transform: translateX(90%);
          }
        `
      : css`
          &:before {
            content: '';

            position: absolute;
            border-top: none;
            bottom: 0;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px;
            border-bottom: 5px solid #2b2b36;
            border-right: 5px solid #2b2b36;
            transform: translateX(-90%);
          }
        `}
`;

export const MessageParticipantNameStyled = styled.div<{
  $isClient?: boolean;
  $isReferee?: boolean;
}>`
  padding: 2px 4px 2px 4px;
  display: flex;
  gap: 6px;
  font-size: 12px;
  color: white;

  ${({ $isClient }) =>
    $isClient &&
    css`
      color: #5297ff;
    `}

  ${({ $isReferee }) =>
    $isReferee &&
    css`
      color: #e8e264;
    `}
`;

export const MessageTextStyled = styled.div`
  padding: 4px 8px 0 8px;
`;

export const MessageTimeStyled = styled.div`
  padding: 4px 4px 4px 8px;
  align-self: flex-end;
  font-size: 10px;
`;

export const MessagePdfTextStyled = styled.div`
  width: 100%;
  color: rgba(251, 251, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 8px;
  margin-top: 4px;
`;

export const ImgStyled = styled.img`
  @starting-animation: 0.5s ease;
  max-width: 100%;
  max-height: 260px;
  object-fit: contain;
  overflow: hidden;
`;

export const MessageImgStyled = styled.div`
  max-width: 100%;
  max-height: 260px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

export const AttachmentWrapperStyled = styled.div<{ $right: boolean }>`
  @starting-animation: 0.5s ease;
  display: flex;
  justify-content: ${({ $right }) => ($right ? 'flex-end' : 'flex-start')};
  transition: 1s ease;
`;

// ---------- footer ----------

export const AlertStyled = styled(Alert)`
  margin: 0 20px;
  background: #2a1215;
  border: 1px solid #58181c;
  color: #ffffffd9;
`;

export const PopoverRowStyled = styled.span`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #fbfbff;
`;

export const SendButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  width: 40px !important;
  background: #2b2b36 !important;
  &:active {
    background: #2b2b36 !important;
  }
`;

export const TextAreaWrapper = styled(Input.TextArea)<ITextAreWrapper>`
  position: relative;

  & textarea {
    min-height: 40px;
    background: #14141f;
    border: 1px solid #474755;
    padding-bottom: ${({ $messageIsEmpty }) =>
      $messageIsEmpty ? '0' : '25px'} !important;

    // Стилизация текста в textarea
    color: #ffffff !important;
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5) !important;
      font-family: Inter, sans-serif !important;
      line-height: 20px !important;
      font-size: 12px !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(243, 243, 248, 1);
      border-radius: 0.5rem !important;
    }
  }

  & textarea:hover {
    border: 1px solid #474755;
  }

  & textarea:focus-visible {
    border: 1px solid #69697c;
  }

  & .ant-input-data-count {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 2;
    bottom: -7px;
    right: 0;
    transform: translate(-10px, -30px);
    color: rgba(118, 118, 135, 1);
    font-size: 11px !important;
    background-color: #14141f;
    border-radius: 5px 0 0 0;
    padding-top: 2px;
    width: 25%;
  }
`;

export const AttachmentPopoverContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
