import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalStyled,
  ModalTextGroupStyled,
  ModalTitleStyled,
} from './ModalConfirmReceiptOfCash.styles';
import React from 'react';
import { axios } from 'shared/exios';
import { EXCHANGE_MAP_API_URL } from 'packages/keycloak-client/constants';
import { notification } from 'antd';
import { CloseIcon } from '../../../../../assets/img/icons/icons';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useResize } from 'hooks/useResize';

export const ModalConfirmReceiptOfCash = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isScreen720 } = useResize();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmReceiptOfCash)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmReceiptOfCash).options
  );

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalConfirmReceiptOfCash);
  };

  const handleNavigateToDeal = () => {
    closeModal(ModalKeys.ModalOpenDispute);
    navigate(`/deals/${modalOptions.id}`);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      const response = await axios.put(
        `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${modalOptions.id}/confirm`
      );

      if (modalOptions.updateCallback) {
        modalOptions.updateCallback(
          response.data.data.id,
          response.data.data.status
        );
      }

      notification.success({
        message: t('Сделка выполнена'),
        icon: (
          <CheckCircleOutlined style={{ color: 'rgba(88, 191, 146, 1)' }} />
        ),
        closeIcon: <CloseIcon />,
        description: (
          <div>
            {t('Сделка')}{' '}
            <ButtonAsLinkStyled onClick={handleNavigateToDeal} role="link">
              {modalOptions.name}
            </ButtonAsLinkStyled>{' '}
            {t(
              `на ${
                !modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } успешно выполнена точка`,
              { currency: modalOptions.currency }
            )}
          </div>
        ),
        style: {
          width: '360px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalConfirmReceiptOfCash);
      modalOptions.refetch && modalOptions.refetch();
    }
  };

  const modalContainer =
    document.getElementById('modal-container') || document.body;

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      $isScreen720={!isScreen720}
      getContainer={modalContainer}
      closable={true}
      footer={null}
      width={560}
      centered
    >
      <ModalBodyStyled $isScreen720={!isScreen720}>
        <ModalTextGroupStyled $isScreen720={!isScreen720}>
          <ModalTitleStyled $isScreen720={!isScreen720}>
            {t('Подтвердить получение оплаты вопрос')}
          </ModalTitleStyled>
          <ModalDescriptionStyled>
            {t(
              'После подтверждения действия запятая гарант будет переведен на счет пункта обмена и сделка будет'
            )}
          </ModalDescriptionStyled>
        </ModalTextGroupStyled>

        <ButtonGroupStyled $isScreen720={!isScreen720}>
          <BackButtonStyled
            onClick={handleCloseModal}
            $isScreen720={!isScreen720}
          >
            {t('Назад')}
          </BackButtonStyled>
          <AcceptButtonStyled
            onClick={handleChangeOfferRequestStatus}
            type="primary"
          >
            {t('Подтвердить')}
          </AcceptButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
