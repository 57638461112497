import { DatePicker } from 'antd';
import Icon from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { DashCircle } from '../../assets/img/icons/icon';
import './CustomDatePicker.styles';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { StyledPickerWrapper } from './CustomDatePicker.styles';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().endOf('day');
};

interface ICustomDatePickerProps {
  setValue: (arg: Array<string>) => void;
  valueDate?: string[];
}

export const CustomDatePicker = ({
  setValue,
  valueDate,
}: ICustomDatePickerProps) => {
  const isTablet = useWindowBreakpointState(
    (state) => state.computed.isScreen720
  );

  return (
    <StyledPickerWrapper>
      <DatePicker.RangePicker
        value={
          valueDate ? [dayjs(valueDate[0]), dayjs(valueDate[1])] : undefined
        }
        clearIcon={<Icon component={() => DashCircle()} />}
        disabledDate={disabledDate}
        style={{ width: '100%' }}
        placement={isTablet ? undefined : 'topLeft'}
        onChange={(val) => {
          setValue(val?.map((el) => el?.toString()) as string[]);
        }}
      />
    </StyledPickerWrapper>
  );
};
