import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";
import { ScreenBreakpoints } from "shared/constants/enums";

export interface IWindowState {
  width: number
  computed: {
    get isScreen350(): boolean
    get isScreen360(): boolean
    get isScreen380(): boolean
    get isScreen480(): boolean
    get isScreen575(): boolean
    get isScreen720(): boolean
    get isScreen767(): boolean
    get isScreen991(): boolean
    get isScreen1024(): boolean
    get isScreen1099(): boolean
    get isScreen1199(): boolean
    get isScreen1280(): boolean
    get isScreen1300(): boolean
    get isScreen1400(): boolean
    get isScreen1440(): boolean
    get isScreen1500(): boolean
    get isScreen1600(): boolean
    get isScreen1700(): boolean
  }
}

window.addEventListener('resize', () => {
  useWindowBreakpointState.setState({ width: window.innerWidth ?? 0 })
});

export const useWindowBreakpointState = create<IWindowState>()(
  devtools((set, get) => ({
    width: window.innerWidth ?? 0,
    setWidth: (width: number) => {
      set(
        produce(draft => {
          draft.width = width
        })
      )
    },
    // optional get() is required to not throw when redux dev tools are running
    computed: {
      get isScreen350() { return get()?.width >= ScreenBreakpoints.SCREEN_350 },
      get isScreen360() { return get()?.width >= ScreenBreakpoints.SCREEN_360 },
      get isScreen380() { return get()?.width >= ScreenBreakpoints.SCREEN_380 },
      get isScreen480() { return get()?.width >= ScreenBreakpoints.SCREEN_480 },
      get isScreen575() { return get()?.width >= ScreenBreakpoints.SCREEN_575 },
      get isScreen720() { return get()?.width >= ScreenBreakpoints.SCREEN_720 },
      get isScreen767() { return get()?.width >= ScreenBreakpoints.SCREEN_767 },
      get isScreen991() { return get()?.width >= ScreenBreakpoints.SCREEN_991 },
      get isScreen1024() { return get()?.width > ScreenBreakpoints.SCREEN_1024 },
      get isScreen1099() { return get()?.width >= ScreenBreakpoints.SCREEN_1099 },
      get isScreen1199() { return get()?.width >= ScreenBreakpoints.SCREEN_1199 },
      get isScreen1280() { return get()?.width >= ScreenBreakpoints.SCREEN_1280 },
      get isScreen1300() { return get()?.width >= ScreenBreakpoints.SCREEN_1300 },
      get isScreen1400() { return get()?.width >= ScreenBreakpoints.SCREEN_1400 },
      get isScreen1440() { return get()?.width >= ScreenBreakpoints.SCREEN_1440 },
      get isScreen1500() { return get()?.width >= ScreenBreakpoints.SCREEN_1500 },
      get isScreen1600() { return get()?.width >= ScreenBreakpoints.SCREEN_1600 },
      get isScreen1700() { return get()?.width >= ScreenBreakpoints.SCREEN_1700 },
    }
  }))
)