import styled, { css } from 'styled-components';
import { Button, Modal } from 'antd';

export const ModalStyled = styled(Modal)<{ $isScreen720: boolean }>`
  .ant-modal-close {
    svg {
      height: 13px;
      width: 13px;
      margin-bottom: 10px;
    }
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  ${({ $isScreen720 }) =>
    !$isScreen720 &&
    css`
      min-width: 100%;
      height: 100%;
      .ant-modal-close {
        svg {
          position: absolute;
          top: 5px;
          right: -20px;
        }
      }
      .ant-modal-content {
        height: 100%;
        border-radius: 0;
        padding-left: 10px;
        padding-right: 10px;
      }
    `};
`;

export const ModalTitleStyled = styled.div<{ $isScreen720: boolean }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: rgba(251, 251, 255, 1);

  ${({ $isScreen720 }) =>
    !$isScreen720 &&
    css`
      padding-right: 40px;
      font-size: 22px;
    `}
`;
export const ModalDescriptionStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(222, 222, 227, 1);
  margin-bottom: 24px;
`;

export const BackButtonStyled = styled(Button)`
  min-width: 180px;
  border: 1px solid rgba(99, 32, 229, 1);
  background-color: transparent;

  &&:hover {
    border: 1px solid rgba(99, 32, 229, 1);
    background: linear-gradient(#2b2b36, #2b2b36) padding-box padding-box,
      linear-gradient(52deg, #7d50ff 0%, #7785ff 100%) border-box border-box;
  }
`;

export const AcceptButtonStyled = styled(Button)`
  width: 100%;
`;

export const ModalRowStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ButtonGroupStyled = styled.div<{ $isScreen720: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  ${({ $isScreen720 }) =>
    !$isScreen720 &&
    css`
      position: absolute;
      bottom: 32px;
      width: calc(100% - 20px);
    `}
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;

export const ModalRequisiteInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background-color: #2b2b36;
  border-radius: 8px;
`;

export const ModalRequisiteTitle = styled.div`
  color: #ffffff80;
`;

export const ModalRequisiteText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  word-break: break-word;
`;
