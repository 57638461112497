import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTER_PATH } from '../path';
import { ACCOUNT_SETTINGS_ROUTES } from './app/account-settings';
import { SAFETY_ROUTES } from './app/safety';
import { CHAT_ROUTES } from './app/chat';
import { useCookiesCustom } from '../../hooks/useCookieCustom';
import AppLayout from '../../components/tools/layouts';
import { isDevelopment } from '../../shared/helpers/environment';

const Base = () => {
  const referrerUrl = new URLSearchParams(window.location.search).get(
    'referrer_url'
  );

  const { cookie } = useCookiesCustom();

  if (referrerUrl) {
    window.localStorage.setItem(
      'referrer_url',
      decodeURIComponent(referrerUrl)
    );
  }

  switch (window.location.pathname) {
    case ROUTER_PATH.AuthRoutes.CHANGE_EMAIL:
      return <Navigate to={window.location.pathname} />;
    default:
      return cookie['token'] ? (
        <Navigate to={ROUTER_PATH.AuthRoutes.MY_DEALS} />
      ) : (
        <Navigate to={ROUTER_PATH.FormsPages.LOGIN} />
      );
  }
};

const APP_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      ...ACCOUNT_SETTINGS_ROUTES,
      ...SAFETY_ROUTES,
      ...CHAT_ROUTES,
      {
        index: true,
        element: <Base />,
      },
    ],
  },
];

if (isDevelopment) {
  import('./app/dev').then(({ DEV_ROUTES }) => {
    APP_ROUTES[0].children?.push(...DEV_ROUTES);
  });
}

export { APP_ROUTES };
