import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';

export const NotificationAsideContainerStyled = styled.div`
  background-color: #14141f;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_BREAKPOINT.MD} {
    background-color: transparent;
    height: 100%;
  }
`;

export const NotificationAsideHeaderStyled = styled.div`
  background: #14141f;
  position: sticky;
  top: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  padding: 20px 72px 10px 24px;

  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 40px 32px 10px 32px;
  }
`;

export const NotificationAsideSubHeaderStyled = styled.div`
  position: sticky;
  top: 0;
  min-height: auto;
  height: auto;
  display: block;
  align-items: stretch;
  padding: 0 24px;
  border-bottom: 1px solid #413d5080;

  @media ${MEDIA_BREAKPOINT.MD} {
    min-height: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
`;

export const NotificationAsideTitleStyled = styled.h5`
  margin: -20px 0 0 0 !important;
  padding: 0;
  font-weight: 700;
  font-size: 18px;
  color: rgba(251, 251, 255, 1);
  line-height: 29px;

  @media ${MEDIA_BREAKPOINT.MD} {
    font-size: 24px;
  }
`;

export const NotificationAsideSettingsButtonStyled = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const NotificationAsideContentStyled = styled.div`
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #474755;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const NotificationAsideFooterStyled = styled.div`
  position: sticky;
  bottom: 0;
  min-height: 56px;
  height: 56px;
  background-color: #14141f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid #413d5080;
`;

export const NotificationAsideLinkStyled = styled(Link)`
  line-height: 24px;
  color: white;
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;
export const NotificationAsideButtonStyled = styled.div`
  line-height: 22px;
  color: white;
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;

export const StyledRightsideText = styled.span`
  line-height: 22px;
`;
