import { ROUTER_PATH } from '../../../../../../../../router/path';

export const DELAY_ANIMATION = 0.5;
export const SERVICES_SUBTITLE_KEY = '/services';
export const PAYMENT_METHODS_KEY = '/payment-methods';
export const SUBTITLES_CLOSE_KEYS = [
  '/server/account/settings',
  '/safety',
  '/payment-methods',
];
export const MODAL_OPENED_KEYS = [
  ROUTER_PATH.AuthRoutes.WALLETS,
  ROUTER_PATH.AuthRoutes.MY_DEALS,
  '/services',
];
