import { axios } from '../../exios';
import { AxiosResponse } from 'axios';
import { CHAT_URL } from '../../../packages/keycloak-client/constants';
import { Base64ToBlob } from '../../helpers/Base64ToBlob';
import {
  EntityType,
  GetMessagesRequestType,
  IChat,
  ICountChats,
  RolesType,
  SearchAttachmentsPayloadType,
  UploadAttachmentToMinioType,
} from './ChatsTypes';

export const getUnreadMessagesCount = async (isAdmin: boolean) => {
  const userRole = isAdmin ? 'admin' : 'user';
  return await axios.get<ICountChats>(
    `${CHAT_URL}/server/chat/api/${userRole}/message/get-count`
  );
};

export const getMessages_referee = async (entity: EntityType, id: string) => {
  return axios.post<AxiosResponse<GetMessagesRequestType>>(
    `${CHAT_URL}/server/chat/api/referee/message/search`,
    {
      entity_type: entity,
      entity_id: id,
      sort: [
        {
          field: 'created_at',
          direction: 'asc',
        },
      ],
      limit: 1000,
    }
  );
};

export const getMessages_user = async (entity: EntityType, id: string) => {
  return axios.post<AxiosResponse<GetMessagesRequestType>>(
    `${CHAT_URL}/server/chat/api/user/message/search`,
    {
      entity_type: entity,
      entity_id: id,
      sort: [
        {
          field: 'created_at',
          direction: 'asc',
        },
      ],
      limit: 1000,
    }
  );
};

export const getMessages_offer = async (
  entity: EntityType,
  uid: string,
  role: RolesType,
  id: string
) => {
  return axios.post<AxiosResponse<GetMessagesRequestType>>(
    `${CHAT_URL}/server/chat/api/${
      role === 'user' ? 'user' : 'admin'
    }/message/search`,
    {
      entity_type: entity,
      entity_id: id,
      client_uid: uid,
      sort: [
        {
          field: 'created_at',
          direction: 'asc',
        },
      ],
      limit: 1000,
    }
  );
};

export const getChatInfo_user = async (
  entity: EntityType,
  id: string,
  uid: string
) => {
  return axios.post<AxiosResponse<IChat>>(
    `${CHAT_URL}/server/chat/api/user/chat/info`,
    {
      entity_type: entity,
      entity_id: id,
      client_uid: uid,
    }
  );
};

export const getChatInfo_referee = async (entity: EntityType, id: string) => {
  return axios.post<AxiosResponse<IChat>>(
    `${CHAT_URL}/server/chat/api/referee/chat/info`,
    {
      entity_type: entity,
      entity_id: id,
    }
  );
};

export const getOfferChatInfo_admin = async (
  entity: EntityType,
  id: string,
  uid: string
) => {
  return axios.post<AxiosResponse<IChat>>(
    `${CHAT_URL}/server/chat/api/admin/chat/info`,
    {
      entity_type: entity,
      entity_id: id,
      client_uid: uid,
    }
  );
};

export const getOfferChatInfo_client = async (
  entity: EntityType,
  id: string,
  uid: string
) => {
  return axios.post<AxiosResponse<IChat>>(
    `${CHAT_URL}/server/chat/api/user/chat/info`,
    {
      entity_type: entity,
      entity_id: id,
      client_uid: uid,
    }
  );
};

export const sendMessage_offer = async (
  entity: EntityType,
  id: string,
  messageText: string,
  hasAttachment: boolean,
  role: RolesType,
  clientUid: string
) => {
  if (messageText?.trim().length) {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/${
        role === 'user' ? 'user' : 'admin'
      }/message`,
      {
        entity_type: entity,
        entity_id: id,
        text: messageText,
        has_attachment: hasAttachment,
        client_uid: clientUid,
      }
    );
  } else {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/${
        role === 'user' ? 'user' : 'admin'
      }/message`,
      {
        entity_type: entity,
        entity_id: id,
        has_attachment: hasAttachment,
        client_uid: clientUid,
      }
    );
  }
};

export const sendMessage_referee = async (
  entity: EntityType,
  id: string,
  messageText: string,
  hasAttachment: boolean
) => {
  if (messageText?.trim().length) {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/referee/message`,
      {
        entity_type: entity,
        entity_id: id,
        text: messageText,
        has_attachment: hasAttachment,
      }
    );
  } else {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/referee/message`,
      {
        entity_type: entity,
        entity_id: id,
        has_attachment: hasAttachment,
      }
    );
  }
};

export const sendMessage_user = async (
  entity: EntityType,
  id: string,
  messageText: string,
  hasAttachment: boolean
) => {
  if (messageText?.trim().length) {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/user/message`,
      {
        entity_type: entity,
        entity_id: id,
        text: messageText,
        has_attachment: hasAttachment,
      }
    );
  } else {
    return axios.post<AxiosResponse<any>>(
      `${CHAT_URL}/server/chat/api/user/message`,
      {
        entity_type: entity,
        entity_id: id,
        has_attachment: hasAttachment,
      }
    );
  }
};

export const postAttachmentStep2 = async (
  payload: UploadAttachmentToMinioType
) => {
  const [header, base64] = payload.files[0].preview.split(',');
  const contentType = header.split(':')[1].split(';')[0];
  const blob = Base64ToBlob(base64, contentType);
  return fetch(payload.upload_link, {
    method: 'put',
    body: blob,
    headers: {
      'Content-Type': payload?.type ? payload?.type : '',
    },
  });
};

export const postAttachmentStep1_user = async (
  payload: SearchAttachmentsPayloadType
) => {
  return axios.post<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/user/attachment`,
    payload
  );
};

export const postAttachmentStep3_user = async (
  attachment_id: string | number | null
) => {
  return axios.put<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/user/attachment/${attachment_id}`,
    {
      is_uploaded: true,
    }
  );
};

export const postAttachmentStep1_referee = async (
  payload: SearchAttachmentsPayloadType
) => {
  return axios.post<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/referee/attachment`,
    payload
  );
};

export const postAttachmentStep3_referee = async (
  attachment_id: string | number | null
) => {
  return axios.put<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/referee/attachment/${attachment_id}`,
    {
      is_uploaded: true,
    }
  );
};

export const postAttachmentStep1_offer = async (
  payload: SearchAttachmentsPayloadType,
  role: RolesType
) => {
  return axios.post<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/${
      role === 'user' ? 'user' : 'admin'
    }/attachment`,
    payload
  );
};

export const postAttachmentStep3_offer = async (
  attachment_id: string | number | null,
  role: RolesType
) => {
  return axios.put<AxiosResponse<any>>(
    `${CHAT_URL}/server/chat/api/${
      role === 'user' ? 'user' : 'admin'
    }/attachment/${attachment_id}`,
    {
      is_uploaded: true,
    }
  );
};
