import styled from 'styled-components';
import { Badge, Typography } from 'antd';

export const ChatItemWrapper = styled.div<{
  $is_buying_crypto?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  padding: 24px 16px;
  height: 82px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-left: ${({ $is_buying_crypto }) =>
    $is_buying_crypto
      ? '2px solid rgba(8, 132, 115, 1)'
      : '2px solid rgba(99, 32, 229, 1)'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 719px) {
    /*без этого на малых экранах градиентный бордер выходит за границу экрана - его не видно*/
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2px;
    gap: 12px;
    height: auto;
    padding: 16px;
  }
`;

export const BadgeStyled = styled(Badge)<{ $is_corporate: boolean }>`
  margin-bottom: ${({ $is_corporate }) => ($is_corporate ? '24px' : '0')};
  margin-left: auto;
  @media (max-width: 719px) {
    position: absolute;
    right: 16px;
  }
`;

export const DirectionContainerStyled = styled.div<{ $isCorporate: boolean }>`
  display: flex;
  align-self: ${({ $isCorporate }) => ($isCorporate ? 'flex-start' : 'auto')};
  gap: 8px;
  align-items: flex-start;
  width: 200px;
  max-height: 17px;
  justify-content: flex-start;
  align-items: center;
`;

export const TypographyStyled = styled(Typography.Text)`
  color: rgba(118, 118, 135, 1);
  line-height: 14.52px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 719px) {
    max-width: 200px;
  }
`;

export const IconsFlexStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  line-height: 14.52px;
  @media (max-width: 719px) {
    align-self: flex-start;
  }
`;
