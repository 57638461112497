import { useTranslation } from 'react-i18next';
import {
  NotificationAsideContainerStyled,
  NotificationAsideHeaderStyled,
  NotificationAsideTitleStyled,
  NotificationAsideSettingsButtonStyled,
  NotificationAsideSubHeaderStyled,
  NotificationAsideContentStyled,
  NotificationAsideFooterStyled,
  NotificationAsideLinkStyled,
  NotificationAsideButtonStyled,
  StyledRightsideText,
} from './NotificationAsideContent.style';
import { SettingOutlined } from '@ant-design/icons';
import { useNotificationAsideState } from 'widgets/notificationAside/model/useNotificationAsideState';
import { NotificationAsideTabs } from '../NotificationAsideTabs/NotificationAsideTabs';
import { NotificationAsideList } from '../../NotificationAsideList/NotificationAsideList';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { useHeaderState } from 'state/header/useHeaderState';

export const NotificationAsideContent = () => {
  const { t } = useTranslation();

  const notifications = useNotificationAsideState(
    (state) => state.notifications
  );
  const readAllNotifications = useNotificationAsideState(
    (state) => state.readAllNotifications
  );

  const setActiveDropdown = useHeaderState((state) => state.setActiveDropdown);

  const closeModal = useModalState((state) => state.closeModal);

  const readAllClickHandler = () => {
    readAllNotifications();
  };

  const handleCloseModal = () => {
    setActiveDropdown(null);
    closeModal(ModalKeys.ModalNotifications);
  };

  return (
    <NotificationAsideContainerStyled>
      <NotificationAsideHeaderStyled>
        <NotificationAsideTitleStyled>
          {t('Уведомления')}
        </NotificationAsideTitleStyled>
        <NotificationAsideSettingsButtonStyled
          to={'/notifications-settings'}
          onClick={handleCloseModal}
        >
          <SettingOutlined color={'rgba(22, 119, 255, 1)'} />
          <StyledRightsideText>{t('Настройка')}</StyledRightsideText>
        </NotificationAsideSettingsButtonStyled>
      </NotificationAsideHeaderStyled>
      <NotificationAsideSubHeaderStyled>
        <NotificationAsideTabs />
      </NotificationAsideSubHeaderStyled>
      <NotificationAsideContentStyled>
        <NotificationAsideList />
      </NotificationAsideContentStyled>
      {!!notifications.length && (
        <NotificationAsideFooterStyled>
          <NotificationAsideLinkStyled
            to={'/notifications'}
            onClick={handleCloseModal}
          >
            {t('Смотреть все уведомления')}
          </NotificationAsideLinkStyled>
          <NotificationAsideButtonStyled
            onClick={readAllClickHandler}
            role={'button'}
          >
            {t('Прочитать всё')}
          </NotificationAsideButtonStyled>
        </NotificationAsideFooterStyled>
      )}
    </NotificationAsideContainerStyled>
  );
};
