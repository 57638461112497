import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreditCard,
  DocumentIcon,
  ServicesBurgerSubmenuOpenIcon,
  ShieldPageRedirectIcon,
  UserIcon,
  WalletIcon,
} from 'assets/img/icons/icons';
import { MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { ServicesItem } from '../components/ServicesItem';
import {
  ACCOUNT_URL,
  EXCHANGE_MAP_URL,
  FINMS_URL,
} from 'packages/keycloak-client/constants';
import { MenuStandartItem } from '../components/MenuStandartItem';
import {
  PAYMENT_METHODS_KEY,
  SERVICES_SUBTITLE_KEY,
} from '../constants/constants';
import { useAuthState } from 'state/useAuthState';
import { ROUTER_PATH } from '../../../../../../../../router/path';

export const useMenuItems = () => {
  const { userFinmsApi } = useAuthState();

  const { t } = useTranslation();
  const location = useLocation();

  const menuItems: Required<MenuProps>['items'][number][] = useMemo(
    () => [
      {
        key: ROUTER_PATH.AuthRoutes.WALLETS,
        icon: <WalletIcon />,
        label: t('Кошелёк'),
        children: [
          {
            key: ROUTER_PATH.AuthRoutes.WALLETS,
            label: (
              <MenuStandartItem pathkey={ROUTER_PATH.AuthRoutes.WALLETS}>
                {t('Баланс')}
              </MenuStandartItem>
            ),
          },
          {
            key: ROUTER_PATH.AuthRoutes.TRANSACTIONS.replace(':coin', 'btc'),
            label: (
              <MenuStandartItem
                pathkey={ROUTER_PATH.AuthRoutes.TRANSACTIONS.replace(
                  ':coin',
                  'btc'
                )}
              >
                {t('Транзакции')}
              </MenuStandartItem>
            ),
          },
        ],
      },
      {
        key: ROUTER_PATH.AuthRoutes.MY_DEALS,
        icon: <DocumentIcon />,
        label: t('Сделки'),
        children: [
          {
            key: ROUTER_PATH.AuthRoutes.MY_DEALS,
            label: (
              <MenuStandartItem pathkey={ROUTER_PATH.AuthRoutes.MY_DEALS}>
                {t('Обмен с гарантом')}
              </MenuStandartItem>
            ),
          },
          {
            key: ROUTER_PATH.AuthRoutes.MY_DEALS_NO_GUARANTEE,
            label: (
              <MenuStandartItem
                pathkey={ROUTER_PATH.AuthRoutes.MY_DEALS_NO_GUARANTEE}
              >
                {t('Сторонний обмен')}
              </MenuStandartItem>
            ),
          },
        ],
      },
      {
        key: ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX,
        icon: <UserIcon />,
        label: (
          <MenuStandartItem
            pathkey={ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX}
          >
            {t('Данные аккаунта')}
          </MenuStandartItem>
        ),
      },
      {
        key: ROUTER_PATH.AuthRoutes.SAFETY_INDEX,
        icon: <ShieldPageRedirectIcon />,
        label: (
          <MenuStandartItem pathkey={ROUTER_PATH.AuthRoutes.SAFETY_INDEX}>
            {t('Безопасность')}
          </MenuStandartItem>
        ),
      },
      {
        key: PAYMENT_METHODS_KEY,
        icon: <CreditCard />,
        label: (
          <MenuStandartItem pathkey={PAYMENT_METHODS_KEY}>
            {t('Способы оплаты')}
          </MenuStandartItem>
        ),
      },
      {
        key: SERVICES_SUBTITLE_KEY,
        icon: <ServicesBurgerSubmenuOpenIcon />,
        label: t('Сервисы Edenex'),
        children: [
          {
            key: ACCOUNT_URL,
            label: (
              <ServicesItem
                opened
                text={t('Platform')}
                subtext={t('Кошелёк запятая сделки и настройки аккаунта')}
              />
            ),
          },
          {
            key: EXCHANGE_MAP_URL,
            label: (
              <ServicesItem
                text={t('Exchange_Map')}
                subtext={t(
                  'Сделки с гарантом запятая мониторинг и партнерство'
                )}
              />
            ),
          },
          userFinmsApi.company && {
            key: FINMS_URL,
            label: (
              <ServicesItem
                text={t('Fin_MS')}
                subtext={t('Управление пунктами обмена')}
              />
            ),
          },
        ],
      },
    ],
    [t, location.pathname]
  );

  return menuItems;
};
