import { MenuOutlined } from '@ant-design/icons';
import {
  BurgerButtonStyled,
  MenuBurgerContainer,
  MenuDropdownContainer,
  MenuModalContainer,
} from './styles';
import { Menu, MenuProps } from 'antd';
import { useMenuItems } from './hooks/useMenuItems';
import { RenderModal } from 'shared/ui/RenderModal';
import { ModalKeys } from 'shared/ui/RenderModal/model/enums';
import { useModalState } from 'shared/ui/RenderModal/model/useModalState';
import { useResize } from 'hooks/useResize';
import { useNavigate } from 'react-router-dom';
import { useMenuDropdownState } from './model/useMenuDropdownState';
import { useBodyClickListener } from './hooks/useBodyClickListener';
import {
  DELAY_ANIMATION,
  MODAL_OPENED_KEYS,
  SUBTITLES_CLOSE_KEYS,
} from './constants/constants';
import { useSelectSubmenuOnRedirect } from './hooks/useSelectSubmenuOnRedirect';
import { useModalToggleOnResizeOrClose } from './hooks/useModalToggleOnResizeOrClose';
import React from 'react';

const MenuDropdown = (): JSX.Element => {
  const {
    isOpen,
    setIsOpen,
    openSubtitles,
    setOpenSubtitles,
    isClosing,
    setIsClosing,
    clickedSubmenu,
    setClickedSubmenu,
  } = useMenuDropdownState();
  const { openModal, closeModal } = useModalState();

  const items = useMenuItems();

  const { isScreen720 } = useResize();
  const navigate = useNavigate();

  const changeOpen = () => {
    setIsClosing(true);
    setIsOpen(!isOpen);
    setTimeout(() => setIsClosing(false), DELAY_ANIMATION * 1000);
  };

  const handleOpenChange = (openKeys: string[]) => {
    setOpenSubtitles(openKeys.slice(-1));
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    changeOpen();
  };

  const handleClick: MenuProps['onClick'] = (e) => {
    if (e.key.startsWith('http')) {
      window.location.href = e.key;
    } else {
      navigate(e.key);
    }

    if (SUBTITLES_CLOSE_KEYS.some((key) => e.key === key)) {
      setOpenSubtitles([]);
    }
  };

  const hanleClickModal: MenuProps['onClick'] = (e) => {
    closeModal(ModalKeys.ModalHeaderMenu);
    changeOpen();
    handleClick(e);
  };

  const openMenuModel = () =>
    openModal(ModalKeys.ModalHeaderMenu, {
      Container: MenuModalContainer,
      items: items,
      handleClick: handleClick,
      onClick: hanleClickModal,
      onClose: changeOpen,
      onContainerClick: handleContainerClick,
      openedKeys: MODAL_OPENED_KEYS,
    });

  const handleCloseModal = () => {
    setOpenSubtitles([]);
    setClickedSubmenu(null);
  };

  useModalToggleOnResizeOrClose(isOpen, handleCloseModal, openMenuModel);
  useSelectSubmenuOnRedirect(items);
  useBodyClickListener(isOpen, changeOpen);

  return (
    <MenuDropdownContainer>
      <BurgerButtonStyled onClick={handleButtonClick}>
        <MenuOutlined style={{ fontSize: 14, color: '#FFFFFFCC' }} />
      </BurgerButtonStyled>

      {((isClosing && isScreen720) || (isScreen720 && isOpen)) && (
        <MenuBurgerContainer $isOpen={isOpen} onClick={handleContainerClick}>
          <Menu
            mode="inline"
            onOpenChange={handleOpenChange}
            openKeys={openSubtitles}
            onClick={handleClick}
            items={items}
          />
        </MenuBurgerContainer>
      )}
      <RenderModal
        currentModalKey={ModalKeys.ModalHeaderMenu}
        isShowHeader={true}
      />
    </MenuDropdownContainer>
  );
};

export { MenuDropdown };
