import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { Sidebar } from './components/sidebar';
import { CustomHeader } from './components/header/CustomHeader';
import { ROUTER_PATH } from '../../../router/path';
import { useAuthState } from '../../../state/useAuthState';
import { useCookiesCustom } from '../../../hooks/useCookieCustom';
import { useChatListState } from '../../../pages/chat-list/state/useChatListState';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import notClsx from 'shared/helpers/not-clsx';
import { useWindowBreakpointState } from '../../../state/useWindowBreakpointState';
import { useShallow } from 'zustand/react/shallow';

const { Content } = Layout;

const AppLayout = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cookie, setCookie } = useCookiesCustom();
  const serverTime = useAuthState((state) => state.serverTime);
  const getUserProfileFromAPI = useAuthState(
    (state) => state.getUserProfileFromAPI
  );

  const searchParams = new URLSearchParams(window.location.search);
  const [resetTimestamp, setResetTimestamp] = useState<number | null>(null);
  const token = searchParams.get('token');
  const [noPagePaddings, setNoPagePaddings] = useState(false);
  const { t, i18n } = useTranslation();

  const getPartnersChatsList = useChatListState(
    (state) => state.getPartnersChatsList
  );
  const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);

  const currentServerTimeFromCookie = cookie['currentServerTime']
    ? Number(cookie['currentServerTime'])
    : 0;

  const [currentServerTime, setCurrentServerTime] = useState<number>(
    currentServerTimeFromCookie || serverTime
  );

  const { isScreen1280, isScreen1440 } = useWindowBreakpointState(
    useShallow((state) => ({
      isScreen720: state.computed.isScreen720,
      isScreen1280: state.computed.isScreen1280,
      isScreen1440: state.computed.isScreen1440,
    }))
  );

  useEffect(() => {
    if (token) {
      // Если есть токен, то пускать
      return;
    }

    if (!cookie['token']) {
      void getUserProfileFromAPI();
      navigate(ROUTER_PATH.FormsPages.LOGIN);
    }
  }, [cookie.token]);

  useEffect(() => {
    i18n.changeLanguage(cookie['i18nLang']);
  }, [cookie['i18nLang']]);

  useEffect(() => {
    const savedTimestamp = cookie.refreshTokenTimestamp;

    if (parseInt(savedTimestamp) > 0) {
      setResetTimestamp(parseInt(savedTimestamp));
    }

    const handleBeforeUnload = () => {
      const ResetTimestamp = resetTimestamp ? resetTimestamp.toString() : '0';
      const CurrentServerTime = currentServerTime.toString();

      setCookie('refreshTokenTimestamp', ResetTimestamp);
      setCookie('currentServerTime', CurrentServerTime);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    resetTimestamp,
    cookie.refreshTokenTimestamp,
    serverTime,
    currentServerTime,
  ]);

  useEffect(() => {
    const shouldHaveNoPaddings =
      location.pathname.startsWith('/chat') ||
      /^\/deals\/\d+/.test(location.pathname);

    setNoPagePaddings(shouldHaveNoPaddings);
  }, [location.pathname]);

  useEffect(() => {
    getPhexChatsList().then();
    getPartnersChatsList().then();
  }, []);

  const contentClassName = notClsx(noPagePaddings && 'no-paddings');

  return (
    <Layout hasSider>
      <Sidebar />
      <LayoutContentStyled $isMarginLeft={!isScreen1440 && isScreen1280}>
        <CustomHeader />
        <ContentStyled className={contentClassName}>
          <Outlet />
        </ContentStyled>
      </LayoutContentStyled>
    </Layout>
  );
};

export default AppLayout;

const LayoutContentStyled = styled(Layout)<{ $isMarginLeft?: boolean }>`
  min-height: 100dvh;
  background-color: #14141f;

  margin-left: ${(props) => props.$isMarginLeft && '80px'};
`;

const ContentStyled = styled(Content)`
  padding: 0 16px;
  min-height: calc(100vh - 80px);

  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 0 24px;
  }

  &.no-paddings {
    padding: 0;
  }
`;
